import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "./store/global-context";
import { CookiesProvider } from "react-cookie";
import { store } from "./redux/store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

/**
 * root of the project
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      {" "}
      {/* React Redux state management*/}
      <CookiesProvider>
        {" "}
        {/* React Cookie management*/}
        <GlobalContextProvider>
          {" "}
          {/* React default state management*/}
          <BrowserRouter>
            {" "}
            {/* React Router Browser routes */}
            <App /> {/* Entering in application  */}
          </BrowserRouter>
        </GlobalContextProvider>
      </CookiesProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
