import { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Topics.css";
import { MdModeEditOutline } from "react-icons/md";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import UpdateTopics from "./UpdateTopics";
import { AiFillDelete } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { exportToExcel } from "react-json-to-excel";
import { Fragment } from "react";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";
import { CSVLink } from "react-csv";

function Topics() {
  const TopicsNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data?.topicName}</span>
      </span>
    );
  };
  const CategoryNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.categoryId?.category}</span>
      </span>
    );
  };
  const SrNoRenderer = (props, ref) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    unregister,
  } = useForm();

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploaderrors },
    reset: uploadreset,
  } = useForm();

  const rowHeight = 55;
  const [category, setCategory] = useState("");
  const [UpdateTopicsData, setUpdateTopicsData] = useState();
  const [rowData, setRowData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [active, setActive] = useState(-1);
  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  function close() {
    setEditIsOpen(false);
    setUpdateTopicsData({});
    setRowData(rowData);
  }
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    cancelForm();
  }
  function fun() {
    setIsOpen(false);
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon">
          <MdModeEditOutline
            className="ActionIcon"
            onClick={() => {
              setUpdateTopicsData(props.data);
              setEditIsOpen(true);
            }}
          />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  // --get category API--//
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    getCategory();
  }, []);

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response, "getCategoryData");
        const categories = response.data.CategoryData.filter(
          (category) => category.status === true
        ).map((category) => ({
          label: category?.category,
          value: category?._id,
        }));
        setCategoryData(categories);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const resetForm = () => {
    setCategory("");
  };

  // --Add Sub-category API--//

  function addnew(postData) {
    console.log("postData>>>>>>>>", postData);
    let data = new FormData();
    data.append("categoryId", postData.category.value);
    data.append("topicName", postData.topicName);
    data.append("topicRank", postData.topicRank);
    data.append("content", postData.content);

    data.append(
      "topicImage",
      postData?.topicImage[0] === "h"
        ? postData?.topicImage
        : postData?.topicImage[0]
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createtopic`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getTopics();
        reset();
        resetForm();
      })
      .catch(function (error) {
        // console.log(error);
      })
      .finally(() => {
        setIsOpen(false);
      });
  }

  // --get Sub-category API--//
  useEffect(() => {
    getTopics();
    getImageCategory();
  }, []);
  const [downloadData, setDownloadData] = useState([]);

  function getTopics() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettopic`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const downloadData = response.data.result?.map(
          ({
            topicName,
            categoryId,
            topicRank,
            _id,
            content,
            status,
            topicImage,
          }) => {
            return {
              subCategoryName: topicName ? topicName : "",
              categoryName: categoryId ? categoryId?.category : "",
              subCategoryRank: topicRank ? topicRank : "",
              content: content ? content : "",
              subcategoryImage: topicImage ? topicImage : "",
              status: status ? status : false,
            };
          }
        );
        setDownloadData(downloadData);
        setRowData(response.data.result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getTopics();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  // ---- Update subcategory status API ---//
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Category?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeAuthorStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  function changeAuthorStatus(topicId) {
    let data = JSON.stringify({
      topicId: topicId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/topicchangestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getTopics();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------delete subcategory Api -----//
  function deleteSubCategory(index) {
    let data = JSON.stringify({
      topicId: index,
    });

    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASEURL}/deletetopic`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getTopics();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    console.log(uploadImages);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/topicbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getTopics();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.result, "RESULT");
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  //--------Function to handle and set Author Image--------//
  const [icon, setIcon] = useState("");
  const [sendImage, setSendImage] = useState("");
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setIcon(display);
          setSendImage(compressedResult);
        },
      });
    }
  };

  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = 1;
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows;
    setHeight(`${event.target.scrollHeight}px`);
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight;
    }
  };

  const headers = [
    { label: "Sub Category", key: "subCategoryName" },
    { label: "Category", key: "categoryName" },
    { label: "Rank", key: "subCategoryRank" },
    { label: "Content", key: "content" },
    { label: "Image", key: "subcategoryImage" },
    { label: "Status", key: "status" },
  ];
  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                SubCategory
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add SubCategory
                  </button>

                  {/* <button
                    className="btn btn-theme btn-sm"
                    onClick={() =>
                      exportToExcel(
                        [
                          {
                            sheetName: "subCategorybulkupload",
                            details: downloadData,
                          },
                        ],
                        "Subcategory List",
                        true
                      )
                    }
                  >
                    {" "}
                    Export
                  </button> */}
                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="subCategory.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Subcategory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {uploaderrors?.uploadImages?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add SubCategory
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => fun()}
                            ></button>
                          </div>
                          <form
                            onSubmit={handleSubmit(addnew)}
                            autoComplete="new-password"
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Category
                                  </label>
                                  <div className="position-relative">
                                    <Controller
                                      name="category"
                                      control={control}
                                      rules={{
                                        required: "This field is required",
                                      }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          isSearchable={false}
                                          className="react-dropdown"
                                          classNamePrefix="dropdown"
                                          options={categoryData}
                                          onChange={(event) => {
                                            field.onChange(event); // Update the field value
                                          }}
                                        />
                                      )}
                                    />

                                    {Object.keys(errors).includes(
                                      "category"
                                    ) && (
                                      <p style={{ color: "red" }}>
                                        {errors.category.message}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Sub Category Name
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("topicName", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.topicName?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    SubCategory Rank
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="number"
                                      className="form-control"
                                      min={0}
                                      {...register("topicRank", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.topicRank?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Content</label>
                                  <div className="position-relative">
                                    <textarea
                                      className="form-control"
                                      style={{
                                        height: height,
                                        minHeight: "20px",
                                        overflow: "hidden",
                                        resize: "none",
                                      }}
                                      onMouseMove={handleInput}
                                      onInput={handleInput}
                                      {...register("content", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.content?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    SubCategory Image
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="file"
                                      />
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="from directory"
                                      />
                                    </label>
                                    <label className="form-label">
                                      Image URL
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="image url"
                                      />
                                    </label>
                                    {errors?.uploadFile?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    {watch("uploadFile") === "file" && (
                                      <ul className="imagesUpload">
                                        <li
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            {...register("topicImage", {
                                              required:
                                                watch("uploadFile") === "file"
                                                  ? true
                                                  : false,
                                            })}
                                            onChange={(e) => {
                                              handleAuthorImage(e);
                                              unregister("topicImage");
                                            }}
                                          />

                                          {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )}

                                          {errors?.topicImage?.type ===
                                            "required" && (
                                            <p style={{ color: "red" }}>
                                              This field is required
                                            </p>
                                          )}

                                          {errors?.topicImage?.type ===
                                            "validate" && (
                                            <p style={{ color: "red" }}>
                                              File size should be 500KB or less
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    )}
                                    {watch("uploadFile") ===
                                      "from directory" && (
                                      <Fragment>
                                        <Controller
                                          name="directory"
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              isSearchable={false}
                                              className="react-dropdown"
                                              classNamePrefix="dropdown"
                                              options={directoryData}
                                              onChange={(event) => {
                                                field.onChange(event); // Update the field value
                                                setImagesData(event.images);
                                              }}
                                            />
                                          )}
                                        />
                                        {Object.keys(errors).includes(
                                          "directory"
                                        ) && (
                                          <p style={{ color: "red" }}>
                                            {errors.directory.message}
                                          </p>
                                        )}{" "}
                                        <div class="imageList">
                                          {imagesData &&
                                            imagesData.length > 0 &&
                                            imagesData.map((item, index) => (
                                              <Controller
                                                name="topicImage"
                                                control={control}
                                                render={() => (
                                                  <div
                                                    className={`uploadedImg ${
                                                      active === index &&
                                                      "active"
                                                    }`}
                                                    onClick={() => {
                                                      setValue(
                                                        "topicImage",
                                                        item.location
                                                      );
                                                      setActive(index);
                                                    }}
                                                  >
                                                    <img
                                                      height={50}
                                                      width={50}
                                                      alt="icon"
                                                      src={item?.location}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            ))}
                                        </div>
                                      </Fragment>
                                    )}
                                    {watch("uploadFile") === "image url" && (
                                      <div>
                                        <input
                                          type="text"
                                          {...register("topicImage", {
                                            required:
                                              watch("uploadFile") ===
                                              "image url"
                                                ? true
                                                : false,
                                          })}
                                          placeholder="Enter Image URL"
                                        />

                                        {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                        {errors?.topicImage?.type ===
                                          "required" && (
                                          <p style={{ color: "red" }}>
                                            This field is required
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* delete */}
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this SubCategory
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteSubCategory(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdateTopicsData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <UpdateTopics
                    updatedData={UpdateTopicsData}
                    onEditDataFunction={editdataReloadFunc}
                    editIsOpen={editIsOpen}
                    close={close}
                  />
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    TopicsNameRenderer: TopicsNameRenderer,
                    categoryNameRenderer: CategoryNameRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                  }}
                >
                  <AgGridColumn
                    flex={2}
                    // field="SrNo"
                    field="topicRank"
                    headerName="Rank"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="categoryNameRenderer"
                    headerName="Category"
                    field="category"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="TopicsNameRenderer"
                    headerName="SubCategory"
                    field="topicName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="statusRenderer"
                    flex={2}
                    field="status"
                    sortable={true}
                    filter={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Topics;
