import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Ads.css";
import "../Gardener/Gardener.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import JsonDownload from "../../components/downloadjson";
import { exportToExcel } from "react-json-to-excel";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CSVLink } from "react-csv";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};
const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data?.title}</span>
    </>
  );
};
const ArtCreateNameRenderer = (props) => {
  return (
    <>
      <img href={props?.data?.addImage} alt="img" />
    </>
  );
};

const subtitleNameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.subtitle}</span>
    </>
  );
};
const buttonTextNameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.buttonText}</span>
    </>
  );
};
const buttonLinkNameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.buttonLink}</span>
    </>
  );
};
const AddImageRender = (props) => {
  return (
    <>
      <img
        src={props?.data?.addImage}
        alt=""
        style={{ height: "50px", width: "60px" }}
      />
    </>
  );
};

function Ads() {
  const [rowData, setRowData] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const rowHeight = 55;
  let navigate = useNavigate();
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    reset,
    watch,
    control,
    setValue,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors },
    reset: uploadreset,
  } = useForm();

  useEffect(() => {
    getSeries();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    cancelForm();
  }
  function cancelForm() {
    reset();
  }

  // ---- Get article API ---//
  const [downloadData, setDownloadData] = useState([]);

  function getSeries() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getAdd`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        console.log("response.data.result", response.data.result);
        const downloadData = response?.data?.result?.map(
          ({
            title,
            buttonText,
            titleId,
            _id,
            subtitle,
            buttonLink,
            addImage,
          }) => {
            return {
              title: title ? title : "",
              subtitle: subtitle ? subtitle : "",
              buttonText: buttonText ? buttonText : "",
              buttonLink: buttonLink ? buttonLink : "",
              addImage: addImage ? addImage : "",
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ----Delete article API ---//
  function articlesdeleteData(id) {
    let config = {
      method: "DELETE",
      url: `${process.env.REACT_APP_BASEURL}/deleteAdd/${id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        getSeries();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ---- article status API ---//
  function changeArticleStatus(seriesId) {
    let data = JSON.stringify({
      seriesId: seriesId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changeseriesstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getSeries();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() =>
            navigate(`/updateads/${props?.data?._id}`, {
              state: { details: props.data },
              replace: true,
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props?.data?._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);
  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);s

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addBulkUpload  `,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          uploadreset();
          somefun();
        }
      })
      .catch(function (error) {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  const headers = [
    { label: "Title", key: "title" },
    { label: "Sub Title", key: "subtitle" },
    { label: "Button Text", key: "buttonText" },
    { label: "Button Link", key: "buttonLink" },
    { label: "Image", key: "addImage" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Ads
                <div className="float-end btns_head">
                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="Ads.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                </div>
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => navigate("/add-ads")}
                  >
                    Add New Ads
                  </button>
                </div>
              </h4>
              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Ads</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Add?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                articlesdeleteData(DeleteBusinessId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      titleRenderer: TitleRenderer,
                      ArtCreateNameRenderer: ArtCreateNameRenderer,
                      subtitleNameRenderers: subtitleNameRenderer,
                      buttonTextNameRenderers: buttonTextNameRenderer,
                      buttonLinkNameRenderers: buttonLinkNameRenderer,
                      AddImage: AddImageRender,
                    }}
                  >
                    <AgGridColumn
                      flex={1}
                      field="SrNo"
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={2}
                      field="AdsTitle"
                      cellRenderer="titleRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={2}
                      field="sub title"
                      cellRenderer="subtitleNameRenderers"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={2}
                      field="buttonTextNameRenderers"
                      cellRenderer="buttonTextNameRenderers"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={2}
                      field="buttonLink"
                      cellRenderer="buttonLinkNameRenderers"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={2}
                      field="AddImage"
                      cellRenderer="AddImage"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={1}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Ads;
