import React, { useState, useMemo, useEffect, Fragment, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./innerTv.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import UpdateInnerTv from "./UpdateInnerTv";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import { CSVLink } from "react-csv";

function InnerTv() {
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    reset,
    watch,
    control,
    setValue,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors },
    reset: uploadreset,
  } = useForm();

  const [UpdateWeatherData, setUpdateWeatherData] = useState();
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [tags, setTags] = useState("");
  const [editIsOpen, setEditIsOpen] = useState(false);
  const rowHeight = 55;
  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const selectRef = useRef(null);

  const handleAuthorImage = (event) => {
    console.log("event.target.files", event.target.files);
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  useEffect(() => {
    function getImageCategory() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getallimages`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          const directories = response.data.result.map((item) => ({
            value: item?.imageCategoryId?._id,
            label: item?.imageCategoryId?.imageCategory,
            images: item.images,
          }));
          setDirectoryData(directories);
        })
        .catch(function (error) {
          toast.error(error.message ?? "Something went wrong");
        });
    }
    getImageCategory();
  }, []);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateWeatherData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const resetForm = () => {
    setTags("");
    setSendinnericonImage("");
    setInnericon("");
  };

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const WeatherNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.weatherTagName}</span>
      </span>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getweather();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const statusValueGetter = (params) => {
    return params.data.status;
  };
  //----------//---------//
  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    cancelForm();
  }
  function cancelForm() {
    reset();
  }
  function fun() {
    setIsOpen(false);
  }
  //---------Get weather Api -----//
  useEffect(() => {
    getweather();
  }, []);
  const [rowData, setRowData] = useState([]);

  function close() {
    setEditIsOpen(false);
    setUpdateWeatherData({});
    setRowData(rowData);
  }

  function getweather() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getInnerVideo`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        const downloadData = response.data.result?.map(
          ({ innerImage, title, url, innerCategory, _id, status, rank }) => {
            return {
              title: title ? title : "",
              innerImage: innerImage ? innerImage : "",
              url: url ? url : "",
              innerCategory: innerCategory?.title ? innerCategory?.title : "",
              rank: rank ? rank : "",
              // _id,
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  const [active, setActive] = useState(-1);
  const [downloadData, setDownloadData] = useState([]);

  //-------//--------//
  const [innerTVData, setInnerTVData] = useState([]);
  useEffect(() => {
    const fetchInnerTVData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}/getInnerTV`
        );
        setInnerTVData(response.data.result);
      } catch (error) {
        // Handle error
        console.error("Error fetching Inner TV data:", error);
      }
    };

    fetchInnerTVData();
  }, []);
  const optionListing = innerTVData?.map((item) => ({
    label: item?.title,
    value: item?._id,
    self: item,
  }));

  function addnewWeather(postdata) {
    somefun();
    const formData = new FormData();
    formData.append("title", postdata?.Title);
    formData.append("rank", postdata?.rank);
    formData.append(
      "innerImage",
      postdata?.innerImage[0] === "h"
        ? postdata?.innerImage
        : postdata?.innerImage[0]
    );

    formData.append("url", postdata?.url);
    formData.append("innerCategory", postdata?.Categories?.value);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createInnerVideoAndUpdate`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        resetForm();
        reset();
        getweather();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------Status Inners Api -----//

  //---------delete Inners Api -----//
  function deleteWeather(index) {
    let config = {
      method: "Delete",
      url: `${process.env.REACT_APP_BASEURL}/deleteInnerVideo/${index}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        getweather();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/innerVideobulkupload `,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const headers = [
    { label: "Title", key: "title" },
    { label: "Image", key: "innerImage" },
    { label: "Video", key: "url" },
    { label: "Category", key: "innerCategory" },
    { label: "Rank", key: "rank" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Inner Tv
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Inner Tv
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="InnerTv.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>
              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Inner Tv</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Inner Tv
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => somefun()}
                            ></button>
                          </div>
                          <form onSubmit={handlesubmitUpload(addnewWeather)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Title</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("Title", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.Title?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                  <label className="form-label">Rank</label>

                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("rank", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.rank?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                  <label className="form-label">
                                    Video URL
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("url", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.url?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>

                                  {/* <label className="form-label">Image</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("image", {
                                        required: true,
                                      })}
                                      type="file"
                                      onChange={(e) => {
                                        const file = e.target.files[0];
                                        uploadRegister("image").onChange(file);
                                      }}
                                    />
                                    {uploaderrors?.image?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div> */}
                                </div>
                                {/* Line no */}

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    SubCategory Image
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="file"
                                      />
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="from directory"
                                      />
                                    </label>
                                    <label className="form-label">
                                      Image URL
                                      <input
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="image url"
                                      />
                                    </label>
                                    {errors?.uploadFile?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    {watch("uploadFile") === "file" && (
                                      <ul className="imagesUpload">
                                        <li
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            {...uploadRegister("innerImage", {
                                              required:
                                                watch("uploadFile") === "file"
                                                  ? true
                                                  : false,
                                            })}
                                            onChange={(e) => {
                                              handleAuthorImage(e);
                                              unregister("innerImage");
                                            }}
                                          />

                                          {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )}

                                          {errors?.innerImage?.type ===
                                            "required" && (
                                            <p style={{ color: "red" }}>
                                              This field is required
                                            </p>
                                          )}

                                          {errors?.innerImage?.type ===
                                            "validate" && (
                                            <p style={{ color: "red" }}>
                                              File size should be 500KB or less
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    )}
                                    {watch("uploadFile") ===
                                      "from directory" && (
                                      <Fragment>
                                        <Controller
                                          name="directory"
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              isSearchable={false}
                                              className="react-dropdown"
                                              classNamePrefix="dropdown"
                                              options={directoryData}
                                              onChange={(event) => {
                                                field.onChange(event); // Update the field value
                                                setImagesData(event.images);
                                              }}
                                            />
                                          )}
                                        />
                                        {Object.keys(errors).includes(
                                          "directory"
                                        ) && (
                                          <p style={{ color: "red" }}>
                                            {errors.directory.message}
                                          </p>
                                        )}{" "}
                                        <div class="imageList">
                                          {imagesData &&
                                            imagesData.length > 0 &&
                                            imagesData.map((item, index) => (
                                              <Controller
                                                name="innerImage"
                                                control={control}
                                                render={() => (
                                                  <div
                                                    className={`uploadedImg ${
                                                      active === index &&
                                                      "active"
                                                    }`}
                                                    onClick={() => {
                                                      setValue(
                                                        "innerImage",
                                                        item.location
                                                      );
                                                      setActive(index);
                                                    }}
                                                  >
                                                    <img
                                                      height={50}
                                                      width={50}
                                                      alt="icon"
                                                      src={item?.location}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            ))}
                                        </div>
                                      </Fragment>
                                    )}
                                    {watch("uploadFile") === "image url" && (
                                      <div>
                                        <input
                                          type="text"
                                          {...uploadRegister("innerImage", {
                                            required:
                                              watch("uploadFile") ===
                                              "image url"
                                                ? true
                                                : false,
                                          })}
                                          placeholder="Enter Image URL"
                                        />

                                        {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                        {errors?.innerImage?.type ===
                                          "required" && (
                                          <p style={{ color: "red" }}>
                                            This field is required
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="position-relative">
                                  <label className="form-label">
                                    Choose Image:
                                  </label>
                                  <br />

                                  <div className="d-flex">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        checked={showImage ? "checked" : ""}
                                        type="radio"
                                        value="file"
                                        onChange={() => {
                                          setShowImage(true);
                                          setShowImageFile(true);
                                          setShowImageErr(true);
                                        }}
                                      />
                                      {showImage && (
                                        <div className="position-relative">
                                          {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )}

                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              handleAuthorImage(
                                                e?.target?.files
                                              );
                                              setNewImage(e?.target?.files);
                                            }}
                                            className="fileUpload"
                                            required
                                          />
                                          {newImage === null && (
                                            <p style={{ color: "red" }}>
                                              Please select a file
                                            </p>
                                          )}
                                          {fileSelectionError && (
                                            <p style={{ color: "red" }}>
                                              File size exceeds 500 KB limit
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        checked={
                                          !showImageFile ? "checked" : ""
                                        }
                                        type="radio"
                                        value="from directory"
                                        onClick={() => {
                                          handleRadioClick();
                                          setShowImageFile(false);
                                          setShowImage(false);
                                          setShowImageErr(true);
                                        }}
                                      />
                                    </label>

                                    {showImageErr === false && (
                                      <p style={{ color: "red" }}>
                                        {" "}
                                        This Fild is required
                                      </p>
                                    )}
                                  </div>

                                  {!showImageFile && (
                                    <Select
                                      options={directoryData}
                                      onChange={(event) => {
                                        setImagesData(event.images);
                                        handleSelectClose(event);
                                      }}
                                    />
                                  )}
                                </div>

                                <div class="row">
                                  {imagesData &&
                                    !showImage &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item) => {
                                      return (
                                        <div
                                          className="col-md-3"
                                          onClick={() => {
                                            setNewImage(item.location);
                                          }}
                                        >
                                          <img
                                            height={50}
                                            width={50}
                                            alt="icon"
                                            src={item?.location}
                                          />
                                        </div>
                                      );
                                    })}
                                </div> */}

                                <div className="col-md-12 mb3">
                                  <label className="form-label">
                                    Select Categories
                                  </label>
                                  <Controller
                                    name="Categories"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={optionListing}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          // setAuthorDetails(event.self);
                                        }}
                                      />
                                    )}
                                  />
                                  {Object.keys(errors).includes(
                                    "fileupload"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {errors.fileupload.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* //--//---// */}

              {/* delete */}
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Weather</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteWeather(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}

              {/* edit */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdateWeatherData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <UpdateInnerTv
                        updatedData={UpdateWeatherData}
                        onEditDataFunction={editdataReloadFunc}
                        editIsOpen={editIsOpen}
                        close={close}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* edit */}

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    weatherNameRenderer: WeatherNameRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="titleRenderer"
                    field="title"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={260}
                    cellRenderer="rankRenderer"
                    field="rank"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default InnerTv;
