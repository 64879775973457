import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./ArtSeries.css";
import "../Gardener/Gardener.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { exportToExcel } from "react-json-to-excel";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};
const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data?.title}</span>
    </>
  );
};
const ArtCreateNameRenderer = (props) => {
  return (
    <>
      <span>{props.data?.createFrom}</span>
    </>
  );
};
function ArtSeries() {
  const [rowData, setRowData] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const rowHeight = 55;
  let navigate = useNavigate();
  useEffect(() => {
    getSeries();
  }, []);

  // ---- Get article API ---//
  const [downloadData, setDownloadData] = useState([]);

  function getSeries() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getarticleseries?type=Admin`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        const downloadData = response.data.result?.map(
          ({ title, createFrom, titleId, _id }) => {
            return {
              title: title ? title : "",
              createdBy: createFrom ? createFrom : "",
              titleId:
                titleId && titleId.length > 0
                  ? titleId.map((tag) => tag?.articleTitle).join(",")
                  : "",
            };
          }
        );

        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ----Delete article API ---//
  function articlesdeleteData(id) {
    let data = JSON.stringify({
      seriesId: id,
    });
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/removearticleseries`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getSeries();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ---- article status API ---//
  function changeArticleStatus(seriesId) {
    let data = JSON.stringify({
      seriesId: seriesId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changeseriesstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getSeries();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this article?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeArticleStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };
  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;

    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() =>
            navigate(`/UpdateArtSeries/${props?.data?._id}`, {
              state: { details: props.data },
              replace: true,
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);
  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  function bulkupload({ uploadImages }) {
    const formData = new FormData();
    formData.append("attachment", uploadImages[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/articleSeriesBulkUpload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        if (
          response.data.message.includes("bulk upload created successfully")
        ) {
          toast.success(response.data.message);
          reset();
          getSeries();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Path
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => navigate("/AddArtSeries")}
                  >
                    Add New Path
                  </button>
                  {/* <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button> */}
                  {/* <JsonDownload
                    title="Export"
                    fileName="Article Series List"
                    downloadfile={downloadData}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  /> */}
                </div>
                <div>
                  {/* Upload Images */}
                  <Modal
                    show={isBulkUploadOpen}
                    onHide={() => setIsBulkUploadOpen(false)}
                  >
                    <form onSubmit={handleSubmit(bulkupload)}>
                      <Modal.Header closeButton>
                        <Modal.Title> Upload Journals</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">File</label>
                            <div className="position-relative">
                              <input
                                type="file"
                                multiple
                                // value={tags}
                                className="form-control"
                                {...register("uploadImages", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  unregister("uploadImages");
                                }}
                                name="uploadImages"
                              />
                              {errors?.uploadImages?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}

                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setIsBulkUploadOpen(false);
                            reset();
                          }}
                        >
                          Close
                        </Button>
                        <button type="submit">Submit</button>
                      </Modal.Footer>
                    </form>
                  </Modal>
                </div>
              </h4>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this Series?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                articlesdeleteData(DeleteBusinessId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      titleRenderer: TitleRenderer,
                      artCreateNameRenderer: ArtCreateNameRenderer,
                      statusRenderer: StatusRenderer,
                    }}
                  >
                    <AgGridColumn
                      flex={1}
                      field="SrNo"
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={2}
                      field="JournalsTitle"
                      cellRenderer="titleRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={2}
                      field="createdBy"
                      cellRenderer="artCreateNameRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="statusRenderer"
                      flex={1}
                      field="status"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ArtSeries;
