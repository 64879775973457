import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./innerTv.css";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import RenderImage from "../../components/Image";

const UpdateInnerTv = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  const [active, setActive] = useState(-1);
  useEffect(() => {
    setEditData(props.updatedData);
    getImageCategory();
  }, [props]);

  const {
    register: uploadRegister,
    handleSubmit: handlesubmitUpload,
    reset,
    setValue,
    unregister,
    watch,
    control,
    formState: { errors: uploaderrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      Title: props?.updatedData?.title,
      subtitle: props?.updatedData?.rank,
      url: props?.updatedData?.url,
      innerImage: props?.updatedData?.innerImage,
      Categories: {
        label: props?.updatedData?.innerCategory.title,
        value: props?.updatedData?.innerCategory._id,
      },
    },
  });

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.result, "RESULT");
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }

  useEffect(() => {
    if (props?.updatedData) {
      setValue("innerImage", props?.updatedData?.innerImage);
    }
  }, [props]);
  //--------Function to handle and set Weather Image--------//

  function updateWeather(postdata) {
    console.log("postdata>>>>>>>>>>>>", postdata);
    props.close();
    let data = new FormData();
    data.append("title", postdata?.Title);
    data.append("rank", postdata?.subtitle);

    data.append(
      "innerImage",
      postdata.innerImage[0] === "h"
        ? postdata.innerImage
        : postdata.innerImage[0]
    );
    data.append("url", postdata?.url);
    data.append("innerCategory", postdata?.Categories?.value);
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/createInnerVideoAndUpdate?id=${editGroupData?._id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {});
  }

  const [innerTVData, setInnerTVData] = useState([]);
  useEffect(() => {
    const fetchInnerTVData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}/getInnerTV`
        );
        setInnerTVData(response.data.result);
      } catch (error) {
        // Handle error
        console.error("Error fetching Inner TV data:", error);
      }
    };

    fetchInnerTVData();
  }, []);
  const optionListing = innerTVData?.map((item) => ({
    label: item?.title,
    value: item?._id,
    self: item,
  }));

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Inner Tv
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>

                  <form onSubmit={handlesubmitUpload(updateWeather)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Title</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...uploadRegister("Title", {
                                required: true,
                              })}
                            />

                            {uploaderrors?.Title?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                          <label className="form-label">Rank</label>

                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...uploadRegister("subtitle", {
                                required: true,
                              })}
                            />

                            {uploaderrors?.subtitle?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                          <label className="form-label">Video URL</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...uploadRegister("url", {
                                required: true,
                              })}
                            />

                            {uploaderrors?.url?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>

                          {!watch("uploadFile") && (
                            <div className="col-md-12 mb-3 mt-3">
                              <label className="form-label">
                                SubCategory Image
                              </label>
                              <div className="position-relative">
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "128px",
                                      height: "128px",
                                    }}
                                  >
                                    {watch("innerImage") && (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "148px",
                                        }}
                                        src={watch("innerImage") ?? innericon}
                                      />
                                    )}
                                  </li>
                                </ul>
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div>
                          )}

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Category Image</label>

                            <div className="position-relative">
                              <label className="form-label">
                                Upload File
                                <input
                                  {...uploadRegister("uploadFile")}
                                  type="radio"
                                  value="file"
                                />
                              </label>
                              <label className="form-label">
                                from directory
                                <input
                                  {...uploadRegister("uploadFile")}
                                  type="radio"
                                  value="from directory"
                                />
                              </label>
                              <label className="form-label">
                                Image URL
                                <input
                                  {...uploadRegister("uploadFile")}
                                  type="radio"
                                  value="image url"
                                />
                              </label>
                              {uploaderrors?.uploadFile?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                              {watch("uploadFile") === "file" && (
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      {...uploadRegister("innerImage", {
                                        required:
                                          watch("uploadFile") === "file"
                                            ? true
                                            : false,
                                      })}
                                      onChange={(e) => {
                                        handleAuthorImage(e);
                                        unregister("innerImage");
                                      }}
                                    />

                                    {innericon.length === 0 ? (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "128px",
                                        }}
                                        src="../../images/defalutimg.svg"
                                      />
                                    ) : (
                                      <RenderImage image={innericon} />
                                    )}

                                    {uploaderrors?.innerImage?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    {uploaderrors?.innerImage?.type ===
                                      "validate" && (
                                      <p style={{ color: "red" }}>
                                        File size should be 500KB or less
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              )}
                              {watch("uploadFile") === "from directory" && (
                                <Fragment>
                                  <Controller
                                    name="directory"
                                    control={control}
                                    rules={{
                                      required:
                                        watch("uploadFile") === "from directory"
                                          ? true
                                          : false,
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={directoryData}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          setImagesData(event.images);
                                        }}
                                      />
                                    )}
                                  />
                                  {Object.keys(uploaderrors).includes(
                                    "directory"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {uploaderrors.directory.message}
                                    </p>
                                  )}{" "}
                                  <div class="imageList">
                                    {imagesData &&
                                      imagesData.length > 0 &&
                                      imagesData.map((item, index) => (
                                        <Controller
                                          name="innerImage"
                                          control={control}
                                          render={() => (
                                            <div
                                              className={`uploadedImg ${
                                                active === index && "active"
                                              }`}
                                              onClick={() => {
                                                setValue(
                                                  "innerImage",
                                                  item.location
                                                );
                                                setActive(index);
                                              }}
                                            >
                                              <img
                                                height={50}
                                                width={50}
                                                alt="icon"
                                                src={item?.location}
                                              />
                                            </div>
                                          )}
                                        />
                                      ))}
                                  </div>
                                </Fragment>
                              )}
                              {watch("uploadFile") === "image url" && (
                                <div>
                                  <input
                                    type="text"
                                    {...uploadRegister("innerImage", {
                                      required:
                                        watch("uploadFile") === "image url"
                                          ? true
                                          : false,
                                    })}
                                    placeholder="Enter Image URL"
                                  />

                                  {uploaderrors?.innerImage?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mb3">
                          <label className="form-label">
                            Select Categories
                          </label>
                          <Controller
                            name="Categories"
                            control={control}
                            rules={{
                              required: "This field is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={optionListing}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  // setAuthorDetails(event.self);
                                }}
                              />
                            )}
                          />
                          {/* {Object.keys(errors).includes("fileupload") && (
                            <p style={{ color: "red" }}>
                              {errors.fileupload.message}
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateInnerTv;
