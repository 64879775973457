import React, { useState, useEffect } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

function Login() {
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [coord, setCoord] = useState("");
  sessionStorage.removeItem("_expiredTime");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoord(position.coords);
    });
  }, []);

  let navigate = useNavigate();
  const [message, setmessage] = useState("");
  const [pvisibility, setpvisibility] = useState(false);
  const [ipAddress, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  function signin(postdata) {
    let data = JSON.stringify({
      email: postdata.email,
      password: postdata.password,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/adminlogin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(
            "AdminData",
            JSON.stringify(response.data.result) || "{}"
          );
          localStorage.setItem("AdminToken", response.data.token);
          setTimeout(() => {
            navigate("/Articles");
          }, 1000);
        } else {
          if (response.status === 201) {
            setmessage(response.data.message);
          } else {
            setmessage("please enter correct credentials");
          }
        }
      })
      .catch(function (error) {});
  }

  return (
    <section className="login_section">
      <div className="form_box">
        <form onSubmit={handleSubmit(signin)}>
          <div className="col-md-12 text-center">
            {/* <img
              className="logoImg"
              src="../../images/logo.png"
              alt="renderImages"
            /> */}
            <h2>Martin</h2>
          </div>
          <h4>Sign In</h4>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            {...register("email", {
              required: true,
            })}
          />
          {errors?.email?.type === "required" && (
            <p style={{ color: "red" }}>This field is required</p>
          )}

          <div className="position-relative">
            <input
              type={pvisibility ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              {...register("password", {
                required: "This password is required",
              })}
            />
            {pvisibility ? (
              <div>
                <img
                  alt="renderImages"
                  style={{
                    width: "23px",
                    position: "absolute",
                    top: "13px",
                    right: "12px",
                  }}
                  src="images/eye.png"
                  onClick={() => {
                    setpvisibility(false);
                  }}
                />
              </div>
            ) : (
              <div>
                <img
                  alt="renderImages"
                  style={{
                    width: "23px",
                    position: "absolute",
                    top: "13px",
                    right: "12px",
                  }}
                  src="images/invisible.png"
                  onClick={() => {
                    setpvisibility(true);
                  }}
                />
              </div>
            )}
            {errors?.password?.type === "required" && (
              <p style={{ color: "red" }}>This field is required</p>
            )}
          </div>
          <button type="submit" className="form-control btn">
            Login
          </button>
          {message}
        </form>
      </div>
    </section>
  );
}

export default Login;
