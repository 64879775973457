import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./RequestArticles.css";
import "../Gardener/Gardener.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import JsonDownload from "../../components/downloadjson";
import { exportToExcel } from "react-json-to-excel";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const ImageRenderer = (props, ref) => {
  return (
    <span className="profle_img_box">
      <img
        className="profile_img_table"
        src={props?.data?.userName?.aboutcommunitystrengths?.about?.userImage}
        alt="icon"
      />
    </span>
  );
};

const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data?.articleTitle}</span>
    </>
  );
};

const userNames = (props) => {
  console.log("props", props);
  return (
    <>
      <span>{props?.data?.userName?.first_name}</span>
    </>
  );
};

const ArticleType = (props) => {
  return (
    <>
      <span>{props.data?.blogType}</span>
    </>
  );
};

const CreatedByRenderer = (props) => {
  return (
    <>
      <span>{props.data?.createdBy}</span>
    </>
  );
};

const DescriptionRenderer = (props) => {
  return (
    <>
      <span>{props.data?.description}</span>
    </>
  );
};

const TagsRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props?.data?.tags?.join(", ")}</span>
    </span>
  );
};

const GroupRender = (props) => {
  return (
    <span className="profle_img_box">
      <span>
        {props?.data?.groups_Id.map((item) => item.grpName)?.join(", ")}
      </span>
    </span>
  );
};

const CreatedDateRenderer = (props) => {
  const date = new Date(+props.data.createdDate);
  return (
    <span className="profle_img_box">
      <span>{date.toLocaleDateString("en-US")}</span>
    </span>
  );
};

const ArticleimageRenderer = (props) => {
  return (
    <span>
      <img className="profile_img_table" src={props.data.banner} alt="icon" />
    </span>
  );
};

function RequestArticles() {
  const [rowData, setRowData] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const rowHeight = 55;
  let navigate = useNavigate();

  useEffect(() => {
    getarticles();
  }, []);
  const [downloadData, setDownloadData] = useState([]);

  // ---- Get blog API ---//
  function getarticles() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getarticles?type=requestarticle`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.result);

        const downloadData = response.data.result?.map(
          ({
            aiMeter,
            articleDescription,
            articleImage,
            articleRank,
            articleSubTitle,
            articleTitle,
            articleType,
            authorName,
            categoryId,
            groupTags,
            headContent,
            innerId,
            metaDescription,
            oppositeTags,
            science,
            scienceImage,
            status,
            tags,
            topicId,
            weatherTag,
            wellgoContent,
            wellgoHeadline,
            wellgoImage,
            wellgoTitle,
            _id,
          }) => {
            return {
              authorName: authorName ? authorName : "",
              category: categoryId ? categoryId?.category : "",
              topicId: topicId ? topicId?.topicName : "",
              articleImage: articleImage ? articleImage : "",
              articleTitle: articleTitle ? articleTitle : "",
              articleSubTitle: articleSubTitle ? articleSubTitle : "",
              articleDescription: articleDescription ? articleDescription : "",
              tags:
                tags && tags.length > 0
                  ? tags.map((tag) => tag?.tags).join(",")
                  : "",
              innerId: innerId ? innerId?.inner : "",
              groupTags:
                groupTags && groupTags.length > 0
                  ? groupTags.map((gtag) => gtag?.groupName).join(",")
                  : "",
              weatherTag: weatherTag ? weatherTag?.weatherTagName : "",
              oppositetags:
                oppositeTags && oppositeTags.length > 0
                  ? oppositeTags.map((otag) => otag?.tags).join(",")
                  : "",
              headContent:
                headContent && headContent.length > 0
                  ? headContent
                      .map(
                        (head_content) =>
                          `${head_content?.heading},${head_content?.content}`
                      )
                      .join("||")
                  : "",
              science: science ? science : "",
              scienceImage: scienceImage ? scienceImage : "",
              wellgoImage: wellgoImage ? wellgoImage : "",
              wellgoTitle: wellgoTitle ? wellgoTitle?.name : "",
              wellgoHeadline: wellgoHeadline ? wellgoHeadline : "",
              wellgoContent: wellgoContent ? wellgoContent : "",
              metaDescription: metaDescription ? metaDescription : "",
              aiMeter: aiMeter ?? "",
              // status: status ? status : "",
              articleRank: articleRank ? articleRank : "",
              articleType: articleType ? articleType : "",
              // _id: _id,
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ----Delete article API ---//
  function articlesdeleteData(id) {
    let data = JSON.stringify({
      blogId: id,
    });
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/deleteblog`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ---- article status API ---//
  function changeArticleStatus(blogId) {
    let data = JSON.stringify({
      blogId: blogId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/blogchangestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "Reject" : "Approve";
    let message = "Are you sure you want to " + status + " this article?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeArticleStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };
  function changeArticleTrendingStatus(blogId) {
    let data = JSON.stringify({
      blogId: blogId,
    });
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/blogchangestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeArticleTrendingStatus(props?.data?._id);
        }}
        status={props?.data?.trending}
      />
    );
  };
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() =>
            navigate({
              pathname: `/UpdateRequestArticles/${props.data._id}`,
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);
  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Request Journals
                <button
                  className="btn btn-theme btn-sm"
                  onClick={() =>
                    exportToExcel(
                      [
                        {
                          sheetName: "articlebulkupload",
                          details: downloadData,
                        },
                      ],
                      "Request Article List",
                      true
                    )
                  }
                >
                  Export
                </button>
                {/* <JsonDownload
                  title="Export"
                  fileName="Request Article List"
                  downloadfile={downloadData}
                  btnClassName={"btn btn-theme btn-sm"}
                  btnColor={"#f9560f"}
                /> */}
              </h4>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>
                              Are you sure you want to delete this Request
                              Articles?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                articlesdeleteData(DeleteBusinessId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      titleRenderer: TitleRenderer,
                      userNames: userNames,
                      articleType: ArticleType,
                      createdByRenderer: CreatedByRenderer,
                      descriptionRenderer: DescriptionRenderer,
                      imageRenderer: ImageRenderer,
                      tagRenderer: TagsRenderer,
                      statusRenderer: StatusRenderer,
                      TrendingRender: TrendingRender,
                      GroupRender: GroupRender,
                      createdDateRenderer: CreatedDateRenderer,
                      articleimageRenderer: ArticleimageRenderer,
                    }}
                  >
                    <AgGridColumn
                      flex={1}
                      field="SrNo"
                      width={90}
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={1}
                      field="JournalsTitle"
                      cellRenderer="titleRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                      width={150}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="JournalsType"
                      cellRenderer="articleType"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      width={150}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      headerName="Gardener Name"
                      field="first_name"
                      cellRenderer="userNames"
                      sortable={false}
                      filter={false}
                      width={150}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      headerName="Gardener Image"
                      // field="userName.file"
                      cellRenderer="imageRenderer"
                      sortable={false}
                      filter={false}
                      width={150}
                    ></AgGridColumn>
                    {/* <AgGridColumn
                      cellRenderer="statusRenderer"
                      flex={1}
                      field="Articles status"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn> */}
                    <AgGridColumn
                      flex={1}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RequestArticles;
