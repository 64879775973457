import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Grouptags.css";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Updategroup from "./UpdateGrouptags";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import JsonDownload from "../../components/downloadjson";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import { CSVLink } from "react-csv";

function Grouptags() {
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    unregister,
  } = useForm();

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploaderrors },
    reset: uploadreset,
  } = useForm();

  const [UpdateGroupData, setUpdateGroupData] = useState();
  const [DeleteGroupId, setDeleteGroupId] = useState("");
  const [GroupView, setGroupView] = useState([]);
  const [groupName, setGroups] = useState("");
  const [rowData, setRowData] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const rowHeight = 55;
  const [editIsOpen, setEditIsOpen] = useState(false);

  function close() {
    setEditIsOpen(false);
    setUpdateGroupData({});
    setRowData(rowData);
  }
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon">
          <MdModeEditOutline
            className="ActionIcon"
            onClick={() => {
              setUpdateGroupData(props.data);
              setEditIsOpen(true);
            }}
          />
        </div>
        <div
          className="ViewIcon"
          onClick={() => {
            setGroupView(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DetailGroup"
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteGroupId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteGroupData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    reset();
  }

  const resetForm = () => {
    setGroups("");
  };

  const handlechangetag = (event) => {
    let array = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    setselectedOptionone(array);
    setErr(false);
  };

  const TagsRenderer = (props) => {
    const Tags = [];
    props.data?.tags?.map((item) => {
      Tags.push(item.tags);
    });
    return <span>{Tags.join(",")}</span>;
  };

  let viewTags = GroupView?.tags?.map((tag) => {
    return tag?.tags.trim();
  });

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const GroupRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.groupName}</span>
      </span>
    );
  };

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this tag-group?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeGroupTagStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getgrouptags();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  useEffect(() => {
    getnewtag();
    getgrouptags();
  }, []);
  //-----------Add Group-tag api--------//
  const [err, setErr] = useState(false);
  function addgrouptag(postdata) {
    if (selectedOptionone.length === 0) {
      setErr(true);
    } else {
      setIsOpen(false);
      setErr(false);
      let data = JSON.stringify({
        groupName: postdata.groupName,
        tags: postdata?.tags?.map((item) => item.value).join(","),
      });
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/addgrouptag`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resetForm();
          reset();
          getgrouptags();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  //-----------Get Group-tag api----------//
  const [downloadData, setDownloadData] = useState([]);

  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        const downloadData = response.data.result?.map(
          ({ groupName, _id, tags, status }) => {
            return {
              groupName: groupName ? groupName : "",
              status: status ? status : "",
              tags:
                tags && tags.length > 0
                  ? tags.map((tag) => tag?.tags).join(",")
                  : "",
              // _id,
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-----------Status Group-tag api----------//
  function changeGroupTagStatus(taggroupId) {
    let data = JSON.stringify({
      taggroupId: taggroupId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changetagsgrouptatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-----------Delete Group-tag api----------//
  function deleteGroup(index) {
    let data = JSON.stringify({
      grouptagId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletegrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Get tag API ---//
  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const options = [];
        response.data.tagData.forEach((tag, index) => {
          if (tag.status === true) {
            options.push({ value: tag._id, label: tag.tags });
          }
        });
        setoptions(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    console.log(uploadImages);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/taggroupbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getgrouptags();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const headers = [
    { label: "Group", key: "groupName" },
    { label: "Tags", key: "tags" },
    { label: "Status", key: "status" },
  ];
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Tag-Group
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#createGroup"
                    onClick={() => setIsOpen(true)}
                  >
                    Add New Tag-Group
                  </button>

                  <CSVLink
                    data={downloadData}
                    headers={headers}
                    filename="GroupTag.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Group Tag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {console.log(errors, "kdjlajsdjjld")}
                            {uploaderrors?.uploadImages?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* //--Add Tag-Group ---// */}
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Tag-Group
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => somefun()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(addgrouptag)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label"> Name </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("groupName", {
                                        required: true,
                                      })}
                                    />
                                    {errors?.groupName?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Tags
                                  </label>
                                  <Controller
                                    name="tags"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isMulti
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={options}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          handlechangetag(event);
                                        }}
                                      />
                                    )}
                                  />

                                  {Object.keys(errors).includes("tags") && (
                                    <p style={{ color: "red" }}>
                                      {errors.tags.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger CancelBtn"
                                onClick={() => somefun()}
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* //--//---// */}

              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Tag-Group
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteGroup(DeleteGroupId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdateGroupData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <Updategroup
                        updatedData={UpdateGroupData}
                        onEditDataFunction={editdataReloadFunc}
                        editIsOpen={editIsOpen}
                        close={close}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className="modal fade"
                id="DetailGroup"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Group Tag Details
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <tr>
                                    <th>Name :</th>
                                    <td>{GroupView.groupName}</td>
                                  </tr>
                                  <tr>
                                    <th>Tags :</th>
                                    <td>{viewTags?.join(", ")}</td>
                                  </tr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ height: 440, width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    tagsRenderer: TagsRenderer,
                    groupRenderer: GroupRenderer,
                    statusRenderer: StatusRenderer,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    flex={2}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                    // pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={2}
                    cellRenderer="groupRenderer"
                    headerName="Tag-Group"
                    field="groupName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    // pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={2}
                    field="status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    // pinned="left"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Grouptags;
