import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import RenderImage from "../../components/Image";

function SignupInvitation() {
  const {
    register,
    handleSubmit,
    clearErrors,
    unregister,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onBlur" });

  const [content, setContent] = useState("");
  const [authorImage, setAuthorImage] = useState("");
  const [authorDetails, setAuthorDetails] = useState({});
  const [options, setoptions] = useState([]);
  const [imagedataabout, setImageDataAbout] = useState(null);

  const values = async () =>
    await fetch(`${process.env.REACT_APP_BASEURL}/getsigninvite`, {
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const result = data?.result;
        // if (result?.type === "GARDENER") {
        //   let authorDetail = result?.userName;
        //   authorDetail["authorImage"] = result?.userName?.file;
        //   setAuthorDetails(authorDetail);
        // }

        // if (result?.type === "AUTHOR") {
        //   setAuthorDetails(result?.authorName);
        // }

        // if (result?.type === "AUTHOR") {
        setAuthorDetails(result?.authorName);
        setImageDataAbout(result?.image);
        // }
        setContent(result?.contentBox);
        reset({
          authorimage: result?.authorName.authorimage,
          authorDetail: {
            label: result?.authorName?.authorName,
            value: result?.authorName?._id,
            self: result?.authorName,
          },
          contentBox: result?.contentBox,
          authordescription: result?.authorName?.authordescription,
          articleSubTitle: result?.articleSubTitle,
          headline: result?.headline,
          subheadline: result?.subheadline,
          tags: result?.tags.map((item) => ({
            label: item?.tags,
            value: item?._id,
          })),
          uploadFile: result.image.length > 0 ? "form directory" : "file",
        });
      })
      .catch((error) => console.log(error, "error update"));

  useEffect(() => {
    getnewtag();
    getImageCategory();
    getAbout();
  }, []);
  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const tagList = response.data.tagData
          .filter((tag) => tag.status == true)
          ?.map((tag) => ({ value: tag._id, label: tag.tags }));
        setoptions(tagList);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //--------Function to render All images--------//

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  //-----Get-Author's-------//
  useEffect(() => {
    getauthor();
    values();
  }, []);
  const [authorListing, setAuthorListing] = useState([]);

  function getauthor() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data.result.map((item) => ({
          value: item?._id,
          label: item?.authorName,
          self: item,
        }));
        setAuthorListing(data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isLoading, setIsLoading] = useState({ draft: false, publish: true });

  async function signupInvitationForm({
    image,
    headline,
    subheadline,
    contentBox,
    tags,
    authorDetail,
  }) {
    setIsLoading({
      publish: true,
    });
    // setError(null); // Clear previous errors when a new request starts
    try {
      let formData = new FormData();

      const post_tags = tags
        ?.map((item) => ({ id: item.value }))
        ?.map((item) => item.id.replace(/'/g, ""));
      console.log(authorDetail, "authorIdauthorIdauthorId");
      if (authorDetail && authorDetail?.self?.uniqueId)
        formData.append("authorId", authorDetail?.self?.uniqueId);
      if (watch("uploadFile") === "from directory")
        formData.append("image", image);
      else if (image?.[0]) formData.append("image", image[0] ?? "");
      if (headline) formData.append("headline", headline);
      if (subheadline) formData.append("subheadline", subheadline);
      if (contentBox) formData.append("contentBox", contentBox);
      if (post_tags) formData.append("tags", post_tags);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/updatesigninvite/${aboutDetails?._id}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );

      console.log(response, "response");

      if (!response.ok) {
        throw new Error(
          "Failed to submit the data. Please Fill the required fields."
        );
      }

      // Handle response if necessary
      const data = await response.json();
      // ...

      if (data.status) {
        toast.success(data.message);
        getAbout();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      // setError(error.message);
      toast.error(error.message);
      console.error(error);
    } finally {
      setIsLoading({
        publish: true,
      });
    }
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }
  const [aboutDetails, setAboutDetails] = useState({});
  function getAbout() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getsigninvite`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const details = response.data.result;
        setAboutDetails(details);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setAuthorImage(display);
        },
      });
    }
  };

  const validateFileSize = (file) => {
    if (file && file[0]) {
      const fileSize = file[0].size / 1024;
      return fileSize <= 500;
    }
    return false;
  };

  return (
    <>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(signupInvitationForm)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <div className="d-flex">
                  <h4 className="card-title">Signup Invitation</h4>
                  <button
                    type="button"
                    className="btn submitBtn mb-2 mx-2"
                    onClick={() => {
                      setIsLoading({ publish: true });
                      clearErrors();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn submitBtn mb-2"
                    onClick={() => {
                      setIsLoading({ publish: false });
                    }}
                  >
                    Edit
                  </button>
                </div>

                <div className="branchData">
                  <div className="row">
                    {/* //----Author -section---// */}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Author Image</label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "148px" }}>
                              {renderImages(
                                authorDetails?.authorImage ??
                                  "../../images/defalutimg.svg"
                              )}
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Author Name</label>
                            <Controller
                              name="authorDetail"
                              control={control}
                              rules={{
                                required: "This field is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  isDisabled={isLoading.publish}
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={authorListing}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    setAuthorDetails(event.self);
                                  }}
                                />
                              )}
                            />
                            {Object.keys(errors).includes("authorDetail") && (
                              <p style={{ color: "red" }}>
                                {errors.authorDetail.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <label className="form-label">
                              Author Description
                            </label>
                            <textarea
                              type="textarea"
                              disabled
                              defaultValue={authorDetails?.authordescription}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*//----Article -section---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Headline</label>
                            <input
                              disabled={isLoading.publish}
                              type="text"
                              className="form-control"
                              {...register("headline", {
                                required: true,
                              })}
                              name="headline"
                            />{" "}
                            {errors?.headline?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Sub Headline</label>
                            <input
                              type="text"
                              disabled={isLoading.publish}
                              className="form-control"
                              {...register("subheadline", {
                                required: true,
                              })}
                              name="subheadline"
                            />{" "}
                            {errors?.subheadline?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-5 mb-3">
                          <label className="form-label">Description</label>
                          <div className="App">
                            <Editor
                              name="contentBox"
                              disabled={isLoading.publish}
                              defaultValue={content}
                              control={control}
                              placeholder="Write a Description..."
                            />
                          </div>
                          {errors?.contentBox?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}{" "}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select Tags </label>
                          <Controller
                            name="tags"
                            control={control}
                            rules={{
                              required: "This field is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isDisabled={isLoading.publish}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("tags") && (
                            <p style={{ color: "red" }}>
                              {errors.tags.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Image</label>
                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                disabled={isLoading.publish}
                                {...register("uploadFile", {
                                  required: true,
                                })}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                disabled={isLoading.publish}
                                {...register("uploadFile", {
                                  required: true,
                                })}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            {errors?.uploadFile?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    disabled={isLoading.publish}
                                    {...register("image", {
                                      required:
                                        watch("uploadFile") === "from directory"
                                          ? false
                                          : true,
                                      validate: validateFileSize,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("image");
                                    }}
                                  />

                                  {authorImage.length === 0 ? (
                                    <RenderImage image={imagedataabout} />
                                  ) : (
                                    <RenderImage image={authorImage} />
                                  )}

                                  {errors?.authorImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                  {errors?.image?.type === "validate" && (
                                    <p style={{ color: "red" }}>
                                      File size should be 500KB or less
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}

                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required: "This field is required",
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isDisabled={isLoading.publish}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}{" "}
                                <div class="row">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item) => (
                                      <Controller
                                        name="image"
                                        control={control}
                                        render={() => (
                                          <div
                                            className="col-md-3"
                                            onClick={() => {
                                              setValue("image", item.location);
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        disabled={isLoading.publish}
                        type="submit"
                        className="btn submitBtn me-3"
                        name="Publish"
                        onClick={() => {
                          register("articleType", { value: "Publish" });
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default SignupInvitation;
