import React, { useState, useEffect } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AiFillDelete } from "react-icons/ai";
import { MdDriveFileMove, MdContentCopy } from "react-icons/md";
import { BiSearchAlt } from "react-icons/bi";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

function SearchImageInDirectory() {
  //----useForm hook for upload image----//

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // useForm hook
  const {
    control: moveImageControl,
    handleSubmit: moveImageHandleSubmit,
    formState: { errors: moveImageErrors },
    reset: moveImageReset,
  } = useForm();

  const params = useParams();
  console.log(params, "params");
  const [directoryData, setDirectoryData] = useState({});
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [MoveCategoriesId, setMoveCategoriesId] = useState("");
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [directoryName, setDirectoryName] = useState("");

  function somefun() {
    setIsOpen(false);
    setEditIsOpen(false);
    setIsLoading(false);

    cancelForm();
  }
  function cancelForm() {
    reset();
  }
  function fun() {
    setIsOpen(false);
    setIsLoading(false);
  }

  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [moveIsOpen, setMoveIsOpen] = useState(false);

  function imagebulkupload({ uploadImages }) {
    // setIsLoading(true);
    console.log(uploadImages);
    const formData = new FormData();
    Object.values(uploadImages).forEach((file, index) => {
      formData.append(`images`, file);
    });
    // formData.append("images", uploadImages);
    formData.append("imageCategoryId", params?.id);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/imagebulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "images response");
        if (response.data.status) {
          toast.success(response.data.message);
          reset();
          somefun();
          searchImages();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    searchImages();
    getImageCategory();
  }, []);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getimagecategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result
          ?.filter((item) => item._id !== params?.id)
          ?.map((item) => ({
            label: item?.imageCategory,
            value: item?._id,
          }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  function deleteImageCategory(id, categoryId) {
    setIsLoading(true);
    let data = JSON.stringify({
      categoryId,
      id,
    });
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/deleteimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          searchImages(text);
          setDeleteCategoriesId("");
          setCategoryId("");
          setDeleteIsOpen(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function moveImageToDirectory({ pushImageCategoryId, moveType }) {
    setIsLoading(true);
    let data = JSON.stringify({
      moveType: moveType.value, // Move | Copy
      imageId: MoveCategoriesId,
      pullImageCategoryId: params?.id,
      pushImageCategoryId: pushImageCategoryId?.value,
    });
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/moveimage`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "move");
        if (response.data.status) {
          toast.success(response.data.message);
          searchImages(text);
          setMoveCategoriesId("");
          setMoveIsOpen(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
        moveImageReset();
      });
  }

  function searchImages(text) {
    let config = {
      method: "get",
      url: text
        ? `${process.env.REACT_APP_BASEURL}/imagebyname?search=${text}`
        : `${process.env.REACT_APP_BASEURL}/imagebyname`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "responseresponse");
        if (response.data.status) {
          setRowData(response?.data?.result);
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }
  const [text, setText] = useState("");
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Image Search
                <input
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
                <BiSearchAlt
                  onClick={() => {
                    searchImages(text);
                  }}
                />
                {/* <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Upload Images
                  </button>
                </div> */}
              </h4>
              <div>
                {/* Upload Images */}
                <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                  <form onSubmit={handleSubmit(imagebulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Images</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...register("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                unregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {console.log(errors, "kdjlajsdjjld")}
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div>
                {/* Move Image form one Directory to another Directory */}
                <Modal
                  show={moveIsOpen}
                  onHide={() => {
                    setMoveIsOpen(false);
                    moveImageReset();
                  }}
                >
                  <form onSubmit={moveImageHandleSubmit(moveImageToDirectory)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Move Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Folder Name</label>
                          <Controller
                            name="pushImageCategoryId"
                            control={moveImageControl}
                            rules={{
                              required: "This Field is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={directoryData}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                }}
                              />
                            )}
                          />

                          {Object.keys(moveImageErrors).includes(
                            "pushImageCategoryId"
                          ) && (
                            <p style={{ color: "red" }}>
                              {moveImageErrors.pushImageCategoryId.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Options</label>
                          <Controller
                            name="moveType"
                            control={moveImageControl}
                            rules={{
                              required: "This Field is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={[
                                  { label: "Move", value: "Move" },
                                  { label: "Copy", value: "Copy" },
                                ]}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                }}
                              />
                            )}
                          />

                          {Object.keys(moveImageErrors).includes(
                            "moveType"
                          ) && (
                            <p style={{ color: "red" }}>
                              {moveImageErrors.moveType.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setMoveIsOpen(false);
                          moveImageReset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* Delete Directory */}
              <div>
                <Modal
                  show={deleteIsOpen}
                  onHide={() => {
                    setDeleteIsOpen(false);
                    setDeleteCategoriesId("");
                    setCategoryId("");
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title> Delete Directory</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card>
                      <Card.Body>
                        Are You Sure You Want to Delete This Directory
                      </Card.Body>
                    </Card>{" "}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setDeleteIsOpen(false);
                        setDeleteCategoriesId("");
                        setCategoryId("");
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteImageCategory(DeleteCategoriesId, categoryId);
                      }}
                      disabled={isLoading}
                    >
                      {" "}
                      {isLoading ? "Loading..." : "Delete"}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <Row>
                {rowData?.length > 0 &&
                  rowData.map(
                    ({ images, imageCategoryId, _id: categoryId }, index) => {
                      return images?.map(({ _id, location }, index) => {
                        return (
                          <Col md={4}>
                            <Card
                              style={{ border: "2px solid #e2e2e2" }}
                              id={_id}
                            >
                              <Card.Body>
                                <Card.Img
                                  variant="top"
                                  src={location}
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                                {/* <Card.Text>
                                Some quick example text to build on the card title
                                and make up the bulk of the card's content.
                              </Card.Text> */}
                                <div
                                  className="iconActionList"
                                  style={{ top: "0", marginTop: "10px" }}
                                >
                                  <div className="DeleteIcon">
                                    <AiFillDelete
                                      className="ActionIcon"
                                      onClick={() => {
                                        setDeleteCategoriesId(_id);
                                        setCategoryId(imageCategoryId);
                                        setDeleteIsOpen(true);
                                      }}
                                    />
                                  </div>
                                  <div className="editIcon">
                                    <MdDriveFileMove
                                      className="ActionIcon"
                                      onClick={() => {
                                        setMoveCategoriesId(_id);
                                        setMoveIsOpen(true);
                                      }}
                                    />
                                  </div>
                                  <div className="editIcon">
                                    <MdContentCopy
                                      className="ActionIcon"
                                      onClick={() => {
                                        navigator.clipboard
                                          .writeText(location)
                                          .then(() => {
                                            toast.success(
                                              "Image Link successfully copied to clipboard"
                                            );
                                          })
                                          .catch((err) => {
                                            toast.error(
                                              "Unable to copy Image Link to clipboard"
                                            );
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      });
                    }
                  )}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SearchImageInDirectory;
