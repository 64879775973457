import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArticles.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import { Col } from "react-bootstrap";
import Iconprofile1 from "../../images/weather/sun1icon.png";
import Iconprofile2 from "../../images/weather/sun2icon.png";
import Iconprofile3 from "../../images/weather/sun3icon.png";
import Iconprofile4 from "../../images/weather/sun4icon.png";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import RenderImage from "../../components/Image";

function AddArticles() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];

  const resetForm = () => {
    setContent("");
    setAuthorImage("");
    setselectedOptionone("");
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    unregister,
    control,
    getValues,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useForm({ mode: "onBlur" });

  let navigate = useNavigate();
  const editor = useRef(null);
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [content, setContent] = useState("");
  const [ainumber, setAinumber] = useState("");
  const [wellgoHeadline, setWellgoHeadline] = useState("");
  const [wellgoContent, setWellgoContent] = useState("");
  const [metaDescription, setmetaDescription] = useState("");
  const [science, setScience] = useState("");
  const [articleSubTitle, setArticleSubTitle] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const [authorImage, setAuthorImage] = useState("");
  const [sendArticleImage, setSendArticleImage] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [wellgorithmsImage, setWellgorithmsImage] = useState("");
  const [sendwellgorithmsImage, setSendWellgorithmsImage] = useState("");
  const [globalImage, setGlobalImage] = useState("");
  const [sendglobalImage, setSendGlobalImage] = useState("");
  const [optionsgroup, setoptionsgroup] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState([]);
  const [FieldValue, setFieldValue] = useState([]);
  const [authorDetails, setAuthorDetails] = useState({});
  const [articleRanks, setarticleRanks] = useState("");
  const [options, setoptions] = useState([]);
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState([]);
  const [
    selectedOptiongroupTagLabelTagIds,
    setSelectedOptiongrouoTagLabelTagIds,
  ] = useState([]);
  const [
    selectedOptionProblemTagLabelTagIds,
    setSelectedOptionProblemTagLabelTagIds,
  ] = useState([]);
  const [Idgrouptag, setIdGroupTag] = useState([]);

  //----get tags API--//

  useEffect(() => {
    getnewtag();
    getgrouptags();
    getImageCategory();
  }, []);
  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const tagList = response.data.tagData
          .filter((tag) => tag.status == true)
          ?.map((tag) => ({ value: tag._id, label: tag.tags }));
        setoptions(tagList);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangetag = (event) => {
    let array = [];
    let arrayLabelids = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value });
    });

    setselectedOptionone(array);
    setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };

  const handlechangegrouptag = (event) => {
    if (event.length > 0) {
      const latestOption = event[event.length - 1];
      const latestOptionId = latestOption.value;
    }
    let array = [];
    let arrayLabelids = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value });
    });

    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
    setSelectedOptiongrouoTagLabelTagIds(arrayLabelids);
  };

  const handlechangeProblemtag = (event) => {
    let array = [];
    let arrayLabelids = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value });
    });
    setSelectedOptionProblemTagLabelTagIds(arrayLabelids);
  };

  const [group, setGroup] = useState("");
  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.result.forEach((grup, index) => {
          if (grup.status == true) {
            optionsgroup.push({ label: grup.groupName, value: grup._id });
          }
        });
        setoptionsgroup(optionsgroup);
        setGroup(response.data.result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
      multigroupArray.push({ id: option.value });
      arrayLabel.push(option.label);
    });

    // event.forEach((option) => {});
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
  };
  //--------//--------//--------//--------//

  //--------Function to handle and set Article Banner Image--------//

  const handleArticleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          const display = URL.createObjectURL(compressedResult);
          setArticleImage(display);
          setSendArticleImage(compressedResult);
          setArticleImageErr(false);
        },
      });
    }
  };

  ///

  //--------Function to handle and set Wellgorithms Image--------//

  const handleWellgorithmsImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setWellgorithmsImage(display);
          setSendWellgorithmsImage(compressedResult);
        },
      });
    }
  };
  //--------Function to handle and set Image Only--------//

  const handleGlobalImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setGlobalImage(display);
          setSendGlobalImage(compressedResult);
        },
      });
    }
  };
  //--------Function to render All images--------//

  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  //-----Get-Author's-------//
  useEffect(() => {
    getauthor();
  }, []);
  const [authorListing, setAuthorListing] = useState([]);

  function getauthor() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data.result
          .filter((item) => item?.status !== false)
          .map((item) => ({
            value: item?._id,
            label: item?.authorName,
            self: item,
          }));

        setAuthorListing(data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //------Weather Icons------//
  const Iconoptions = [
    {
      value: "icon1",
      label: <img src={Iconprofile1} className="custom-option-image" />,
      icon: Iconprofile1,
    },
    {
      value: "icon2",
      label: <img src={Iconprofile2} className="custom-option-image" />,
      icon: Iconprofile2,
    },
    {
      value: "icon3",
      label: <img src={Iconprofile3} className="custom-option-image" />,
      icon: Iconprofile3,
    },
    {
      value: "icon4",
      label: <img src={Iconprofile4} className="custom-option-image" />,
      icon: Iconprofile4,
    },
  ];
  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      {/* <img src={data.icon}   /> */}
      <span>{label}</span>
    </div>
  );
  //------------------//-------------------//

  const [loaderStatus, setloaderStatus] = useState("");
  //--------- Get Category Api -----//
  const [categoryname, setCategoryname] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const categories = response.data.CategoryData.filter(
          (category) => category.status === true
        ).map((category) => ({
          label: category?.category,
          value: category?._id,
        }));
        setCategoryData(categories);
        // setCategoryData(categories);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // --get Sub-category API--//
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");

  function getTopics(id) {
    // reset({ subcategory: null }, { keepValues: true });
    resetField("subcategory", {
      keepDrity: false,
      keepError: false,
      keepTouch: false,
      defaultValue: null, // when provided with defaultVlaue update both input value and internal defaultValues
    });

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettopic?categoryId=${id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),

        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const subcategories = response.data.result
          .filter((subcategory) => subcategory?.status === true)
          .map((subcategory) => ({
            value: subcategory?._id,
            label: subcategory?.topicName,
          }));

        setSubCategory(subcategories);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //-------- get Inners Api----//
  useEffect(() => {
    getinner();
  }, []);
  const [inners, setInners] = useState([]);
  const [innerWords, setInnerWords] = useState("");
  const [innerloaderStatus, setInnerloaderStatus] = useState("");
  function getinner() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const innerWordsData = response?.data?.result
          ?.map(
            (item) =>
              item.status === true && {
                label: item?.inner,
                value: item?._id,
              }
          )
          .filter(Boolean);

        setInners(innerWordsData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //-------//--------//

  //---------get Weather tag Api----//
  useEffect(() => {
    getWeather();
  }, []);
  const [optionsweather, setOptionsWeather] = useState([]);
  const [weatherData, setWeatherData] = useState("");
  function getWeather() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getweathertag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const weatherTagsData = response?.data?.result
          ?.filter((item) => item.status === true)
          .map((item) => ({
            label: item?.weatherTagName,
            value: item?._id,
          }));
        setOptionsWeather(weatherTagsData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-------------/Add more Button/----------------// // Initial input fields //
  const [inputFields, setInputFields] = useState([
    { id: 1, heading: "", content: "" },
  ]);

  const [isAddButtonError, setIsAddButtonError] = useState(false);

  const handleAddInput = () => {
    if (
      inputFields.every((item) => item.content.length && item.heading.length)
    ) {
      setInputFields([
        ...inputFields,
        {
          id: inputFields[inputFields.length - 1].id + 1,
          heading: "",
          content: "",
        },
      ]);
      setIsAddButtonError(false);
    } else {
      setIsAddButtonError(true);
    }
  };
  const handleRemoveInput = (id) => {
    setInputFields(inputFields.filter((field) => field.id !== id));
    setIsAddButtonError(false);
  };

  //-------------/Get Wellgorithms Api /----------------//
  useEffect(() => {
    getWellgorithms();
  }, []);
  const [wellgorithmsData, setWellgorithmsData] = useState([]);
  const [wellgoTitle, setWellgoTitle] = useState("");
  const [tWellgoTags, setWellgoTags] = useState([]);
  const [wellgorithmsDetails, setWellgorithmsDetails] = useState({});

  function getWellgorithms() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getwellgorithms`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const wellgorithmsData = response?.data?.result
          ?.filter((item) => item?.status === true)
          .map((item) => ({
            label: item?.name,
            value: item?._id,
            self: item,
          }));

        setWellgorithmsData(wellgorithmsData);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  //-------------//----------------//

  const [articleImageErr, setArticleImageErr] = useState(false);

  const [isLoading, setIsLoading] = useState({ draft: false, publish: false });

  async function addArticles(
    {
      ainumber,
      articleImage,
      articleSubTitle,
      articleTitle,
      category,
      articleDescription,
      groupTags,
      innerTag,
      problemTags,
      science,
      sciencename,
      scienceImage,
      subcategory,
      tags,
      weatherTag,
      wellgoContent,
      wellgoHeadline,
      wellgorithmsImage,
      wellgorithmsDetail,
      wellgorithmstags,
      wellgorithmgrouptags,
      wellgorithmsPoints,
      articleType,
      authorDetail,
      metaDescription,
      articleRank,
    },
    event
  ) {
    setIsLoading({
      draft: articleType === "Draft" ? true : false,
      publish: articleType === "Publish" ? true : false,
    });

    try {
      let formData = new FormData();

      const post_tags = tags
        ?.map((item) => ({ id: item.value }))
        ?.map((item) => item.id.replace(/'/g, ""));

      const post_welgotags = wellgorithmstags
        ?.map((item) => item.value)
        .join(",");

      const welgo_grptags = wellgorithmgrouptags
        ?.map((item) => item.value)
        .join(",");

      const Problemtags = problemTags
        ?.map((item) => ({ id: item.value }))
        ?.map((item) => item.id.replace(/'/g, ""));

      const post_groupTags = groupTags
        ?.map((item) => ({ id: item.value }))
        ?.map((item) => item.id.replace(/'/g, ""));

      if (authorDetail && authorDetail?.self?.uniqueId)
        formData.append("authorName", authorDetail?.self?.uniqueId);
      if (articleImage && articleImage)
        formData.append(
          "articleImage",
          articleImage[0] === "h" ? articleImage : articleImage[0]
        );
      if (scienceImage && scienceImage)
        formData.append(
          "scienceImage",
          scienceImage[0] === "h" ? scienceImage : scienceImage[0]
        );

      if (articleTitle) formData.append("articleTitle", articleTitle);
      if (articleSubTitle) formData.append("articleSubTitle", articleSubTitle);
      if (articleDescription)
        formData.append("articleDescription", articleDescription);
      if (science) formData.append("science", science);
      if (sciencename) formData.append("sciencename", sciencename);
      if (wellgorithmsDetail && Object.keys(wellgorithmsDetail).length) {
        if (wellgorithmsDetails?.wellgoImage)
          formData.append("wellgoImage", wellgorithmsDetails?.wellgoImage);
        if (wellgoHeadline) formData.append("wellgoHeadline", wellgoHeadline);

        formData.append(
          "wellgorithmsPoints",
          wellgorithmsPoints || wellgorithmsDetails?.wellgorithmsPoints
        );
        if (wellgoContent) formData.append("wellgoContent", wellgoContent);
        if (wellgorithmsDetail)
          formData.append("name", wellgorithmsDetail?.label);
        if (post_welgotags) formData.append("wellgorithmtags", post_welgotags);
        if (wellgorithmsDetail?.value)
          formData.append("wellgoID", wellgorithmsDetail?.value);
        if (welgo_grptags)
          formData.append("wellgorithmgrouptags", welgo_grptags);
      }
      // new data contenct
      if (ainumber) formData.append("aiMeter", ainumber);
      if (metaDescription) formData.append("metaDescription", metaDescription);
      if (articleRank) formData.append("articleRank", articleRank);
      if (category?.value) formData.append("categoryId", category.value);
      if (subcategory?.value) formData.append("topicId", subcategory?.value);
      if (weatherTag?.value) formData.append("weatherTag", weatherTag?.value);
      if (innerTag?.value) formData.append("innerId", innerTag?.value);
      if (post_tags) formData.append("tag", post_tags);
      if (Problemtags) formData.append("oppositeTags", Problemtags);
      if (post_groupTags) formData.append("groupTags", post_groupTags);
      if (inputFields)
        formData.append("headContent", JSON.stringify(inputFields));
      formData.append(
        "articleType",
        event?.type === "submit" ? "Publish" : event
      );

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/addarticles`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          "Failed to submit the data. Please Fill the required fields."
        );
      }

      // Handle response if necessary
      const data = await response.json();
      if (data.status) {
        toast.success(data.message);
        resetForm();
        setAddButtonStatus(false);
        setTimeout(() => {
          navigate("/Articles");
        }, 4000);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      // setError(error.message);
      console.error(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading({
        draft: false,
        publish: false,
      });
    }
  }

  const submitForm = () => {
    const formData = {
      ainumber: getValues("ainumber"),
      articleImage: getValues("articleImage"),
      articleSubTitle: getValues("articleSubTitle"),
      articleTitle: getValues("articleTitle"),
      articleDescription: getValues("articleDescription"),
      groupTags: getValues("groupTags"),
      problemTags: getValues("problemTags"),
      science: getValues("science"),
      scienceImage: getValues("scienceImage"),
      subcategory: getValues("subcategory"),
      tags: getValues("tags"),
      innerTag: getValues("innerTag"),
      category: getValues("category"),
      weatherTag: getValues("weatherTag"),
      wellgoContent: getValues("wellgoContent"),
      wellgorithmstags: getValues("wellgorithmstags"),
      wellgorithmgrouptags: getValues("wellgorithmgrouptags"),
      wellgorithmsPoints: getValues("wellgorithmsPoints"),
      wellgoHeadline: getValues("wellgoHeadline"),
      wellgorithmsDetail: getValues("wellgorithmsDetail"),
      articleType: getValues("articleType"),
      authorDetail: getValues("authorDetail"),
      metaDescription: getValues("metaDescription"),
      articleRank: getValues("articleRank"),
    };
    addArticles(formData, "Draft");
  };

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [imagesDataScience, setImagesDataScience] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  const hadelremovetagas = () => {
    setValue(
      "wellgorithmstags",
      wellgorithmsDetails?.tag.map((item) => ({
        value: item?._id,
        label: item?.tags,
      }))
    );
    setValue(
      "wellgorithmgrouptags",
      wellgorithmsDetails?.tagGroup?.map((item) => ({
        value: item?._id,
        label: item?.groupName,
      }))
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(addArticles)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Journals</h4>
                <div className="branchData">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Author Image</label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "148px" }}>
                              {renderImages(
                                authorDetails?.authorImage ??
                                  "../../images/defalutimg.svg"
                              )}
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Author Name</label>
                            <Controller
                              name="authorDetail"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={authorListing}
                                  onChange={(event) => {
                                    field.onChange(event);
                                    setAuthorDetails(event.self);
                                  }}
                                />
                              )}
                            />
                            {Object.keys(errors).includes("authorDetail") && (
                              <p style={{ color: "red" }}>
                                {errors.authorDetail.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <label className="form-label">
                              Author Description
                            </label>
                            <textarea
                              type="textarea"
                              disabled
                              defaultValue={authorDetails?.authordescription}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //---------------//----------// */}
                    {/* //----Category -section // SubCategory -section---// */}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category</label>
                          <Controller
                            name="category"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={categoryData}
                                onChange={(event) => {
                                  field.onChange(event);
                                  getTopics(event.value);
                                }}
                              />
                            )}
                          />
                          {Object.keys(errors).includes("category") && (
                            <p style={{ color: "red" }}>
                              {errors.category.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">SubCategory</label>
                          <Controller
                            name="subcategory"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                // defaultValue={options[0]}
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={subCategory}
                                isDisabled={
                                  subCategory?.length == 0 ? true : false
                                }
                                onChange={(event) => {
                                  field.onChange(event);
                                  let { value } = event;
                                  setSubCategoryName(value);
                                }}
                              />
                            )}
                          />
                          {Object.keys(errors).includes("subcategory") && (
                            <p style={{ color: "red" }}>
                              {errors.subcategory.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*//----Article -section---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Journals Image</label>
                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("articleImage", {
                                      required: true,
                                    })}
                                    onChange={(e) => {
                                      handleArticleImage(e);
                                      unregister("articleImage");
                                    }}
                                  />
                                  {errors?.articleImage?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      File is required
                                    </p>
                                  )}

                                  {articleImage.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={articleImage} />
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  // rules={{
                                  //   required: "This field is required",
                                  // }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event);
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}
                                <div className="d-flex  img_fix">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item) => (
                                      <Controller
                                        name="articleImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className="m-2"
                                            onClick={() => {
                                              setValue(
                                                "articleImage",
                                                item.location
                                              );
                                            }}
                                          >
                                            <img
                                              height={100}
                                              width={100}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("articleImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.articleImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Journals Title</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("articleTitle", {
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : true,
                              })}
                              name="articleTitle"
                            />
                            {errors?.articleTitle?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">
                              Journals SubTitle
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("articleSubTitle", {
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : true,
                              })}
                              name="articleSubTitle"
                            />{" "}
                            {errors?.articleSubTitle?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-5 mb-3">
                          <label className="form-label">
                            Journals Description
                          </label>
                          <div className="App">
                            <Editor
                              name="articleDescription"
                              defaultValue={content}
                              control={control}
                              placeholder="Write a Description..."
                              watch={watch}
                            />
                          </div>
                          {errors?.articleDescription?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}{" "}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select Tags </label>
                          <Controller
                            name="tags"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  handlechangetag(event);
                                }}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Problem Tag </label>
                          <Controller
                            name="problemTags"
                            control={control}
                            /* Remove the 'rules' property */
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event);
                                  handlechangeProblemtag(event);
                                }}
                              />
                            )}
                          />
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Weather Tags </label>
                          <Controller
                            name="weatherTag"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={optionsweather}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  setWeatherData(event.value);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("weatherTag") && (
                            <p style={{ color: "red" }}>
                              {errors.weatherTag.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*//----------//----------//*/}
                    {/*//----inner -Words---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">XBT Name</label>
                          <Controller
                            name="innerTag"
                            control={control}
                            // rules={{
                            //   required: "This field is required",
                            // }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={inners}
                                onChange={(event) => {
                                  field.onChange(event);
                                  setInnerWords(event.value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          {inputFields?.map((field, index) => (
                            <div key={`${field?.id}`}>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Distinction
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    setInputFields((prev) => {
                                      prev[index].heading = e.target.value;
                                      return prev;
                                    });
                                  }}
                                  onBlur={(e) => {
                                    setInputFields((prev) => {
                                      return prev;
                                    });
                                  }}
                                />
                              </div>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">Imagine</label>
                                <textarea
                                  className="form-control"
                                  onChange={(e) => {
                                    setInputFields((prev) => {
                                      prev[index].content = e.target.value;
                                      return prev;
                                    });
                                  }}
                                />
                              </div>
                              {index !== 0 && (
                                <div className="plusBtn">
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveInput(field.id)}
                                  >
                                    -
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                          {/* {isAddButtonError && (
                            <p style={{ color: "red" }}>
                              The Heading and Content fields are required
                            </p>
                          )} */}
                          <div className="minBtn">
                            {" "}
                            <button type="button" onClick={handleAddInput}>
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    {/*//----science / image---/*/}{" "}
                    {/* ??????????????????????????????? */}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Hero Name</label>
                          <input
                            className="form-control"
                            {...register("sciencename")}
                            name="sciencename"
                          />
                          {/* Remove the error message rendering */}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Hero Content</label>
                          <textarea
                            className="form-control"
                            {...register("science")}
                            name="science"
                          />
                          {/* Remove the error message rendering */}
                        </div>

                        <div className="col-md-2">
                          <label className="form-label">Hero Image</label>
                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFileScience")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFileScience")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFileScience", {
                                  required: true,
                                })}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFileScience") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("scienceImage")}
                                    onChange={(e) => {
                                      handleGlobalImage(e);
                                      unregister("scienceImage");
                                    }}
                                  />

                                  {globalImage.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={globalImage} />
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFileScience") ===
                              "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directoryScience"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : "This Filds is requred",
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesDataScience(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes(
                                  "directoryScience"
                                ) && (
                                  <p style={{ color: "red" }}>
                                    {errors.directoryScience.message}
                                  </p>
                                )}{" "}
                                <div class="row">
                                  {imagesDataScience &&
                                    imagesDataScience.length > 0 &&
                                    imagesDataScience.map((item) => (
                                      <Controller
                                        name="scienceImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className="col-md-3"
                                            onClick={() => {
                                              setValue(
                                                "scienceImage",
                                                item.location
                                              );
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFileScience") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("scienceImage", {
                                    required:
                                      watch("uploadFileScience") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.scienceImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">
                            Wellgorithm Image
                          </label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "148px" }}>
                              {renderImages(
                                wellgorithmsDetails?.wellgoImage ??
                                  "../../images/defalutimg.svg"
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Title</label>
                            <Controller
                              name="wellgorithmsDetail"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={wellgorithmsData}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    setWellgoTitle(event.value);
                                    setWellgorithmsDetails(event.self);
                                    setValue(
                                      "wellgoContent",
                                      event.self.content
                                    );
                                    setValue(
                                      "wellgoHeadline",
                                      event.self.headline
                                    );
                                    setValue(
                                      "wellgorithmstags",
                                      event?.self?.tag.map((item) => ({
                                        value: item?._id,
                                        label: item?.tags,
                                      }))
                                    );
                                    setValue(
                                      "wellgorithmgrouptags",
                                      event?.self?.tagGroup?.map((item) => ({
                                        value: item?._id,
                                        label: item?.groupName,
                                      }))
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Headline</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("wellgoHeadline")}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Content</label>
                            <textarea
                              className="form-control"
                              {...register("wellgoContent")}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Points
                            </label>
                            <input
                              type="number"
                              defaultValue={
                                wellgorithmsDetails?.wellgorithmsPoints
                              }
                              className="form-control"
                              {...register("wellgorithmsPoints")}
                              name="wellgorithmsPoints"
                            />
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Group Tags
                            </label>
                            <Controller
                              name="wellgorithmgrouptags"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={optionsgroup}
                                  onChange={(event) => {
                                    field.onChange(event);
                                    handlechangegrouptag(event);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Tags
                            </label>
                            <Controller
                              name="wellgorithmstags"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={options}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    handlechangetag(event);
                                  }}
                                />
                              )}
                            />
                            <button type="button" onClick={hadelremovetagas}>
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    {/*//----Meta description---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Meta description</label>
                          <textarea
                            className="form-control"
                            {...register("metaDescription")}
                            name="metaDescription"
                          />
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    {/*//----Ai Meter [0/100]---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Ai Meter [0/100] :
                          </label>
                          <div className="d-flex align-items-center">
                            <Col xs={11}>
                              <input
                                type="number"
                                className="form-control"
                                {...register("ainumber", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                  min:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : 0,
                                  max:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : 100,
                                })}
                                name="ainumber"
                                min={0}
                              />{" "}
                              {errors?.ainumber?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Enter number between 0 to 100
                                </p>
                              )}
                              {errors?.ainumber?.type === "min" && (
                                <p style={{ color: "red" }}>Not less than 0</p>
                              )}
                              {errors?.ainumber?.type === "max" && (
                                <p style={{ color: "red" }}>
                                  Not more than 100
                                </p>
                              )}
                            </Col>
                            <Col
                              xs={1}
                              style={{ padding: "10px", fontSize: "20px" }}
                            >
                              %
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    {/*//----articleRanks---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Article Ranks :</label>
                          <div className="d-flex">
                            <Col xs={11}>
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                {...register("articleRank", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                })}
                                name="articleRank"
                              />{" "}
                              {errors?.articleRank?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This Field is required{" "}
                                </p>
                              )}
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/Articles");
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        disabled={isLoading.publish}
                        type="submit"
                        className="btn submitBtn me-3"
                        name="Publish"
                        onClick={() => {
                          register("articleType", { value: "Publish" });
                        }}
                      >
                        {isLoading.publish ? "Loading..." : "Publish"}{" "}
                      </button>

                      <button
                        disabled={isLoading.draft}
                        type="button"
                        className="btn submitBtn me-3"
                        name="Draft"
                        onClick={() => {
                          clearErrors();
                          register("articleType", { value: "Draft" });
                          submitForm();
                        }}
                      >
                        {isLoading.draft ? "Loading..." : "Draft"}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </React.Fragment>
  );
}

export default AddArticles;
