import { useState, useMemo, useEffect, Fragment } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Wellgorithms.css";
import { MdModeEditOutline } from "react-icons/md";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { AiFillDelete } from "react-icons/ai";
import UpdateWellgorithms from "./UpdateWellgorithms";
import Select from "react-select";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import JsonDownload from "../../components/downloadjson";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";
import { CSVLink } from "react-csv";

const TopicsNameRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props.data.name}</span>
    </span>
  );
};
const CategoryNameRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props.data.tagId.tags}</span>
    </span>
  );
};
const SrNoRenderer = (props, ref) => {
  return <span className="profle_img_box">{props.rowIndex + 1}</span>;
};
const wellgoRank = (props, ref) => {
  return <span className="profle_img_box">{props.data.wellgoRank}</span>;
};

function Wellgorithms() {
  const rowHeight = 55;
  const [category, setCategory] = useState("");
  const [UpdatewellData, setUpdatewellData] = useState();
  const [rowData, setRowData] = useState([]);
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  const [active, setActive] = useState(-1);
  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
          setErr(false);
        },
      });
    }
  };

  const {
    register,
    handleSubmit,
    unregister,
    control,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploaderrors },
    reset: uploadreset,
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    cancelForm();
  }
  function fun() {
    setIsOpen(false);
    cross();
    setIcon("");
    setSendImage("");
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdatewellData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  // --get Group-Tag API--//

  function close() {
    setEditIsOpen(false);
    setUpdatewellData({});
    setRowData(rowData);
  }

  const [optionsgroup, setoptionsgroup] = useState([]);
  const [optionstag, setoptionstag] = useState([]);
  const [optionscategory, setoptionscategory] = useState([]);
  const [optionsSubCategory, setoptionsSubCategory] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState([]);
  const [selectCategoryID, setSelectCategoryID] = useState("");

  useEffect(() => {
    getgrouptags();
    getImageCategory();
    gettag();
    getCategory();
  }, []);
  useEffect(() => {
    getsubCategory();
  }, [selectCategoryID]);

  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
      multigroupArray.push({ id: option.value });
      arrayLabel.push(option.label);
    });
    setErr(false);
    setSelectedMultipleGroups(array);
  };

  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.result.forEach((grup, index) => {
          if (grup.status == true) {
            optionsgroup.push({ label: grup.groupName, value: grup._id });
          }
        });
        setoptionsgroup(optionsgroup);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function gettag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let options = [];
        response.data.tagData.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.tags, value: tag._id });
          }
        });
        setoptionstag(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // add sub category function
  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let options = [];
        response.data.CategoryData.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.category, value: tag._id });
          }
        });
        setoptionscategory(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function getsubCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubCategoryByCategoryID/${selectCategoryID}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log("responseresponse>>>>>>>>>>>>>>>>>", response);
        let options = [];
        response?.data?.data.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.topicName, value: tag._id });
          }
        });
        setoptionsSubCategory(options);
      })
      .catch(function (error) {
        // console.log(error);
        setoptionsSubCategory([]);
      });
  }

  // --Add Wellgorithms API--//
  const [err, setErr] = useState(false);

  function AddWellgorithms(postData) {
    if (
      selectedMultipleGroups.length === 0 &&
      watch("uploadFile") !== "from directory"
    ) {
      setErr(true);
    } else {
      setIsOpen(false);
      setErr(false);
      let data = new FormData();
      data.append("name", postData.name);
      data.append(
        "wellgoImage",
        postData.wellgoImage[0] === "h"
          ? postData.wellgoImage
          : postData.wellgoImage[0]
      );

      data.append("content", postData.content);
      data.append("wellgorithmsPoints", postData?.wellgorithmsPoints);
      data.append("wellgoRank", postData?.wellgoRank);
      data.append("headline", postData?.headline);
      data.append("subcategories", postData?.subcategories.value);
      data.append("categories", postData?.categories.value);
      data.append("categoriesname", postData?.categories.label);
      data.append("subcategoriesname", postData?.subcategories.label);
      data.append("pointtype", postData?.pointtype);
      data.append(
        "tagGroup",
        postData?.tagGroup?.map((item) => item.value).join(",")
      );
      data.append("tag", postData?.tag?.map((item) => item.value).join(","));
      setSendImage("");
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/addwellgorithms`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          getWellgorithms();
          // resetForm();
          reset();
        })
        .catch(function (error) {
          // console.log(error);
        });
      setIcon("");
      setSendImage("");
    }
  }

  const resetForm = () => {
    setCategory("");
    setIcon("");
  };

  // --get Wellgorithms API--//
  useEffect(() => {
    getWellgorithms();
  }, []);
  const [downloadData, setDownloadData] = useState([]);

  function getWellgorithms() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getwellgorithms`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.result);

        console.log("response.data.result", response.data.result);
        const downloadData = response.data.result?.map(
          ({
            name,
            content,
            tagGroup,
            _id,
            headline,
            wellgoRank,
            wellgorithmsPoints,
            wellgoImage,
            pointtype,
            categoriesname,
            subcategoriesname,
            tag,
            status,
          }) => ({
            name: name ? name : "",
            headline: headline ? headline : "",
            content: content ? content : "",
            pointsType: pointtype ? pointtype : "",
            wellgorithmsPoints: wellgorithmsPoints ? wellgorithmsPoints : "",
            wellgoRank: wellgoRank ? wellgoRank : "",
            wellgoImage: wellgoImage ? wellgoImage : "",
            tag:
              tag && tag.length > 0
                ? tag.map((gtag) => gtag?.tags).join(",")
                : "",
            tagGroup:
              tagGroup && tagGroup.length > 0
                ? tagGroup.map((gtag) => gtag?.groupName).join(",")
                : "",
            categorieName: categoriesname ? categoriesname : "",
            subcategorieName: subcategoriesname ? subcategoriesname : "",
            status: status ? status : "",
          })
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {});
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getWellgorithms();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  //---------delete Wellgorithms Api -----//
  function deleteOppositetags(index) {
    let data = JSON.stringify({
      wellgorithmsId: index,
    });

    let config = {
      method: "DELETE",
      url: `${process.env.REACT_APP_BASEURL}/deletewellgorithms`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getWellgorithms();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //------------Status-------------------//
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Wellgorithms?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeWellgorithmsStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };
  function changeWellgorithmsStatus(wellgorithmsId) {
    let data = JSON.stringify({
      wellgorithmsId: wellgorithmsId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changewellgorithmsstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getWellgorithms();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //------------Status-------------------//

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    console.log(uploadImages);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/wellgorithmsbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getWellgorithms();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.result, "RESULT");
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  //--------Function to handle and set Author Image--------//
  const [icon, setIcon] = useState("");
  const [sendImage, setSendImage] = useState("");

  const headers = [
    { label: "Wellgorithm", key: "name" },
    { label: "Challenge", key: "headline" },
    { label: "Summary", key: "content" },
    { label: "Points Type", key: "pointsType" },
    { label: "Points", key: "wellgorithmsPoints" },
    { label: "Rank", key: "wellgoRank" },
    { label: "Image", key: "wellgoImage" },
    { label: "Tags", key: "tag" },
    { label: "Tag Group", key: "tagGroup" },
    { label: "Category", key: "categorieName" },
    { label: "Sub Category", key: "subcategorieName" },
    { label: "Status", key: "status" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Wellgorithms
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Wellgorithms
                  </button>

                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="Wellgorithms.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Wellalgorithms</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {uploaderrors?.uploadImages?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Wellgorithms
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => fun()}
                            ></button>
                          </div>
                          <form onSubmit={handleSubmit(AddWellgorithms)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">name</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("name", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.name?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                {/* Add new fild headline */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Headline</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("headline", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.headline?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label"> content</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("content", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.content?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Points Type
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("pointtype", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.pointtype?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Wellgorithms Points
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      type="number"
                                      min={0}
                                      {...register("wellgorithmsPoints", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.wellgorithmsPoints?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Rank</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      type="number"
                                      min={0}
                                      {...register("wellgoRank", {
                                        required: true,
                                      })}
                                    />
                                    {errors?.name?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                {/* >>>>>>>>>>>>>>>>>>>>>>>> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Wellgoritms Image
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="file"
                                      />
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="from directory"
                                      />
                                    </label>
                                    <label className="form-label">
                                      Image URL
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="image url"
                                      />
                                    </label>
                                    {errors?.uploadFile?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    {watch("uploadFile") === "file" && (
                                      <ul className="imagesUpload">
                                        <li
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            {...register("wellgoImage", {
                                              required:
                                                watch("uploadFile") === "file"
                                                  ? true
                                                  : false,
                                            })}
                                            onChange={(e) => {
                                              handleAuthorImage(e);
                                              unregister("wellgoImage");
                                            }}
                                          />

                                          {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )}

                                          {errors?.wellgoImage?.type ===
                                            "required" && (
                                            <p style={{ color: "red" }}>
                                              This field is required
                                            </p>
                                          )}

                                          {errors?.wellgoImage?.type ===
                                            "validate" && (
                                            <p style={{ color: "red" }}>
                                              File size should be 500KB or less
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    )}
                                    {watch("uploadFile") ===
                                      "from directory" && (
                                      <Fragment>
                                        <Controller
                                          name="directory"
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              isSearchable={false}
                                              className="react-dropdown"
                                              classNamePrefix="dropdown"
                                              options={directoryData}
                                              onChange={(event) => {
                                                field.onChange(event); // Update the field value
                                                setImagesData(event.images);
                                              }}
                                            />
                                          )}
                                        />
                                        {Object.keys(errors).includes(
                                          "directory"
                                        ) && (
                                          <p style={{ color: "red" }}>
                                            {errors.directory.message}
                                          </p>
                                        )}{" "}
                                        <div class="imageList">
                                          {imagesData &&
                                            imagesData.length > 0 &&
                                            imagesData.map((item, index) => (
                                              <Controller
                                                name="wellgoImage"
                                                control={control}
                                                render={() => (
                                                  <div
                                                    className={`uploadedImg ${
                                                      active === index &&
                                                      "active"
                                                    }`}
                                                    onClick={() => {
                                                      setValue(
                                                        "wellgoImage",
                                                        item.location
                                                      );
                                                      setActive(index);
                                                    }}
                                                  >
                                                    <img
                                                      height={50}
                                                      width={50}
                                                      alt="icon"
                                                      src={item?.location}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            ))}
                                        </div>
                                      </Fragment>
                                    )}
                                    {watch("uploadFile") === "image url" && (
                                      <div>
                                        <input
                                          type="text"
                                          {...register("wellgoImage", {
                                            required:
                                              watch("uploadFile") ===
                                              "image url"
                                                ? true
                                                : false,
                                          })}
                                          placeholder="Enter Image URL"
                                        />

                                        {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                        {errors?.wellgoImage?.type ===
                                          "required" && (
                                          <p style={{ color: "red" }}>
                                            This field is required
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Wellgorithms Image
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="file"
                                      />
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="from directory"
                                      />
                                    </label>
                                    {errors?.uploadFile?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    {watch("uploadFile") === "file" && (
                                      <ul className="imagesUpload">
                                        <li
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            {...register("wellgoImage", {
                                              required:
                                                watch("uploadFile") ===
                                                "from directory"
                                                  ? false
                                                  : true,
                                              validate: validateFileSizes,
                                            })}
                                            onChange={(e) => {
                                              handleImage(e);
                                              unregister("wellgoImage");
                                            }}
                                          />
                                          {errors?.wellgoImage?.type ===
                                            "validate" && (
                                            <p style={{ color: "red" }}>
                                              File size should be 500KB or less
                                            </p>
                                          )}
                                          {icon.length == 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={icon} />
                                          )}

                                          {errors?.wellgoImage?.type ===
                                            "required" && (
                                            <p style={{ color: "red" }}>
                                              This field is required
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    )}
                                    {watch("uploadFile") ===
                                      "from directory" && (
                                      <Fragment>
                                        <Controller
                                          name="directory"
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              isSearchable={false}
                                              className="react-dropdown"
                                              classNamePrefix="dropdown"
                                              options={directoryData}
                                              onChange={(event) => {
                                                field.onChange(event); // Update the field value
                                                setImagesData(event.images);
                                              }}
                                            />
                                          )}
                                        />
                                        {Object.keys(errors).includes(
                                          "directory"
                                        ) && (
                                          <p style={{ color: "red" }}>
                                            {errors.directory.message}
                                          </p>
                                        )}{" "}
                                        <div class="row">
                                          {imagesData &&
                                            imagesData.length > 0 &&
                                            imagesData.map((item) => (
                                              <Controller
                                                name="wellgoImage"
                                                control={control}
                                                render={() => (
                                                  <div
                                                    className="col-md-3"
                                                    onClick={() => {
                                                      console.log(
                                                        item?.location,
                                                        "00000#####000000000"
                                                      );
                                                      setValue(
                                                        "wellgoImage",
                                                        item.location
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      height={50}
                                                      width={50}
                                                      alt="icon"
                                                      src={item?.location}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            ))}
                                        </div>
                                      </Fragment>
                                    )}
                                  </div>
                                </div> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Tag
                                  </label>
                                  <Controller
                                    name="tag"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isMulti
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={optionstag}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                        }}
                                      />
                                    )}
                                  />

                                  {Object.keys(errors).includes("tag") && (
                                    <p style={{ color: "red" }}>
                                      {errors.tag.message}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Group-Tag
                                  </label>
                                  <Controller
                                    name="tagGroup"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isMulti
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={optionsgroup}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          handlechangegroupmulti(event);
                                        }}
                                      />
                                    )}
                                  />

                                  {Object.keys(errors).includes("tagGroup") && (
                                    <p style={{ color: "red" }}>
                                      {errors.tagGroup.message}
                                    </p>
                                  )}
                                </div>

                                {/* Add new Filds  */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Categories
                                  </label>
                                  <Controller
                                    name="categories"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={optionscategory}
                                        onChange={(event) => {
                                          field.onChange(event);
                                          setSelectCategoryID(event?.value);
                                        }}
                                      />
                                    )}
                                  />

                                  {Object.keys(errors).includes("tag") && (
                                    <p style={{ color: "red" }}>
                                      {errors.tag.message}
                                    </p>
                                  )}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Sub Categories
                                  </label>
                                  <Controller
                                    name="subcategories"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={optionsSubCategory}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                        }}
                                      />
                                    )}
                                  />

                                  {Object.keys(errors).includes(
                                    "subcategories"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {errors.tag.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                // onClick={AddWellgorithms}
                                className="btn submitBtn"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* delete */}
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Wellgorithms
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteOppositetags(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdatewellData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <UpdateWellgorithms
                    updatedData={UpdatewellData}
                    onEditDataFunction={editdataReloadFunc}
                    editIsOpen={editIsOpen}
                    close={close}
                  />
                </div>
              )}

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    TopicsNameRenderer: TopicsNameRenderer,
                    categoryNameRenderer: CategoryNameRenderer,
                    statusRenderer: StatusRenderer,
                    wellgoRank: wellgoRank,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    flex={2}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="Rank"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    comparator={customLowerCaseComparator}
                    cellRenderer="wellgoRank"
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="TopicsNameRenderer"
                    headerName="Wellgorithms"
                    field="name"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Wellgorithms;
