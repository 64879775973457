import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Inners.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import UpdateInners from "./UpdateInners";
import JsonDownload from "../../components/downloadjson";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import { CSVLink } from "react-csv";

function Inners() {
  const {
    register: uploadRegister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
    reset: uploadReset,
  } = useForm({ mode: "onBlur" });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [UpdateTagsData, setUpdateTagsData] = useState();
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [tags, setTags] = useState("");
  const rowHeight = 55;
  const [editIsOpen, setEditIsOpen] = useState(false);
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateTagsData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const resetForm = () => {
    setTags("");
  };

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const InnersRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data?.inner}</span>
      </span>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getinner();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const statusValueGetter = (params) => {
    return params.data.status;
  };
  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsOpen(false);
    setIsBulkUploadOpen(false);
    uploadReset();
    cancelForm();
  }
  function cancelForm() {
    reset();
  }
  function fun() {
    setIsOpen(false);
  }
  //---------Get Inners Api -----//
  function close() {
    setEditIsOpen(false);
    setUpdateTagsData({});
    setRowData(rowData);
  }

  useEffect(() => {
    getinner();
  }, []);
  const [rowData, setRowData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    console.log(uploadImages);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/innerbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          reset();
          somefun();
          getinner();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getinner() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        console.log(response.data.result, "RESULT");
        const downloadData = response.data.result?.map(({ inner, _id }) => {
          return {
            inner: inner ? inner : "",
            // _id,
          };
        });
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------Add Inners Api -----//
  function addnewInner(postdata) {
    setIsOpen(false);
    let data = JSON.stringify({
      inner: postdata.inner,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        resetForm();
        uploadReset();
        getinner();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------Status Inners Api -----//

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this inner?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeTagStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };
  function changeTagStatus(innerId) {
    let data = JSON.stringify({
      innerId: innerId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changeinnerstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getinner();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------delete Inners Api -----//
  function deleteInner(index) {
    let data = JSON.stringify({
      innerId: index,
    });

    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASEURL}/deleteinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getinner();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const headers = [{ label: "XBT", key: "inner" }];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                XBT
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add XBT
                  </button>
                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="XBt.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              <div>
                {/* Upload Images */}
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={handleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload XBT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...register("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                unregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {console.log(errors, "kdjlajsdjjld")}
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* //--Add inners---// */}
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add XBT
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => somefun()}
                            ></button>
                          </div>
                          <form onSubmit={handlesubmitUpload(addnewInner)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Add Inner
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("inner", {
                                        required: true,
                                        min: {
                                          value: 1,
                                          message:
                                            "Please enter minimum 1 characters",
                                        },
                                      })}
                                    />

                                    {uploaderrors?.inner?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    {uploaderrors?.inner?.type === "min" && (
                                      <p style={{ color: "red" }}>
                                        Please enter minimum One characters
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* //--//---// */}

              {/* delete */}
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Inner</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteInner(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}
              {/* Edit */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdateTagsData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <UpdateInners
                        updatedData={UpdateTagsData}
                        onEditDataFunction={editdataReloadFunc}
                        editIsOpen={editIsOpen}
                        close={close}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Edit */}
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    innersRenderer: InnersRenderer,
                    statusRenderer: StatusRenderer,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="innersRenderer"
                    field="XBT"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="Status"
                    cellRenderer="statusRenderer"
                    colId="params"
                    sortable={true}
                    filter={true}
                    valueGetter={statusValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Inners;
