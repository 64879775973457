import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
const Updategroup = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  useEffect(() => {
    setEditData(props.updatedData);
  }, [props]);

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      groupName: props?.updatedData?.groupName,
      tags: props?.updatedData?.tags?.map((item) => ({
        label: item?.tags,
        value: item?._id,
      })),
    },
  });

  const [group, setGroup] = useState("");
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState(
    editGroupData?.tags?.map((item, index) => {
      return { value: item._id, label: item.tags };
    })
  );

  const handlechangetag = (event) => {
    let array = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    setselectedOptionone(array);
    setErr(false);
  };

  const resetForm = () => {
    setGroup("");
    setselectedOptionone([]);
  };
  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  // --Update tag API--//
  const [err, setErr] = useState(false);
  function updategroup(postdata) {
    if (selectedOptionone?.length === 0) {
      setErr(true);
    } else {
      setErr(false);
      props.close();
      let data = JSON.stringify({
        tagGroupId: editGroupData._id,
        groupName: postdata.groupName,
        tags: postdata?.tags?.map((item) => item.value).join(","),
      });
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/updateGrouptag`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resetForm();
          reset();
          props.onEditDataFunction();
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }
  // ---- Get tag API ---//
  useEffect(() => {
    getnewtag();
  }, []);

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        response.data.tagData.forEach((tag, index) => {
          if (tag.status === true) {
            options.push({ value: tag._id, label: tag.tags });
          }
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Tag-Group
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handleSubmit(updategroup)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Tag-Group </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              {...register("groupName", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {errors?.groupName?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {errors?.groupName?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Select Tags</label>
                          <Controller
                            name="tags"
                            control={control}
                            rules={{
                              required: "This field is required",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  handlechangetag(event);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("tags") && (
                            <p style={{ color: "red" }}>
                              {errors.tags.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Updategroup;
