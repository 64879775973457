import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddAds.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "suneditor/dist/css/suneditor.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Compressor from "compressorjs";
import RenderImage from "../../components/Image";
import Select from "react-select";
function UpdateAds() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue,
    unregister,
    watch,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [advertisement, setAdvertisement] = useState(null);
  const [active, setActive] = useState(-1);
  const params = useParams();

  useEffect(() => {
    const fetchAdvertisement = async () => {
      try {
        const response = await fetch(
          `https://api.inner.garden/admin/v1/getAdd?addId=${params?.id}`
        );
        if (response.ok) {
          const data = await response.json();
          setAdvertisement(data?.result);
          setValue("title", data?.result?.title);
          setValue("subtitle", data?.result?.subtitle);
          setValue("buttonlink", data?.result?.buttonLink);
          setValue("buttontext", data?.result?.buttonText);
          if (data?.result?.addImage) {
            setValue("addImage", data?.result?.addImage);
          }
          getImageCategory();
        } else {
          console.error("Failed to fetch advertisement");
        }
      } catch (error) {
        console.error("Error fetching advertisement:", error);
      }
    };

    fetchAdvertisement();
  }, []);

  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));

        console.log("directories>>", directories);
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }

  function AddAds(data) {
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("subtitle", data?.subtitle);
    formData.append("buttonText", data?.buttontext);
    formData.append("buttonLink", data?.buttonlink);

    formData.append(
      "addImage",
      data.addImage[0] === "h" ? data.addImage : data.addImage[0]
    );
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/cretaeAndUpdateAdd?id=${params?.id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        toast.success("Update Succesfully Ads ");
        setTimeout(() => {
          navigate("/ads");
        }, 4000);
      })
      .catch(function (error) {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setTimeout(() => {
          // setAddButtonStatus(false);
        }, 4000);
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Update Ads</h4>
              <div className="branchData">
                <form onSubmit={handleSubmit(AddAds)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("title", {
                                required: true,
                              })}
                            />
                            {errors?.title && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Sub Ttitle</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("subtitle", {
                                required: true,
                              })}
                            />
                            {errors?.subtitle && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Button Text</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("buttontext", {
                                required: true,
                              })}
                            />
                            {errors?.buttontext && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Button Link</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("buttonlink", {
                                required: true,
                              })}
                            />
                            {errors?.buttonlink && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>

                          {!watch("uploadFile") && (
                            <div className="col-md-12 mb-3 mt-3">
                              <label className="form-label">Ads Image</label>
                              <div className="position-relative">
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "128px",
                                      height: "128px",
                                    }}
                                  >
                                    {watch("addImage") && (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "148px",
                                        }}
                                        src={watch("addImage") ?? innericon}
                                      />
                                    )}
                                  </li>
                                </ul>
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div>
                          )}

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Category Image</label>

                            <div className="position-relative">
                              <label className="form-label">
                                Upload File
                                <input
                                  {...register("uploadFile")}
                                  type="radio"
                                  value="file"
                                />
                              </label>
                              <label className="form-label">
                                from directory
                                <input
                                  {...register("uploadFile")}
                                  type="radio"
                                  value="from directory"
                                />
                              </label>
                              <label className="form-label">
                                Image URL
                                <input
                                  {...register("uploadFile")}
                                  type="radio"
                                  value="image url"
                                />
                              </label>

                              {watch("uploadFile") === "file" && (
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      {...register("addImage", {
                                        required:
                                          watch("uploadFile") === "file"
                                            ? true
                                            : false,
                                      })}
                                      onChange={(e) => {
                                        handleAuthorImage(e);
                                        unregister("addImage");
                                      }}
                                    />

                                    {innericon.length === 0 ? (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "128px",
                                        }}
                                        src="../../images/defalutimg.svg"
                                      />
                                    ) : (
                                      <RenderImage image={innericon} />
                                    )}

                                    {errors?.addImage?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}

                                    {errors?.addImage?.type === "validate" && (
                                      <p style={{ color: "red" }}>
                                        File size should be 500KB or less
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              )}
                              {watch("uploadFile") === "from directory" && (
                                <Fragment>
                                  <Controller
                                    name="directory"
                                    control={control}
                                    rules={{
                                      required:
                                        watch("uploadFile") === "from directory"
                                          ? true
                                          : false,
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={directoryData}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          setImagesData(event.images);
                                        }}
                                      />
                                    )}
                                  />
                                  {Object.keys(errors).includes(
                                    "directory"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {errors.directory.message}
                                    </p>
                                  )}{" "}
                                  <div class="imageList">
                                    {imagesData &&
                                      imagesData.length > 0 &&
                                      imagesData.map((item, index) => (
                                        <Controller
                                          name="addImage"
                                          control={control}
                                          render={() => (
                                            <div
                                              className={`uploadedImg ${
                                                active === index && "active"
                                              }`}
                                              onClick={() => {
                                                setValue(
                                                  "addImage",
                                                  item.location
                                                );
                                                setActive(index);
                                              }}
                                            >
                                              <img
                                                height={50}
                                                width={50}
                                                alt="icon"
                                                src={item?.location}
                                              />
                                            </div>
                                          )}
                                        />
                                      ))}
                                  </div>
                                </Fragment>
                              )}
                              {watch("uploadFile") === "image url" && (
                                <div>
                                  <input
                                    type="text"
                                    {...register("addImage", {
                                      required:
                                        watch("uploadFile") === "image url"
                                          ? true
                                          : false,
                                    })}
                                    placeholder="Enter Image URL"
                                  />

                                  {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                  {errors?.addImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <h4>Uploade Image</h4>
                            <input
                              type="file"
                              {...register("image")}
                              className="form-control"
                              placeholder="Chooese new file"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/add");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn me-3">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpdateAds;
