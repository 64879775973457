import { createContext, useState } from "react";
import * as React from "react";
import { modules } from "../shared/UserRoleManagement/roles";

let localdata = JSON.parse(sessionStorage.getItem("userData"));

const GlobalContext = createContext({
  moduleList: null,
  userData: localdata,
  islogout: () => {},
  isAuthenticated: (data) => {},
});

export function GlobalContextProvider(props) {
  const [userData, setUserData] = useState(localdata);
  // const [deskMenu, setdeskMenu] = useState(false);
  // const [showMore, setshowMore] = useState(false);

  function isAuthenticated(data) {
    setUserData(data);
  }
  function islogout() {
    localdata = null;
    setUserData("");
  }

  const context = {
    userData: userData,
    moduleList: modules,
    isAuthenticated: isAuthenticated,
    islogout: islogout,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
}

export default GlobalContext;
