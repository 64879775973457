import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddAds.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "suneditor/dist/css/suneditor.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";
import Select from "react-select";

function AddArtSeries() {
  const {
    register,
    control,
    handleSubmit,
    unregister,
    watch,

    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [active, setActive] = useState(-1);
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  useEffect(() => {
    getImageCategory();
  }, []);
  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  function AddAds(data) {
    console.log("data???????????????", data);
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("subtitle", data?.subtitle);
    formData.append("buttonText", data?.buttontext);
    formData.append("buttonLink", data?.buttonlink);
    formData.append(
      "addImage",
      data.addImage[0] === "h" ? data.addImage : data.addImage[0]
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/cretaeAndUpdateAdd`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        toast.success("Create Succesfully Ads ");
        setTimeout(() => {
          navigate("/ads");
        }, 4000);
      })
      .catch(function (error) {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setTimeout(() => {
          // setAddButtonStatus(false);
        }, 4000);
      });
  }
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add New Ads</h4>
              <div className="branchData">
                <form onSubmit={handleSubmit(AddAds)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("title", {
                                required: true,
                              })}
                            />
                            {errors?.title && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Sub Ttitle</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("subtitle", {
                                required: true,
                              })}
                            />
                            {errors?.subtitle && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Button Text</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("buttontext", {
                                required: true,
                              })}
                            />
                            {errors?.buttontext && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Button Link</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("buttonlink", {
                                required: true,
                              })}
                            />
                            {errors?.buttonlink && (
                              <span style={{ color: "red" }}>
                                This Field is required
                              </span>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Uploade Ads Image
                            </label>

                            <div className="position-relative">
                              <label className="form-label">
                                Upload File
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="file"
                                />
                              </label>
                              <label className="form-label">
                                from directory
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="from directory"
                                />
                              </label>
                              <label className="form-label">
                                Image URL
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="image url"
                                />
                              </label>
                              {errors?.uploadFile?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                              {watch("uploadFile") === "file" && (
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      {...register("addImage", {
                                        required:
                                          watch("uploadFile") === "file"
                                            ? true
                                            : false,
                                      })}
                                      onChange={(e) => {
                                        handleAuthorImage(e);
                                        unregister("addImage");
                                      }}
                                    />

                                    {innericon.length === 0 ? (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "128px",
                                        }}
                                        src="../../images/defalutimg.svg"
                                      />
                                    ) : (
                                      <RenderImage image={innericon} />
                                    )}

                                    {errors?.addImage?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              )}
                              {watch("uploadFile") === "from directory" && (
                                <Fragment>
                                  <Controller
                                    name="directory"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={directoryData}
                                        onChange={(event) => {
                                          field.onChange(event);
                                          setImagesData(event.images);
                                        }}
                                      />
                                    )}
                                  />
                                  {Object.keys(errors).includes(
                                    "directory"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {errors.directory.message}
                                    </p>
                                  )}{" "}
                                  <div class="imageList">
                                    {imagesData &&
                                      imagesData?.length > 0 &&
                                      imagesData?.map((item, index) => (
                                        <Controller
                                          name="addImage"
                                          control={control}
                                          render={() => (
                                            <div
                                              className={`uploadedImg ${
                                                active === index && "active"
                                              }`}
                                              onClick={() => {
                                                setValue(
                                                  "addImage",
                                                  item.location
                                                );
                                                setActive(index);
                                              }}
                                            >
                                              <img
                                                height={50}
                                                width={50}
                                                alt="icon"
                                                src={item?.location}
                                              />
                                            </div>
                                          )}
                                        />
                                      ))}
                                  </div>
                                </Fragment>
                              )}
                              {watch("uploadFile") === "image url" && (
                                <div>
                                  <input
                                    type="text"
                                    {...register("addImage", {
                                      required:
                                        watch("uploadFile") === "image url"
                                          ? true
                                          : false,
                                    })}
                                    placeholder="Enter Image URL"
                                  />

                                  {errors?.addImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/ads");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn me-3">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddArtSeries;
