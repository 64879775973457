import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LandingCategory.css";
import Sidebar from "../Sidebar/sidebar";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    Authorization: localStorage.getItem("AdminToken"),
    "Content-Type": "application/json",
  },
});

function LandingCategory() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    getCategory();
  }, []);

  async function getCategory() {
    try {
      const response = await api.get("/getCategory");
      const filteredCategories = response.data.CategoryData.filter(
        (category) => category.status === true
      ).map((category) => ({
        label: category.category,
        value: category._id,
        homeStatus: category.homeStatus,
      }));
      setCategories(filteredCategories);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function onSubmit() {
    const selectedIds = [selectedCategory];
    try {
      const response = await api.patch("/categoryStatus", { id1: selectedIds });
      // setSelectedCategoryData(response.data);
      // Handle the response data as needed
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const cate = categories?.filter((item) => item?.homeStatus === true);

  return (
    <React.Fragment>
      <Sidebar />
      <div className={`footerCategory`}>
        <h2>Please Select Landing Page Categories</h2>
        <div className="footerCategorySelect">
          <h3>Cat One</h3>
          <select onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="" disabled selected>
              {cate[0]?.label}
            </option>
            {categories.map((category) => (
              <option
                key={category.value}
                value={category.value}
                selected={category.homeStatus}
              >
                {category.label}
              </option>
            ))}
          </select>
        </div>
        <button
          className="footerCategorySaveButton"
          type="button"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
}

export default LandingCategory;
