import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import axios from "axios";
import "./Gardener.css";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";

const UpdateStudents = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  const [imageShown, setImageShown] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedjournal, setSelectedJournal] = useState([]);
  const [selecteddrivers, setSelecteddrivers] = useState([]);

  const [grouptagData, setData] = useState([]);
  const baseUrl = "https://api.inner.garden/api/grouptagsget";
  useEffect(() => {
    setEditData(props.updatedData);
  }, [props]);

  const Introduceyourself =
    props?.updatedData?.introduce === "undefined"
      ? props?.updatedData?.aboutcommunitystrengths?.about?.userIntroduce
      : props?.updatedData?.introduce;

  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      Introduceyourself: Introduceyourself,
      title: props?.updatedData?.aboutcommunitystrengths?.about?.title,
      inspiration: props?.updatedData?.inspiration,
      image: props?.updateData?.aboutcommunitystrengths?.about?.coverImage,
      first_name: props?.updatedData?.first_name,
      last_name: props?.updatedData?.last_name,
      email: props?.updatedData?.email,
      description: props?.updatedData?.description,
      url: props?.updatedData?.url,
      insta: props?.updatedData?.insta,
      linkedin:
        props?.updatedData?.aboutcommunitystrengths?.community?.linkedin ==
        "undefined"
          ? ""
          : props?.updatedData?.aboutcommunitystrengths?.community?.linkedin,
      website:
        props?.updatedData?.aboutcommunitystrengths?.community?.website ==
        "undefined"
          ? ""
          : props?.updatedData?.aboutcommunitystrengths?.community?.website,
      aspiration:
        props?.updatedData?.aboutcommunitystrengths?.strength?.aspiration,
    },
  });

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }
  useEffect(() => {
    props.onEditDataFunction();
    setImageShown(props.updatedData?.file ?? "");
    reset({ image: props.updatedData.file });
  }, []);

  const ImagesDataCover =
    props?.updatedData?.aboutcommunitystrengths?.about?.coverImage &&
    props?.updatedData?.aboutcommunitystrengths?.about?.coverImage;

  const ImagesDataProfile =
    props?.updatedData?.aboutcommunitystrengths?.about?.userImage &&
    props?.updatedData?.aboutcommunitystrengths?.about?.userImage;

  function updateStudentData(postData) {
    props.close();

    const commaSeparatedValues = selected
      .map((selected) => selected.value)
      .join(",");
    const commaSeparatedselectedjournal = selectedjournal
      .map((selectedjournal) => selectedjournal?.label)
      .join(",");
    const commaSeparatedselectedselecteddrivers = selecteddrivers
      .map((selecteddrivers) => selecteddrivers?.label)
      .join(",");
    let formdata = new FormData();

    formdata.append("userId", props?.updatedData?._id);
    formdata.append("first_name", postData?.first_name);
    formdata.append("last_name", postData?.last_name);
    formdata.append("email", postData?.email);
    formdata.append("userIntroduce", postData?.Introduceyourself);
    formdata.append("userImage", postData?.image[0] || ImagesDataCover);
    formdata.append("coverImage", postData?.coverphoto[0] || ImagesDataProfile);
    formdata.append("linkedin", postData?.linkedin);
    formdata.append("website", postData?.website);
    formdata.append("aspiration", postData?.aspiration);
    formdata.append("title", postData?.title);
    formdata.append("inspiration", postData?.inspiration);
    formdata.append(
      "JournalGroup",
      commaSeparatedselectedjournal ||
        props?.updatedData?.aboutcommunitystrengths?.community?.JournalGroup
    );
    formdata.append(
      "drivers",
      commaSeparatedselectedselecteddrivers ||
        props?.updatedData?.aboutcommunitystrengths?.strength?.drivers ||
        ""
    );
    formdata.append(
      "strength",
      commaSeparatedValues ||
        props?.updatedData?.aboutcommunitystrengths?.strength?.strength
    );
    formdata.append(
      "Gardnertype",
      postData.Gardnertype || props.updatedData?.Gardnertype
    );
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editstudent`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {});
  }

  //--------Function to handle and set Image--------//

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const data = result?.taggroupData?.map((item) => ({
          label: item?.groupName,
          value: item?._id,
        }));
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [ImageShownPrifle, setImageShownPrifle] = useState();

  useEffect(() => {}, [ImageShownPrifle]);

  const handleImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];

      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setImageShown(display);
        },
      });
    }
  };
  const handleImageProfile = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];

      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setImageShownPrifle(display);
        },
      });
    }
  };

  const journalValues = [
    { label: "Courage", value: "Courage" },
    { label: "Kindness", value: "kindness" },
    { label: "Gratitude", value: "gratitude" },
    { label: "Hope", value: "hope" },
    { label: "Creativity", value: "Creativity" },
    { label: "Confidence", value: "confidence" },
    { label: "Laughter", value: "laughter" },
  ];

  const options = [
    {
      label: "I am journaling for a greater cause.",
      value: "I am journaling for a greater cause.",
    },
    {
      label: "I am showing others if I can do it, they can do it.",
      value: "I am showing others if I can do it, they can do it.",
    },
    {
      label: "I am a powerful force for change.",
      value: "I am a powerful force for change.",
    },
    {
      label: "I rise from the ashes, stronger than before.",
      value: "I rise from the ashes, stronger than before.",
    },
    {
      label: "I am a sculptor of dreams, shaping a beautiful tomorrow.",
      value: "I am a sculptor of dreams, shaping a beautiful tomorrow.",
    },
  ];

  const [validationMessage, setValidationMessage] = useState("");

  const handleChange = (selectedOptions) => {
    if (selectedOptions.length <= 5) {
      setSelectedJournal(selectedOptions);
      setValidationMessage("");
    } else {
      setValidationMessage("You can select up to 5 options only.");
    }
  };

  useEffect(() => {
    if (props?.updatedData?.aboutcommunitystrengths?.community?.JournalGroup) {
      const stringValues =
        props?.updatedData.aboutcommunitystrengths.community.JournalGroup;
      const arrayValues = stringValues ? stringValues.split(",") : [];
      const defaultSelected = arrayValues.map((group) => ({
        value: group.trim(),
        label: group.trim(),
      }));
      setSelectedJournal(defaultSelected);
    }
  }, [props?.updatedData]);

  useEffect(() => {
    if (props?.updatedData?.aboutcommunitystrengths?.strength?.strength) {
      const stringValues =
        props?.updatedData?.aboutcommunitystrengths?.strength?.strength;
      const arrayValues = stringValues ? stringValues.split(",") : [];

      const defaultSelected = arrayValues.map((group) => ({
        value: group.trim(),
        label: group.trim(),
      }));

      setSelected(defaultSelected);
    }
  }, [props?.updatedData]);

  useEffect(() => {
    if (props?.updatedData?.aboutcommunitystrengths?.strength?.drivers) {
      const stringValues =
        props?.updatedData?.aboutcommunitystrengths?.strength?.drivers;

      const defaultSelected = stringValues.map((group) => ({
        value: group.trim(),
        label: group.trim(),
      }));

      setSelecteddrivers(defaultSelected);
    }
  }, [props?.updatedData]);

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <form onSubmit={handleSubmit(updateStudentData)}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Edit Gardener
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => fun()}
                      ></button>
                    </div>

                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Cover Image</label>
                          <div className="position-relative">
                            <ul className="imagesUpload">
                              <li
                                style={{
                                  width: "100%",
                                  height: "128px",
                                }}
                              >
                                <input
                                  type="file"
                                  {...register("coverphoto", {
                                    required: getValues("coverphoto")
                                      ? false
                                      : "This Field is Required",
                                  })}
                                  onChange={(e) => {
                                    handleImage(e);
                                    unregister("coverphoto");
                                  }}
                                />

                                {imageShown.length === 0 &&
                                ImagesDataCover === "undefined" ? (
                                  <img
                                    alt="renderImages"
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                      objectFit: "cover",
                                    }}
                                    src="../../images/defalutimg.svg"
                                  />
                                ) : (
                                  <RenderImage
                                    image={imageShown || ImagesDataCover}
                                  />
                                )}

                                {errors?.image?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </li>
                            </ul>

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Gardener Image</label>
                          <div className="position-relative">
                            <ul className="imagesUpload">
                              <li
                                style={{
                                  width: "100%",
                                  height: "128px",
                                }}
                              >
                                <input
                                  type="file"
                                  {...register("image", {
                                    required: getValues("image")
                                      ? false
                                      : "This Field is Required",
                                  })}
                                  onChange={(e) => {
                                    handleImageProfile(e);
                                    unregister("image");
                                  }}
                                />

                                {imageShown === "undefined" &&
                                ImagesDataProfile === "undefined" ? (
                                  <img
                                    alt="renderImages"
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                    src="../../images/defalutimg.svg"
                                  />
                                ) : (
                                  <RenderImage
                                    image={
                                      ImageShownPrifle || ImagesDataProfile
                                    }
                                  />
                                )}

                                {errors?.image?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </li>
                            </ul>

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Introduce yourself
                          </label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("Introduceyourself")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Your inspiration</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("inspiration")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Your Title</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("title")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">first Name</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("first_name", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {errors?.first_name?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}{" "}
                            {errors?.first_name?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Last Name</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("last_name", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {errors?.last_name?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}{" "}
                            {errors?.last_name?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Gardener Email</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                              readOnly
                            />

                            {errors?.email?.type === "pattern" && (
                              <p className="error">Invalid email address</p>
                            )}
                            {errors?.email?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Website</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("website", {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Linkedin</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("linkedin", {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Aspiration</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("aspiration", {
                                required: false,
                              })}
                            />
                          </div>
                        </div>
                        <div>
                          <label className="d-block">
                            Would you like to join a journal group?
                          </label>
                          <small>
                            (You can always join later) - Select up to 5
                          </small>
                          <Select
                            isMulti
                            options={grouptagData}
                            value={selectedjournal}
                            onChange={handleChange}
                          />
                          {validationMessage && (
                            <p style={{ color: "red" }}>{validationMessage}</p>
                          )}
                        </div>
                        <div>
                          <label className="d-block">
                            What are your strengths?
                          </label>

                          <CreatableSelect
                            isMulti
                            value={selected}
                            options={journalValues}
                            onChange={setSelected}
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="d-block">
                            What are your drivers?
                          </label>

                          <MultiSelect
                            options={options}
                            value={selecteddrivers}
                            onChange={setSelecteddrivers}
                            labelledBy={" What are your drivers?"}
                            isCreatable={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Gardener Type</label>
                        <div className="position-relative">
                          <select
                            {...register("Gardnertype")}
                            style={{ width: "100%" }}
                            defaultValue={props.updatedData?.Gardnertype}
                          >
                            <option value="gardeners">Gardeners</option>
                            <option value="composters">Composter</option>
                            <option value="pests">Pests</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateStudents;
