import React, { useState, useEffect } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArtSeries.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  articleSeriesList,
  updateArticleSeries,
} from "../../redux/reducers/articleSeriesReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Reorder from "../../components/Reorder";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";

function UpdateArtSeries(props) {
  const {
    register,
    handleSubmit,
    unregister,
    control,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    apiSeriesData,
    articleSeriesListCount,
    series,
    removeSeriesListCount,
    lifecycleType,
  } = useSelector((store) => store.articleSeries);

  const [optionscategory, setoptionscategory] = useState([]);
  const [optionsSubCategory, setoptionsSubCategory] = useState([]);
  const [selectCategoryID, setSelectCategoryID] = useState("");

  useEffect(() => {
    dispatch(articleSeriesList());
  }, []);

  useEffect(() => {
    setInputFields(apiSeriesData);
    articleseriesget(apiSeriesData);
  }, [articleSeriesListCount]);

  useEffect(() => {
    if (removeSeriesListCount && lifecycleType === "remove") {
      setInputFields(apiSeriesData);
      setArticles(series);
    }
  }, [removeSeriesListCount, series.length]);
  // console.log(apiSeriesData, "apiSeriesData");

  async function articleseriesget(series) {
    await fetch(
      `${process.env.REACT_APP_BASEURL}/getarticleseries?seriesId=${params?.id}`,
      {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const result = data?.result;
        const seqArticle = result?.titleId?.map((item) => ({
          label: item?.articleTitle,
          value: item?._id,
        }));
        const seqArticleIds = result?.titleId
          ?.map((item) => item?._id)
          .join(",");
        const remainingFields = series?.filter(
          (item) => !seqArticleIds.includes(item?.value)
        );

        const categories = {
          lable: result?.category?.category,
          value: result?.category?._id,
        };

        const subcategories = {
          lable: result?.subCategory,
          value: result?._id,
        };
        setNewTitleName(result?.title);
        setArticles(seqArticle);
        setInputFields(remainingFields);
        setDirectoryData(result?.seriesImage);
      });
  }

  // console.log(series, "apiSeriesData");
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  //-------------/Add more Button/----------------//
  const [inputFields, setInputFields] = useState([]); // Initial input fields
  // const handleAddInput = () => {
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       id: inputFields[inputFields.length - 1].id + 1,
  //     },
  //   ]);
  // };

  // const handleRemoveInput = (id) => {
  //   setInputFields(inputFields.filter((field) => field.id !== id));
  // };

  //---------------/Add-Series api/------------//
  const [articles, setArticles] = useState([]);
  const [newtitleName, setNewTitleName] = useState("");
  const [newtitleNameError, setNewTitleNameError] = useState(false);
  const [seriesError, setSeriesError] = useState(false);
  const [directoryData, setDirectoryData] = useState([]);
  const [uniqueSeriesError, setUniqueSeriesError] = useState(false);
  const [emptySeriesError, setEmptySeriesError] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({
    value: "",
    label: "",
  });
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [topicData, setTopicData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/gettopic`,
          {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTopicData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function EditSeries() {
    if (!newtitleName.trim() || !series.length) {
      !newtitleName.trim() && setNewTitleNameError(true);
      !series.length && setSeriesError(true);
      return;
    }
    setAddButtonStatus(true);
    const formData = new FormData();
    formData.append("title", newtitleName);
    formData.append("titleId", series.map((item) => item?.value).join(","));
    formData.append("seriesImage", directoryData);

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updatearticleseries/${params?.id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          navigate("/ArtSeries");
        }, 1000);
      })
      .catch(function (error) {
        toast.error("File size should be 500KB or less");
      })
      .finally(() => {
        setTimeout(() => {
          setAddButtonStatus(false);
        }, 4000);
      });
  }

  async function addingArticlesToSeriesContainer(event) {
    if (Object.values(selectedArticle).some((item) => item.length === 0)) {
      setEmptySeriesError(true);
      return;
    }
    if (articles.find((item) => item.value === selectedArticle?.value)) {
      setUniqueSeriesError(true);
      return;
    }

    const updateInputField = inputFields.filter(
      (item) => item.value !== selectedArticle?.value
    );

    setInputFields(updateInputField);

    dispatch(
      updateArticleSeries({ apiSeriesData: updateInputField, type: "update" })
    );

    setArticles((prev) => [...prev, selectedArticle]);
    setSeriesError(false);
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDirectoryData(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    // Trigger the file input when the image is clicked
    document.getElementById("fileInput").click();
  };

  const validateFileSize = (file) => {
    if (file && file[0]) {
      const fileSize = file[0].size / 1024;
      return fileSize <= 500;
    } else {
      return true;
    }
  };
  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTopicId(selectedId);
  };

  useEffect(() => {
    function getCategory() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getCategory`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let options = [];
          response.data.CategoryData.forEach((tag, index) => {
            if (tag.status == true) {
              options.push({ label: tag.category, value: tag._id });
            }
          });
          setoptionscategory(options);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
    getCategory();
  }, []);

  useEffect(() => {
    function getsubCategory() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getSubCategoryByCategoryID/${selectCategoryID}`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let options = [];
          response?.data?.data?.forEach((tag, index) => {
            if (tag.status == true) {
              options.push({ label: tag.topicName, value: tag._id });
            }
          });
          setoptionsSubCategory(options);
        })
        .catch(function (error) {
          setoptionsSubCategory([]);
        });
    }
    getsubCategory();
  }, [selectCategoryID]);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Paths</h4>
              <div className="branchData">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row bgBox mb-3">
                      <div className="col-md-12">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            value={newtitleName}
                            onChange={(e) => {
                              setNewTitleName(e.target.value);
                            }}
                            onBlur={() => {
                              setNewTitleNameError(false);
                            }}
                          />
                          {newtitleNameError && (
                            <p style={{ color: "red" }}>
                              This Field is required
                            </p>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Select Article Title
                          </label>
                          <Select
                            options={inputFields}
                            onChange={(event) => {
                              setSeriesError(false);
                              setEmptySeriesError(false);
                              setUniqueSeriesError(false);
                              setSelectedArticle(event);
                            }}
                          />
                          {seriesError && (
                            <p style={{ color: "red" }}>
                              This Field is required
                            </p>
                          )}
                          {emptySeriesError && (
                            <p style={{ color: "red" }}>
                              Please Select Something from above Dropdown
                            </p>
                          )}
                          {uniqueSeriesError && (
                            <p style={{ color: "red" }}>
                              You can't add the selected field again
                            </p>
                          )}
                          <button
                            type="button"
                            onClick={addingArticlesToSeriesContainer}
                          >
                            +
                          </button>
                        </div>
                        <Reorder articles={articles} />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className="position-relative">
                    <label className="form-label">Image:</label>
                    <div className="d-flex">
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        {...register("categoryImage", {
                          validate: {
                            validateFileSize: (file) =>
                              validateFileSize(file) ||
                              "File size should be 500KB or less",
                          },
                        })}
                        onChange={handleImageChange}
                        mode="onchange"
                      />
                      {errors.categoryImage && (
                        <p style={{ color: "red" }}>
                          {errors.categoryImage.message}
                        </p>
                      )}
                      <img
                        src={directoryData}
                        alt="image"
                        style={{ width: "200px", cursor: "pointer" }}
                        onClick={handleClick}
                      />
                    </div>
                  </div> */}
                  <br />

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Select Categories</label>
                    <Controller
                      name="categories"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isSearchable={false}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          options={optionscategory}
                          onChange={(event) => {
                            field.onChange(event);
                            setSelectCategoryID(event?.value);
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Select Sub Categories</label>
                    <Controller
                      name="subcategories"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isSearchable={false}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          options={optionsSubCategory}
                          onChange={(event) => {
                            field.onChange(event);
                            setSelectedTopicId(event?.value);
                          }}
                        />
                      )}
                    />

                    {Object.keys(errors).includes("subcategories") && (
                      <p style={{ color: "red" }}>{errors.tag.message}</p>
                    )}
                  </div>

                  {/* <div className="d-flex" style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <label className="form-label">Select Categories</label>
                      <select
                        onChange={handleSelectChange}
                        value={selectedTopicId}
                      >
                        {topicData?.result?.map((items, index) => (
                          <option key={index} value={items._id}>
                            {items?.topicName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn CancelBtn me-3"
                      onClick={() => {
                        navigate("/ArtSeries");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={addButtonStatus}
                      type="button"
                      className="btn submitBtn me-3"
                      onClick={() => {
                        EditSeries();
                      }}
                    >
                      {addButtonStatus ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpdateArtSeries;
