import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import sx from "./sidebar.module.css";

function Sidebar() {
  const [show, setShow] = useState(false);
  return (
    <aside className="left-sidebar col-md-4">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <h5>Menus</h5>

            {/* ................................. */}

            <li className="sidebar-item">
              <Dropdown>
                <Dropdown.Toggle id={`${sx["dropdown-basic"]}`}>
                  Journals
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <li>
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Articles"}
                      aria-expanded="false"
                    >
                      {/* {moduleItem.icon} */}
                      <span className="hide-menu">Journals</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/RequestArticles"}
                      aria-expanded="false"
                    >
                      {/* {moduleItem.icon} */}
                      <span className="hide-menu">Journals Request</span>
                    </NavLink>
                  </li>

                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/ArtSeries"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Paths</span>
                    </NavLink>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="sidebar-item">
              <Dropdown>
                <Dropdown.Toggle id={`${sx["dropdown-basic"]}`}>
                  Gardener
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <li>
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Author"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Author</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Users"}
                      aria-expanded="false"
                    >
                      {/* {moduleItem.icon} */}
                      <span className="hide-menu">Gardeners</span>
                    </NavLink>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="sidebar-item">
              <Dropdown>
                <Dropdown.Toggle id={`${sx["dropdown-basic"]}`}>
                  Category
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Category"}
                      aria-expanded="false"
                    >
                      Category
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Topics"}
                      aria-expanded="false"
                    >
                      SubCategory
                    </NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/footer-categories"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Footer categories</span>
                    </NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/landing-categories"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu"> Landing categories</span>
                    </NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/add-video"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Home Video Url</span>
                    </NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="sidebar-item">
              <Dropdown>
                <Dropdown.Toggle id={`${sx["dropdown-basic"]}`}>
                  Tags
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <li>
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Tag"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Tag</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Tag-Group"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Tag Group</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/Weathers"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Weathers Tag</span>
                    </NavLink>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="sidebar-item">
              <Dropdown>
                <Dropdown.Toggle id={`${sx["dropdown-basic"]}`}>
                  Inner Tv
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/innertv"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Inner Tv </span>
                    </NavLink>
                  </li>
                  <li className="sidebar-item">
                    <NavLink
                      className="sidebar-link waves-effect waves-dark sidebar-link"
                      to={"/innertvcategories"}
                      aria-expanded="false"
                    >
                      <span className="hide-menu">Inner Tv Categories </span>
                    </NavLink>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/Inners"}
                aria-expanded="false"
              >
                <span className="hide-menu">XBT</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/Wellgorithms"}
                aria-expanded="false"
              >
                <span className="hide-menu">Wellgorithms</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/policies"}
                aria-expanded="false"
              >
                <span className="hide-menu">Policies</span>
              </NavLink>
            </li>

            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/ads"}
                aria-expanded="false"
              >
                <span className="hide-menu">Add Ads</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/images"}
                aria-expanded="false"
              >
                <span className="hide-menu">Image Directory</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/contect"}
                aria-expanded="false"
              >
                <span className="hide-menu">Support</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
