import React, { useMemo, useState, useEffect, Fragment } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import UpdateStudents from "./UpdateGardener";
import "./Gardener.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Footer from "../Footer/footer";
import { AiFillEye } from "react-icons/ai";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import JsonDownload from "../../components/downloadjson";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import RenderImage from "../../components/Image";
import Compressor from "compressorjs";
import { exportToExcel } from "react-json-to-excel";
import { CSVLink } from "react-csv";

function Gardener() {
  function somefun() {
    setIsOpen(false);
    setIsBulkUploadOpen(false);
    cancelForm();
    setImageShown("");
    cross();
  }
  function fun() {
    setIsOpen(false);
    cross();
  }
  function cancelForm() {
    reset();
    setValue();
  }
  function cross() {
    reset();
    setValue();
  }
  const SrNoRenderer = (props) => {
    return (
      <>
        <span>{props.node.rowIndex + 1}</span>
      </>
    );
  };
  const GardenrRenderer = (props) => {
    return (
      <>
        <span>{props?.data?.uniqueId}</span>
      </>
    );
  };
  const DatecreatedByRenderer = (params) => {
    return <span>{params?.data?.createdAt?.split("T")[0]}</span>;
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const editdataReloadFunc = () => {
    getStudents();
  };
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Gardener?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeStudentStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const [UpdateStudentData, setUpdateCategoriesData] = useState({});
  const [DeleteDeleteId, setDeleteDeleteId] = useState("");
  const [rowData, setRowData] = useState([]);
  const [value, setValue] = useState();
  const rowHeight = 55;
  const [isOpen, setIsOpen] = useState(false);
  function close() {
    setEditIsOpen(false);
    setUpdateCategoriesData({});
    setRowData(rowData);
  }
  const [Viewuser, setViewuser] = useState([]);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateCategoriesData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon viewicon" />
        </div>

        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteDeleteId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteStudentData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const ImageRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <img
          className="profile_img_table"
          src={props?.data?.aboutcommunitystrengths?.about?.userImage}
          alt="icon"
        />
      </span>
    );
  };
  //--------validation-----//
  const {
    register,
    handleSubmit,
    control,
    unregister,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploadErrors },
    reset: uploadreset,
  } = useForm();

  // ------Add studentnew API-------//
  // const [image, setImage] = useState("");
  function addstudentnew(postdata) {
    setIsOpen(false);
    let formdata = new FormData();
    // formdata.append("file", postdata?.image[0]);
    formdata.append("firstname", postdata.firstname);
    formdata.append("lastname", postdata.lastname);
    formdata.append("yourinspiration", postdata.yourinspiration);
    formdata.append("Introduceyourself", postdata.Introduceyourself);
    formdata.append("email", postdata.email);
    formdata.append("Password", postdata.Password);
    formdata.append("gardenerType", postdata.gardenerType);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/register`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        getStudents();
        reset();
        setValue();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ------getStudents API-------//
  useEffect(() => {
    getStudents();
  }, []);
  const [downloadData, setDownloadData] = useState([]);
  function getStudents() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getstudent`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // Assuming response.data.data is an array of student objects
        setRowData(response?.data?.data);

        const mappedData = response?.data?.data?.map(
          ({
            inspiration = "",
            first_name = "",
            last_name = "",
            email = "",
            aboutcommunitystrengths,
            introduce = "",
            Gardnertype = "",
            uniqueId = "",
            status = "",
          }) => {
            const about = aboutcommunitystrengths?.about ?? {};
            const community = aboutcommunitystrengths?.community ?? {};
            const strength = aboutcommunitystrengths?.strength ?? {};

            const strengthArray = aboutcommunitystrengths?.strength?.drivers;
            const output = strengthArray?.join(", ");

            return {
              inspiration,
              first_name,
              last_name,
              email,
              title: about?.title ?? "",
              coverImage: about?.coverImage ?? "",
              userImage: about?.userImage ?? "",
              linkedin: community?.linkedin ?? "",
              website: community?.website ?? "",
              JournalGroup: community?.JournalGroup ?? "",
              aspiration: strength?.aspiration ?? "",
              strength: strength?.strength ?? "",
              drivers: output,
              userIntroduce: introduce,

              Gardnertype,
              uniqueId,
              status,
            };
          }
        );

        // Set the mapped data to downloadData state
        setDownloadData(mappedData);
      })
      .catch(function (error) {
        console.error("Error fetching students:", error);
        // Handle error
      });
  }

  // ------ Delete API -------//
  function studentdeleteData(index) {
    let data = JSON.stringify({
      studentId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletestudent`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getStudents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const defaultColDef = useMemo(() => {
    return {
      sortingoder: "desc",
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  // ------ status API -------//
  function changeStudentStatus(userId) {
    let data = JSON.stringify({
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changestudentprofilestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getStudents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    // console.log(uploadImages);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/gardnerbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getStudents();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  //--------Function to handle and set Image--------//
  const [imageShown, setImageShown] = useState("");
  const handleImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setImageShown(display);
        },
      });
    }
  };

  const headers = [
    { label: "Inspiration", key: "inspiration" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Title", key: "title" },
    { label: "Cover Image", key: "coverImage" },
    { label: "Profile Image", key: "userImage" },
    { label: "LinkedIn", key: "linkedin" },
    { label: "Website", key: "website" },
    { label: "Journal Group", key: "JournalGroup" },
    { label: "Aspiration", key: "aspiration" },
    { label: "Strength", key: "strength" },
    { label: "drivers", key: "drivers" },
    { label: "Introduce", key: "userIntroduce" },
    { label: "Type", key: "Gardnertype" },
    { label: "Unique ID", key: "uniqueId" },
    { label: "Status", key: "status" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      value = value.replace(/"/g, '""');
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Gardeners
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add New Gardener
                  </button>

                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="Gardener.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Gardener</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {uploadErrors?.uploadImages?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          uploadreset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <form onSubmit={handleSubmit(addstudentnew)}>
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Gardener
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={() => somefun()}
                              ></button>
                            </div>

                            <div className="modal-body">
                              <div className="row">
                                {/* <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Gardener Image
                                  </label>
                                  <div className="position-relative">
                                    <ul className="imagesUpload">
                                      <li
                                        style={{
                                          width: "100%",
                                          height: "128px",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          {...register("image", {
                                            required: "file is required",
                                            validate: validateFileSize,
                                          })}
                                          onChange={(e) => {
                                            handleImage(e);
                                            unregister("image");
                                          }}
                                        />

                                        {imageShown.length === 0 ? (
                                          <img
                                            alt="renderImages"
                                            style={{
                                              width: "100%",
                                              height: "128px",
                                            }}
                                            src="../../images/defalutimg.svg"
                                          />
                                        ) : (
                                          <RenderImage image={imageShown} />
                                        )}

                                        {errors?.image?.type === "required" && (
                                          <p style={{ color: "red" }}>
                                            This field is required
                                          </p>
                                        )}
                                        {errors?.image?.type === "validate" && (
                                          <p style={{ color: "red" }}>
                                            File size should be 500KB or less
                                          </p>
                                        )}
                                      </li>
                                    </ul>

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div> */}

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Introduce yourself
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("Introduceyourself")}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Your inspiration
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("yourinspiration")}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    First Name
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("firstname", {
                                        required: true,
                                        min: {
                                          value: 1,
                                          message:
                                            "Please enter minimum 1 characters",
                                        },
                                      })}
                                    />
                                    {errors?.firstname?.type === "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}

                                    {errors?.firstname?.type === "min" && (
                                      <p style={{ color: "red" }}>
                                        Please enter minimum One characters
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Last Name
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("lastname", {
                                        required: true,
                                        min: {
                                          value: 1,
                                          message:
                                            "Please enter minimum 1 characters",
                                        },
                                      })}
                                    />
                                    {errors?.lastname?.type === "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}

                                    {errors?.lastname?.type === "min" && (
                                      <p style={{ color: "red" }}>
                                        Please enter minimum One characters
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Gardener Email
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("email", {
                                        required: true,
                                        pattern:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      })}
                                    />
                                    {errors?.email?.type === "pattern" && (
                                      <p className="error">
                                        Invalid email address
                                      </p>
                                    )}
                                    {errors?.email?.type === "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Password</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("Password", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.Password?.type === "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}

                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Gardener Type
                                  </label>
                                  <div className="position-relative">
                                    <select
                                      {...register("gardenerType")}
                                      className="form-control"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="gardeners">
                                        Gardeners
                                      </option>
                                      <option value="composters">
                                        Composter
                                      </option>
                                      <option value="pests">Pests</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {editIsOpen && (
                <UpdateStudents
                  updatedData={UpdateStudentData}
                  onEditDataFunction={editdataReloadFunc}
                  editIsOpen={editIsOpen}
                  close={close}
                />
              )}
              <div
                className="modal fade DeletePopup"
                id="DeleteStudentData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Gardener ?
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                studentdeleteData(DeleteDeleteId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="UserViewId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Gardeners Detail{" "}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Name : {Viewuser.userName}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6> Gardener Email : {Viewuser.email}</h6>
                                  </div>

                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Description :{" "}
                                      {Viewuser.description}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6> Gardener Url : {Viewuser.url}</h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Facebook : {Viewuser.facebook}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Instagram : {Viewuser.insta}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Linkedin : {Viewuser.linkedin}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Twitter : {Viewuser.twitter}
                                    </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6>
                                      {" "}
                                      Gardener Threads : {Viewuser.threads}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  style={{ width: "100%" }}
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    DatecreatedByRenderer: DatecreatedByRenderer,
                    moodRenderer: ImageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    gardenrRenderer: GardenrRenderer,
                  }}
                >
                  <AgGridColumn
                    // width={10}
                    flex={1}
                    field="SrNo"
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={1}
                    field="uniqueId"
                    cellRenderer="gardenrRenderer"
                    headerName="uniqueId"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={1}
                    field="first_name"
                    headerName="User Name"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    field="email"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    headerName="Gardener Image"
                    field="file"
                    sortable={true}
                    cellRenderer="moodRenderer"
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={1}
                    cellRenderer="statusRenderer"
                    field="status"
                    sortable={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={1}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Gardener;
