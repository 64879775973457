import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddAuthor.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import RenderImage from "../../components/Image";
import Select from "react-select";
import { toast } from "react-toastify";

function UpdateAuthor() {
  let navigate = useNavigate();
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [authorImage, setAuthorImage] = useState("");
  const [sendAuthorImage, setSendAuthorImage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    setValue,
    control,
  } = useForm({ mode: "onBlur" });

  const resetForm = () => {
    setAuthorImage("");
  };
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/getAuthorById/${id}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setValue("authorImage", data?.data?.authorImage);
        setValue("authorName", data?.data?.authorName);
        setValue("authordescription", data?.data?.authordescription);
        setValue("authorType", data?.data?.Gardnertype);
        console.log("data>>>>>>>>>>....", data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchAuthor();
  }, []);

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setAuthorImage(display);
          setSendAuthorImage(compressedResult);
          setErr(false);
        },
      });
    }
  };
  //--------Function to render images--------//
  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  useEffect(() => {
    getImageCategory();
  }, []);
  //--------//-------//
  const [err, setErr] = useState(false);
  const [validName, setValidName] = useState("");
  function AddAuthor(postdata) {
    let data = new FormData();

    data.append(
      "authorImage",
      postdata.authorImage[0] === "h"
        ? postdata.authorImage
        : postdata.authorImage[0]
    );
    data.append("authorName", postdata.authorName);
    data.append("authorId", id);
    data.append("authordescription", postdata.authordescription);
    data.append("authorType", postdata.Gardnertype);

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/editauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setAddButtonStatus(false);
        navigate("/Author");
        resetForm("");
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = 1;
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows;
    setHeight(`${event.target.scrollHeight}px`);
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight;
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(AddAuthor)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Update Author</h4>
                <div className="branchData">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          {!watch("uploadFile") && (
                            <div className="col-md-12 mb-3 mt-3">
                              <label className="form-label">
                                Category Image
                              </label>
                              <div className="position-relative">
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "128px",
                                      height: "128px",
                                    }}
                                  >
                                    {watch("authorImage") && (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "148px",
                                        }}
                                        src={
                                          watch("authorImage") ?? authorImage
                                        }
                                      />
                                    )}
                                  </li>
                                </ul>
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div>
                          )}
                          <label className="form-label">Author Image</label>
                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="from directory"
                              />
                            </label>

                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("authorImage", {
                                      required:
                                        watch("uploadFile") === "from directory"
                                          ? false
                                          : true,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("authorImage");
                                    }}
                                  />

                                  {authorImage.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={authorImage} />
                                  )}

                                  {errors?.authorImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required: "This field is required",
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}{" "}
                                <div className="d-flex  img_fix">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item) => (
                                      <Controller
                                        name="authorImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className="selectable-image m-2 active"
                                            onClick={() => {
                                              setValue(
                                                "authorImage",
                                                item.location
                                              );
                                            }}
                                          >
                                            <img
                                              height={100}
                                              width={100}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("authorImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.categoryImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Author Name</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("authorName", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {errors?.authorName?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                            {errors?.authorName?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <p
                              style={{ color: "#FF0000", textAlign: "center" }}
                            >
                              {" "}
                              {validName}
                            </p>
                          </div>

                          <div className="col-md-12">
                            <label className="form-label">
                              Author Description
                            </label>
                            <textarea
                              type="textarea"
                              className="form-control"
                              style={{
                                height: height,
                                minHeight: "20px",
                                overflow: "hidden",
                                resize: "none",
                              }}
                              onMouseMove={handleInput}
                              {...register("authordescription", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />

                            {errors?.authordescription?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                            {errors?.authordescription?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Gardener Type</label>
                            <div className="position-relative">
                              <select
                                {...register("Gardnertype")}
                                style={{ width: "100%" }}
                                // defaultValue={props.updatedData?.Gardnertype}
                              >
                                <option value="gardeners">Gardeners</option>
                                <option value="composter">Composter</option>
                                <option value="pests">Pests</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/Author");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={addButtonStatus}
                        type="submit"
                        className="btn submitBtn me-3"
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default UpdateAuthor;
