import React, { useState, useEffect } from "react";
import axios from "axios";
import "./addvideo.css";
import Sidebar from "../Sidebar/sidebar";

function AddVideo() {
  const [youtubeLink, setYoutubeLink] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    getYouTubeUrl();
  }, []);

  function getYouTubeUrl() {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/getYouTubeUrl`)
      .then((response) => {
        // Handle the response data
        setId(response?.data?.data[0]?._id);
        setYoutubeLink(response?.data?.data[0]?.url);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function onSubmit() {
    const adminToken = localStorage.getItem("AdminToken");
    if (!adminToken) {
      console.error("Admin token not available.");
      return;
    }
    const data = {
      url: youtubeLink,
    };
    if (id) {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/createYoutubeLink?id=${id}`,
        headers: {
          Authorization: adminToken,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          console.log("Response:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/createYoutubeLink`,
        headers: {
          Authorization: adminToken,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          console.log("Response:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const handleInputChange = (event) => {
    setYoutubeLink(event.target.value);
  };

  return (
    <React.Fragment>
      <Sidebar />
      <div className={`footerCategory`}>
        <h2>Add Video Url For Landing Page </h2>
        <div className="footerCategorySelect">
          <input type="text" value={youtubeLink} onChange={handleInputChange} />
        </div>
        <button
          className="footerCategorySaveButton"
          type="button"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
}

export default AddVideo;
