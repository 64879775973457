import React, { useState, useEffect } from "react";
import axios from "axios";
import "./innerTv.css";
import { useForm } from "react-hook-form";
import Compressor from "compressorjs";
import Modal from "react-bootstrap/Modal";

const UpdateInnerTvCategories = (props) => {
  const [editGroupData, setEditData] = useState(props?.updatedData);
  useEffect(() => {
    setEditData(props.updatedData);
  }, [props]);

  const {
    register: uploadRegister,
    handleSubmit: handlesubmitUpload,
    reset,
    formState: { errors: uploaderrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      title: props?.updatedData?.title,
      subtitle: props?.updatedData?.subtitle,
      rank: props?.updatedData?.rank,
    },
  });

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  //--------Function to handle and set Weather Image--------//
  const [weatherImage, setWeatherImage] = useState("");
  const [sendWeatherImage, setSendWeatherImage] = useState("");

  function updateWeather(postdata) {
    console.log("postdata>>>>>>>>", postdata);
    props.close();

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updateInnerTV/${props?.updatedData?._id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: postdata,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {});
  }

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Inner Tv Categories
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handlesubmitUpload(updateWeather)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label className="form-label">Title</label>
                            <input
                              className="form-control"
                              {...uploadRegister("title", {
                                required: true,
                              })}
                            />
                            {uploaderrors?.title?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>

                          <div className="position-relative">
                            <label className="form-label">Sub Title</label>
                            <input
                              className="form-control"
                              {...uploadRegister("subtitle", {
                                required: true,
                              })}
                            />
                            {uploaderrors?.subtitle?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>

                          <div className="position-relative">
                            <label className="form-label">Rank</label>
                            <input
                              className="form-control"
                              {...uploadRegister("rank", {
                                required: true,
                              })}
                            />
                            {uploaderrors?.rank?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateInnerTvCategories;
