import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArticles.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { Col } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import defaultImage from "../../images/weather/defalutimg.svg";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import RenderImage from "../../components/Image";

function UpdateArticles() {
  const params = useParams();

  const values = async () =>
    await fetch(
      `${process.env.REACT_APP_BASEURL}/getarticlesbyId?articleId=${params?.id}`,
      {
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const result = data?.result;
        console.log("authorDetail>>>>>>>>>>>", result);

        setWellgorithmsImage(result);
        setArticleImage(result?.articleImage);
        setValue("articleImages", result?.articleImage);
        setGlobalImage(result?.scienceImage);
        const initialInputFields = result?.headContent.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        if (result?.type === "GARDENER") {
          let authorDetail = result?.userName;
          authorDetail["authorImage"] = result?.userName?.file;
          setAuthorDetails(authorDetail);
        }
        if (result?.type === "AUTHOR") {
          setAuthorDetails(result?.authorName);
        }
        setInputFields(initialInputFields);
        console.log("result?.type", result?.type);
        setUserType(result?.type);

        reset({
          authorimage:
            result?.type === "GARDENER"
              ? result?.userName?.file
              : result?.authorName?.authorImage,
          authorDetail: {
            label:
              result?.type === "GARDENER"
                ? result?.userName?.first_name +
                  " " +
                  result?.userName?.last_name
                : result?.authorName?.authorName,
            value:
              result?.type === "GARDENER"
                ? result?.userName?._id
                : result?.authorName?._id,
            self:
              result?.type === "GARDENER"
                ? result?.userName
                : result?.authorName,
          },
          authordescription:
            result?.type === "GARDENER"
              ? result?.userName?.aboutcommunitystrengths?.about
                  ?.userIntroduce || result?.userName?.introduce
              : result?.authorName?.authordescription,

          aiMeter: result?.aiMeter,
          articleImage: result?.articleImage,
          articleSubTitle: result?.articleSubTitle,
          articleTitle: result?.articleTitle,
          articleDescription: result?.articleDescription,
          articleRank: result?.articleRank ? result?.articleRank : "",
          wellgorithmsPoints: result?.wellgoVersionId?.wellgorithmsPoints,
          metaDescription: result?.metaDescription
            ? result?.metaDescription
            : "",
          categoryId: {
            label: result?.categoryId?.category,
            value: result?.categoryId?._id,
          },
          groupTags: result?.groupTags.map((item) => ({
            label: item?.groupName,
            value: item?._id,
          })),
          tags: result?.groupTags.map((item) => ({
            label: item?.groupName,
            value: item?._id,
          })),
          innerId: {
            label: result?.innerId?.inner,
            value: result?.innerId?._id,
          },
          oppositeTags: result?.oppositeTags.map((item) => ({
            label: item?.tags,
            value: item?._id,
          })),

          science: result?.science,
          sciencename: result?.sciencename,
          scienceImage: result?.scienceImage,
          subcategory: {
            label: result?.topicId?.topicName,
            value: result?.topicId?._id,
          },
          tags: result?.tags.map((item) => ({
            label: item?.tags,
            value: item?._id,
          })),
          weatherTag: {
            label: result?.weatherTag?.weatherTagName,
            value: result?.weatherTag?._id,
          },
          wellgoContent: result?.wellgoContent,
          wellgoHeadline: result?.wellgoHeadline,
          wellgorithmsImage: result?.wellgoVersionId?.wellgoImage,
          wellgorithmsTitle: {
            label: result?.wellgoTitle?.name,
            value: result?.wellgoTitle?._id,
          },
          welgotags: result?.wellgoVersionId?.wellgorithmtags?.map((item) => ({
            label: item?.tags,
            value: item?._id,
          })),
          welgogrouptags: result?.wellgoVersionId?.wellgorithmgrouptags?.map(
            (item) => ({
              label: item?.groupName,
              value: item?._id,
            })
          ),
          headContent: result?.headContent,
          // heading: result?.headContent.map((item) => item?.heading),
          // content: result?.headContent.map((item) => item?.content),
        });
      })
      .catch((error) => console.log(error, "error update"));

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
    clearErrors,
    control,
    setValue,
    reset,
    resetField,
    getValues,
  } = useForm({
    /*resolver: yupResolver(schema),*/
    mode: "onBlur",
    values,
    reValidateMode: "onChange",
  });

  //---//
  const [userType, setUserType] = useState("");
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);

  const editor = useRef(null);
  let navigate = useNavigate();
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [ratings, setRatings] = useState("");
  const [content, setContent] = useState("");
  const [sendArticleImage, setSendArticleImage] = useState("");
  const [authorName, setauthorName] = useState("");
  const [authordescription, setAuthorDescription] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [options, setoptions] = useState([]);
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState();
  const [
    selectedOptionProblemTagLabelTagIds,
    setSelectedOptionProblemTagLabelTagIds,
  ] = useState();
  const [groupdata, setGroupdata] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState();
  const [science, setScience] = useState("");
  const [globalImage, setGlobalImage] = useState("");
  const [sendglobalImage, setSendGlobalImage] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [aiMeter, setaiMeter] = useState("");
  const [wellgoTitle, setWellgoTitle] = useState("");
  const [wellgoHeadline, setWellgoHeadline] = useState("");
  const [wellgoContent, setWellgoContent] = useState("");
  const [wellgorithmsImage, setWellgorithmsImage] = useState("");
  const [sendwellgorithmsImage, setSendWellgorithmsImage] = useState("");
  const [authorImage, setAuthorImage] = useState("");
  const [sendAuthorImage, setSendAuthorImage] = useState("");
  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [imagesDataScience, setImagesDataScience] = useState([]);
  //----------EDIT ARTICLE API----------//

  //----get Group-tags API--//

  useEffect(() => {
    getnewtag();
    getnewgroup();
    getWellgorithms();
    getImageCategory();
    values();
  }, []);
  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let groupData = [];
        response.data.result.forEach((item, index) => {
          if (item.status == true) {
            groupData.push({ label: item.groupName, value: item._id });
          }
        });
        setGroupdata(groupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      multigroupArray.push({ id: option.value });
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
  };

  //----get tags API--//
  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let tagdata = [];
        response.data?.tagData?.forEach((item) => {
          if (item.status == true) {
            tagdata.push({ value: item?._id, label: item.tags });
          }
        });
        setoptions(tagdata);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangetag = (event) => {
    let array = [];
    let arrayLabel = [];
    let arrayLabelids = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value });
    });
    // setselectedOptionone(array);
    // setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };
  const handlechangeProblemtag = (event) => {
    let array = [];
    let arrayLabelids = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value });
    });

    // setselectedOptionone(array);
    // setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionProblemTagLabelTagIds(arrayLabelids);
  };

  //---------get Weather tag Api----//
  useEffect(() => {
    getWeather();
    getgrouptags();
  }, []);
  const [optionsweather, setOptionsWeather] = useState([]);
  const [weatherData, setWeatherData] = useState("");
  function getWeather() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getweathertag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const weatherTagsData = response?.data?.result?.map((item) => ({
          label: item?.weatherTagName,
          value: item?._id,
        }));
        setOptionsWeather(weatherTagsData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //--------Function to handle and set Author Image--------//
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
        },
      });
    }
  };
  //--------Function to handle and set Article Banner Image--------//
  const handleArticleImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setArticleImage(display);
          setSendArticleImage(compressedResult);
        },
      });
    }
  };
  //--------Function to render images--------//
  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  //--------- Get Category Api -----//
  const [loaderStatus, setloaderStatus] = useState("");
  const [categoryname, setCategoryname] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    getCategory();
  }, []);
  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const categories = response.data.CategoryData.filter(
          (category) => category.status === true
        ).map((category) => ({
          label: category?.category,
          value: category?._id,
        }));
        setCategoryData(categories);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // --get Sub-category API--//
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");
  function getTopics(id) {
    resetField("subcategory", {
      keepDrity: false,
      keepError: false,
      keepTouch: false,
      defaultValue: null,
    });
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettopic?categoryId=${id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.result.length == 0) {
          setloaderStatus("No Subcategory Available");
        } else {
          const subcategories = response.data.result
            .filter((subcategory) => subcategory?.status === true)
            .map((subcategory) => ({
              value: subcategory?._id,
              label: subcategory?.topicName,
            }));

          setSubCategory(subcategories);
          setloaderStatus("");
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //--------- //---------//

  //-------- get Inners Api----//
  useEffect(() => {
    getinner();
  }, []);
  const [inners, setInners] = useState([]);
  const [innerWords, setInnerWords] = useState("");
  const [innerloaderStatus, setInnerloaderStatus] = useState("");
  function getinner() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const innerWordsData = response?.data?.result?.map((item) => ({
          label: item?.inner,
          value: item?._id,
        }));

        setInners(innerWordsData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-------------//----------------//

  //--------Function to handle and set Image Only--------//
  const handleGlobalImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setGlobalImage(display);
          setSendGlobalImage(compressedResult);
        },
      });
    }
  };

  //--------Function to handle and set Wellgorithms Image--------//
  const handleWellgorithmsImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setWellgorithmsImage(display);
          setSendWellgorithmsImage(compressedResult);
        },
      });
    }
  };
  //-------------//----------------//
  //-----Get-Author's-------//
  useEffect(() => {
    getauthor();
  }, []);
  const [rowData, setRowData] = useState([]);

  function getauthor() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let data = response.data.result.map((item) => ({
          value: item?._id,
          label: item?.authorName,
          self: item,
        }));
        setAuthorListing(data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //-------------//----------------//

  //-------------/Get Wellgorithms Api /----------------//

  const [wellgorithmsData, setWellgorithmsData] = useState([]);
  function getWellgorithms() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getwellgorithms`,

      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const wellgorithmsData = response?.data?.result?.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
        setWellgorithmsData(wellgorithmsData);
      })
      .catch(function (error) {});
  }

  //-----------Get Group-tag api----------//
  const [group, setGroup] = useState("");
  const [optionsgroup, setoptionsgroup] = useState([]);

  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.result.forEach((grup, index) => {
          if (grup.status == true) {
            optionsgroup.push({ label: grup.groupName, value: grup._id });
          }
        });
        setoptionsgroup(optionsgroup);
        setGroup(response.data.result);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-------------/Add more Button/----------------//
  const [inputFields, setInputFields] = useState([]); // Initial input fields
  const [isAddButtonError, setIsAddButtonError] = useState(false);

  const handleAddInput = () => {
    if (
      inputFields.every((item) => item.content.length && item.heading.length)
    ) {
      setInputFields([
        ...inputFields,
        {
          id: inputFields[inputFields.length - 1].id + 1,
          heading: "",
          content: "",
        },
      ]);
      setIsAddButtonError(false);
    } else {
      setIsAddButtonError(true);
    }
  };
  const handleRemoveInput = (id) => {
    setInputFields(inputFields.filter((field) => field.id !== id));
    setIsAddButtonError(false);
  };

  const [authorDetails, setAuthorDetails] = useState({});
  const [authorListing, setAuthorListing] = useState([]);
  const [isLoading, setIsLoading] = useState({ draft: false, publish: false });

  function editArticles(
    {
      aiMeter,
      articleImage,
      articleSubTitle,
      articleTitle,
      articleDescription,
      groupTags,
      oppositeTags,
      science,
      scienceImage,
      sciencename,
      subcategory,
      tags,
      innerId,
      categoryId,
      weatherTag,
      wellgoContent,
      wellgoHeadline,
      wellgorithmsImage,
      wellgorithmsTitle,
      welgogrouptags,
      welgotags,
      articleType,
      headContent,
      authorDetail,
      metaDescription,
      wellgorithmsPoints,
      articleRank,
    },
    event
  ) {
    setIsLoading({
      draft: event?.type !== "submit" ? true : false,
      publish: event?.type === "submit" ? true : false,
    });
    setAddButtonStatus(true);
    let formData = new FormData();
    console.log("welgotags", welgotags);
    if (authorDetails?.uniqueId && authorDetails?.uniqueId[0] === "G") {
      formData.append("userName", authorDetails?.uniqueId);
    } else {
      if (authorDetail && authorDetail?.self?.authorName)
        formData.append("authorName", authorDetail?.self?.uniqueId);
    }
    if (articleImage) {
      if (typeof articleImage === "string") {
        formData.append("articleImage", articleImage ?? "");
      } else {
        formData.append("articleImage", articleImage[0] ?? "");
      }
    }

    if (articleTitle) formData.append("articleTitle", articleTitle);
    if (articleSubTitle) formData.append("articleSubTitle", articleSubTitle);
    if (articleDescription)
      formData.append("articleDescription", articleDescription);
    if (science) formData.append("science", science);
    if (sciencename) formData.append("sciencename", sciencename);
    if (scienceImage) {
      if (typeof scienceImage === "string") {
        formData.append("scienceImage", scienceImage ?? "");
      } else {
        formData.append("scienceImage", scienceImage[0] ?? "");
      }
    }
    if (typeof wellgorithmsImage === "string") {
      formData.append("wellgoImage", wellgorithmsImage ?? "");
    } else {
      formData.append("wellgoImage", wellgorithmsImage ?? "");
    }
    if (wellgoHeadline) formData.append("wellgoHeadline", wellgoHeadline);
    if (wellgoContent) formData.append("wellgoContent", wellgoContent);
    if (wellgorithmsPoints)
      formData.append("wellgorithmsPoints", wellgorithmsPoints);
    if (aiMeter) formData.append("aiMeter", aiMeter);
    if (metaDescription) formData.append("metaDescription", metaDescription);
    if (articleRank) formData.append("articleRank", articleRank);
    formData.append("articleId", params?.id);
    if (categoryId?.value) formData.append("categoryId", categoryId.value);
    if (subcategory?.value) formData.append("topicId", subcategory?.value);
    if (weatherTag?.value) formData.append("weatherTag", weatherTag?.value);
    if (innerId?.value) formData.append("innerId", innerId?.value);
    if (wellgorithmsTitle?.value)
      formData.append("wellgoTitle", wellgorithmsTitle?.value);
    if (welgotags.length)
      formData.append(
        "wellgorithmtags",
        welgotags.map((item) => item.value).join(",")
      );
    if (welgogrouptags.length)
      formData.append(
        "wellgorithmgrouptags",
        welgogrouptags.map((item) => item.value).join(",")
      );

    if (tags.length)
      formData.append("tag", tags.map((item) => item.value).join(","));

    if (oppositeTags.length)
      formData.append(
        "oppositeTags",
        oppositeTags.map((item) => item.value).join(",")
      );
    if (groupTags.length)
      formData.append(
        "groupTags",
        groupTags.map((item) => item.value).join(",")
      );
    console.log(headContent, "headContent");
    if (headContent)
      formData.append("headContent", JSON.stringify(headContent));
    if (authorDetails?.uniqueId[0] === "G") {
      formData.append(
        "adminVerify",
        event?.type === "submit" ? "Publish" : event
      );
    }

    if (authorDetails?.uniqueId[0] === "A") {
      formData.append(
        "articleType",
        event?.type === "submit" ? "Publish" : event
      );
    }

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/editarticles/${params?.id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        setAddButtonStatus(false);
        navigate("/Articles");
        toast.success("Artical Update Succsfuly");
      })
      .catch(function (error) {
        setAddButtonStatus(false);
        toast.error("File size should be 500KB or less");
        // console.log(error);
      })
      .finally(() => {
        setIsLoading({
          draft: false,
          publish: false,
        });
      });
  }

  const submitForm = () => {
    editArticles(
      {
        aiMeter: getValues("aiMeter"),
        articleImage: getValues("articleImage"),
        articleSubTitle: getValues("articleSubTitle"),
        articleTitle: getValues("articleTitle"),
        articleDescription: getValues("articleDescription"),
        groupTags: getValues("groupTags"),
        oppositeTags: getValues("oppositeTags"),
        science: getValues("science"),
        scienceImage: getValues("scienceImage"),
        subcategory: getValues("subcategory"),
        tags: getValues("tags"),
        innerId: getValues("innerId"),
        categoryId: getValues("categoryId"),
        weatherTag: getValues("weatherTag"),
        wellgoContent: getValues("wellgoContent"),
        wellgoHeadline: getValues("wellgoHeadline"),
        wellgorithmsImage: getValues("wellgorithmsImage"),
        wellgorithmsTitle: getValues("wellgorithmsTitle"),
        articleType: getValues("articleType"),
        authorDetail: getValues("authorDetail"),
        metaDescription: getValues("metaDescription"),
        articleRank: getValues("articleRank"),
        welgogrouptags: getValues("welgogrouptags"),
        welgotags: getValues("welgotags"),
      },
      "Draft"
    ); // Call the handleSubmit function
  };

  const validateFileSizearticleImage = (file) => {
    if (file && file[0]) {
      const fileSize = file[0].size / 1024;
      return fileSize <= 500;
    }
    return false;
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormProvider)
      name: "headContent", // unique name for your Field Array
    }
  );

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Journals</h4>

              <div className="branchData">
                <form onSubmit={handleSubmit(editArticles)}>
                  <div className="row">
                    {/* //----Author -section---// */}
                    {userType == "AUTHOR" || userType == "" ? (
                      <div className="col-md-12">
                        <div className="row bgBox mb-3">
                          <div className="col-md-2">
                            <label className="form-label">Author Image</label>
                            <ul className="imagesUpload">
                              <li style={{ width: "100%", height: "148px" }}>
                                {renderImages(
                                  authorDetails?.authorImage ??
                                    "../../images/defalutimg.svg"
                                )}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-10">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">Author Name</label>{" "}
                              {/*authorName*/}
                              <Controller
                                name="authorDetail"
                                control={control}
                                rules={{
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : "This Filds is requred",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={authorListing}
                                    onChange={(event) => {
                                      field.onChange(event); // Update the field value
                                      setAuthorDetails(event.self);
                                      setValue(
                                        "authordescription",
                                        event.self.authordescription
                                      );
                                    }}
                                  />
                                )}
                              />
                              {Object.keys(errors).includes("authorDetail") && (
                                <p style={{ color: "red" }}>
                                  {errors.authorDetail.message}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Author Description
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                {...register("authordescription", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                })}
                                name="authordescription"
                              />
                              {errors?.authordescription?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {userType === "GARDENER" && (
                      <div className="col-md-12">
                        <div className="row bgBox mb-3">
                          <div className="col-md-2">
                            <label className="form-label">Gardener Image</label>
                            <ul className="imagesUpload">
                              <li style={{ width: "100%", height: "148px" }}>
                                {renderImages(
                                  authorDetails?.aboutcommunitystrengths?.about
                                    ?.userImage ?? "../../images/defalutimg.svg"
                                )}
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-10">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Gardener Name
                              </label>
                              <Controller
                                name="authorDetail"
                                control={control}
                                rules={{
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : "This Filds is requred",
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    isDisabled
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={authorListing}
                                    onChange={(event) => {
                                      field.onChange(event); // Update the field value
                                      setAuthorDetails(event.self);
                                    }}
                                  />
                                )}
                              />
                              {Object.keys(errors).includes("authorDetail") && (
                                <p style={{ color: "red" }}>
                                  {errors.authorDetail.message}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Gardener Description
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                {...register("authordescription", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                })}
                                name="authordescription"
                              />{" "}
                              {errors?.authordescription?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* //---------------//----------// */}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category</label>
                          <Controller
                            name="categoryId"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={categoryData}
                                onChange={(event) => {
                                  field.onChange(event);
                                  if (event.value) {
                                    getTopics(event.value);
                                  }
                                  setCategoryname(event.value ?? "");
                                  setSubCategory([]);
                                  setSubCategoryName("");
                                }}
                              />
                            )}
                          />
                          {Object.keys(errors).includes("category") && (
                            <p style={{ color: "red" }}>
                              {errors.category.message}
                            </p>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">SubCategory</label>
                          <Controller
                            name="subcategory"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                // defaultValue={options[0]}
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={subCategory}
                                isDisabled={
                                  subCategory?.length == 0 ? true : false
                                }
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  let { value } = event;
                                  setSubCategoryName(value);
                                }}
                              />
                            )}
                          />
                          {Object.keys(errors).includes("subcategory") && (
                            <p style={{ color: "red" }}>
                              {errors.subcategory.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/*//----Article -section---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        {/* ???????????? */}
                        <div className="col-md-12">
                          <div className="row bgBox mb-3">
                            <div className="col-md-2">
                              <label className="form-label">
                                Journals Image
                              </label>
                              {!watch("uploadFile") && (
                                <div className="col-md-12 mb-3 mt-3">
                                  <div className="position-relative">
                                    <ul className="imagesUpload">
                                      <li
                                        style={{
                                          width: "128px",
                                          height: "128px",
                                        }}
                                      >
                                        {watch("articleImage") && (
                                          <img
                                            alt="renderImages"
                                            style={{
                                              width: "100%",
                                              height: "148px",
                                            }}
                                            src={watch("articleImage")}
                                          />
                                        )}
                                      </li>
                                    </ul>
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              )}
                              <div className="position-relative">
                                <label className="form-label">
                                  Upload File
                                  <input
                                    {...register("uploadFile")}
                                    type="radio"
                                    value="file"
                                  />
                                </label>
                                <label className="form-label">
                                  from directory
                                  <input
                                    {...register("uploadFile")}
                                    type="radio"
                                    value="from directory"
                                  />
                                </label>
                                <label className="form-label">
                                  Image URL
                                  <input
                                    {...register("uploadFile")}
                                    type="radio"
                                    value="image url"
                                  />
                                </label>

                                {watch("uploadFile") === "file" && (
                                  <ul className="imagesUpload">
                                    <li
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                    >
                                      <input
                                        type="file"
                                        {...register("articleImage", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          handleArticleImage(e);
                                          unregister("articleImage");
                                        }}
                                      />
                                      {errors?.articleImage?.type ===
                                        "required" && (
                                        <p style={{ color: "red" }}>
                                          File is required
                                        </p>
                                      )}

                                      {articleImage.length === 0 ? (
                                        <img
                                          alt="renderImages"
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                          src="../../images/defalutimg.svg"
                                        />
                                      ) : (
                                        <RenderImage image={articleImage} />
                                      )}
                                    </li>
                                  </ul>
                                )}
                                {watch("uploadFile") === "from directory" && (
                                  <Fragment>
                                    <Controller
                                      name="directory"
                                      control={control}
                                      // rules={{
                                      //   required: "This field is required",
                                      // }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          isSearchable={false}
                                          className="react-dropdown"
                                          classNamePrefix="dropdown"
                                          options={directoryData}
                                          onChange={(event) => {
                                            field.onChange(event);
                                            setImagesData(event.images);
                                          }}
                                        />
                                      )}
                                    />
                                    {Object.keys(errors).includes(
                                      "directory"
                                    ) && (
                                      <p style={{ color: "red" }}>
                                        {errors.directory.message}
                                      </p>
                                    )}
                                    <div className="d-flex  img_fix">
                                      {imagesData &&
                                        imagesData.length > 0 &&
                                        imagesData.map((item) => (
                                          <Controller
                                            name="articleImage"
                                            control={control}
                                            render={() => (
                                              <div
                                                className="m-2"
                                                onClick={() => {
                                                  setValue(
                                                    "articleImage",
                                                    item.location
                                                  );
                                                }}
                                              >
                                                <img
                                                  height={100}
                                                  width={100}
                                                  alt="icon"
                                                  src={item?.location}
                                                />
                                              </div>
                                            )}
                                          />
                                        ))}
                                    </div>
                                  </Fragment>
                                )}
                                {watch("uploadFile") === "image url" && (
                                  <div>
                                    <input
                                      type="text"
                                      {...register("articleImage", {
                                        required:
                                          watch("uploadFile") === "image url"
                                            ? true
                                            : false,
                                      })}
                                      placeholder="Enter Image URL"
                                    />

                                    {errors?.articleImage?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-10">
                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Journals Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("articleTitle", {
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : true,
                                  })}
                                  name="articleTitle"
                                />
                                {errors?.articleTitle?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                              <div className="col-md-12">
                                <label className="form-label">
                                  Journals SubTitle
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("articleSubTitle", {
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : true,
                                  })}
                                  name="articleSubTitle"
                                />{" "}
                                {errors?.articleSubTitle?.type ===
                                  "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 mt-5 mb-3">
                              <label className="form-label">
                                Journals Description
                              </label>
                              <div className="App">
                                <Editor
                                  name="articleDescription"
                                  defaultValue={content}
                                  control={control}
                                  placeholder="Write a Description..."
                                  watch={watch}
                                />
                              </div>
                              {errors?.articleDescription?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Select Tags </label>
                          <Controller
                            name="tags"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event);
                                  handlechangetag(event);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("tags") && (
                            <p style={{ color: "red" }}>
                              {errors.tags.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Problem Tag </label>

                          <Controller
                            name="oppositeTags"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isMulti
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={options}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  handlechangeProblemtag(event);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("oppositeTags") && (
                            <p style={{ color: "red" }}>
                              {errors.oppositeTags.message}
                            </p>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label"> Weather Tags </label>
                          <Controller
                            name="weatherTag"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={optionsweather}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  setWeatherData(event.value);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("weatherTag") && (
                            <p style={{ color: "red" }}>
                              {errors.weatherTag.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/*//----------//----------//*/}

                    {/*//----inner -Words---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label white">XBT Name</label>
                          <Controller
                            name="innerId"
                            control={control}
                            rules={{
                              required:
                                watch("articleType") === "Draft"
                                  ? false
                                  : "This Filds is requred",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={inners}
                                onChange={(event) => {
                                  field.onChange(event); // Update the field value
                                  setInnerWords(event.value);
                                }}
                              />
                            )}
                          />

                          {Object.keys(errors).includes("innerId") && (
                            <p style={{ color: "red" }}>
                              {errors.innerId.message}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          {fields?.map((field, index) => (
                            <div key={`${field?.id}`}>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">
                                  Distinction
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`headContent.${index}.heading`, {
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : true,
                                  })}
                                />
                              </div>
                              <div className="col-md-12 mb-3">
                                <label className="form-label">Imagine</label>
                                <textarea
                                  className="form-control"
                                  {...register(`headContent.${index}.content`, {
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : true,
                                  })}
                                />
                              </div>
                              {index !== 0 && (
                                <div className="plusBtn">
                                  <button
                                    type="button"
                                    // onClick={() => handleRemoveInput(field.id)}
                                    onClick={() => remove(index)}
                                  >
                                    -
                                  </button>
                                </div>
                              )}
                            </div>
                          ))}
                          {isAddButtonError && (
                            <p style={{ color: "red" }}>
                              The Heading and Content fields are required
                            </p>
                          )}
                          <div className="minBtn">
                            <button
                              type="button"
                              onClick={() =>
                                append({
                                  id: fields.length + 1,
                                  content: "",
                                  heading: "",
                                })
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}

                    {/*//----science / image---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Hero Name</label>
                          <textarea
                            className="form-control"
                            {...register("sciencename", {
                              required:
                                watch("articleType") === "Draft" ? false : true,
                            })}
                            name="sciencename"
                          />{" "}
                          {errors?.sciencename?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Hero Content</label>
                          <textarea
                            className="form-control"
                            {...register("science", {
                              required:
                                watch("articleType") === "Draft" ? false : true,
                            })}
                            name="science"
                          />
                          {errors?.science?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}
                        </div>

                        {!watch("uploadFileScience") && (
                          <div className="col-md-12 mb-3 mt-3">
                            <div className="position-relative">
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "128px",
                                    height: "128px",
                                  }}
                                >
                                  {watch("scienceImage") && (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "148px",
                                      }}
                                      src={watch("scienceImage")}
                                    />
                                  )}
                                </li>
                              </ul>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-2">
                          <label className="form-label">Hero Image</label>
                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFileScience")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFileScience")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFileScience")}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFileScience") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("scienceImage")}
                                    onChange={(e) => {
                                      handleGlobalImage(e);
                                      unregister("scienceImage");
                                    }}
                                  />

                                  {globalImage.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={globalImage} />
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFileScience") ===
                              "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directoryScience"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("articleType") === "Draft"
                                        ? false
                                        : "This Filds is requred",
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesDataScience(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes(
                                  "directoryScience"
                                ) && (
                                  <p style={{ color: "red" }}>
                                    {errors.directoryScience.message}
                                  </p>
                                )}{" "}
                                <div class="row">
                                  {imagesDataScience &&
                                    imagesDataScience.length > 0 &&
                                    imagesDataScience.map((item) => (
                                      <Controller
                                        name="scienceImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className="col-md-3"
                                            onClick={() => {
                                              setValue(
                                                "scienceImage",
                                                item.location
                                              );
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFileScience") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("scienceImage", {
                                    required:
                                      watch("uploadFileScience") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.scienceImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}

                    {/*//----Wellgorithms---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-2">
                          <label className="form-label">Wellgorithms</label>
                          <ul className="imagesUpload">
                            <li style={{ width: "100%", height: "148px" }}>
                              <input
                                type="file"
                                {...register("wellgorithmsImage", {
                                  required: getValues("wellgorithmsImage")
                                    ? false
                                    : "This Field is Required",
                                })}
                                onChange={(e) => {
                                  handleWellgorithmsImage(e);
                                  unregister("wellgorithmsImage");
                                }}
                                name="wellgorithmsImage"
                              />

                              {!wellgorithmsImage ? (
                                <img src={defaultImage} alt="profile" />
                              ) : (
                                renderImages(
                                  wellgorithmsImage?.wellgoVersionId
                                    ?.wellgoImage
                                )
                              )}
                              {errors?.wellgorithmsImage?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Title</label>
                            <Controller
                              name="wellgorithmsTitle"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={wellgorithmsData}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    setWellgoTitle(event.value);
                                    setValue(
                                      "tags",
                                      event?.self?.tag.map((item) => ({
                                        value: item?._id,
                                        label: item?.tags,
                                      }))
                                    );
                                    setValue(
                                      "wellgoContent",
                                      event?.self?.wellgoVersionId
                                        ?.wellgoContent
                                    );
                                    setValue(
                                      "wellgoHeadline",
                                      event?.self?.wellgoVersionId
                                        ?.wellgoHeadline
                                    );
                                    setValue(
                                      "grouptags",
                                      event?.self?.tagGroup?.map((item) => ({
                                        value: item?._id,
                                        label: item?.groupName,
                                      }))
                                    );
                                  }}
                                />
                              )}
                            />

                            {Object.keys(errors).includes(
                              "wellgorithmsTitle"
                            ) && (
                              <p style={{ color: "red" }}>
                                {errors.wellgorithmsTitle.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Headline</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("wellgoHeadline", {
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : true,
                              })}
                            />
                            {errors?.wellgoHeadline?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Points
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              {...register("wellgorithmsPoints")}
                              name="wellgorithmsPoints"
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Content</label>
                            <textarea
                              className="form-control"
                              {...register("wellgoContent", {
                                required: true,
                              })}
                            />
                            {errors?.wellgoContent?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Group Tags
                            </label>
                            <Controller
                              name="welgogrouptags"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  // defaultValue={filterTagsByTitle(wellgoTitle)}
                                  isMulti
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={optionsgroup}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    handlechangetag(event);
                                    getValues("grouptags");
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Wellgorithm Tags
                            </label>
                            <Controller
                              name="welgotags"
                              control={control}
                              rules={{
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : "This Filds is requred",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={options}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    handlechangetag(event);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}

                    {/*//----Meta description---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Meta description</label>
                          <Col xs={11}>
                            <textarea
                              type="textarea"
                              className="form-control"
                              {...register("metaDescription", {
                                required:
                                  watch("articleType") === "Draft"
                                    ? false
                                    : true,
                              })}
                              name="metaDescription"
                            />{" "}
                            {errors?.metaDescription?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This Field is Required
                              </p>
                            )}
                          </Col>
                          <span></span>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}

                    {/*//----Ai Meter [0/100]---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Ai Meter [0/100] :
                          </label>
                          <div className="d-flex align-items-center">
                            <Col xs={11}>
                              <input
                                type="number"
                                className="form-control"
                                {...register("aiMeter", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                  min:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : 0,
                                  max:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : 100,
                                })}
                                name="aiMeter"
                              />
                              {errors?.aiMeter?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Enter number between 0 to 100
                                </p>
                              )}
                              {errors?.aiMeter?.type === "min" && (
                                <p style={{ color: "red" }}>Not less than 0</p>
                              )}
                              {errors?.aiMeter?.type === "max" && (
                                <p style={{ color: "red" }}>
                                  Not more than 100
                                </p>
                              )}
                            </Col>
                            <Col
                              xs={1}
                              style={{ padding: "10px", fontSize: "20px" }}
                            >
                              %
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//----Ratings---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Ratings :</label>
                          <div className="d-flex">
                            <Col xs={11}>
                              <input
                                type="number"
                                className="form-control"
                                {...register("articleRank", {
                                  required:
                                    watch("articleType") === "Draft"
                                      ? false
                                      : true,
                                })}
                                name="articleRank"
                              />{" "}
                              {errors?.articleRank?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is Required
                                </p>
                              )}
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*//------//----------//*/}
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/Articles");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={isLoading.publish}
                        type="submit"
                        className="btn submitBtn me-3"
                        // onClick={() => {
                        //   editArticles("Publish");
                        // }}
                      >
                        {isLoading.publish ? "Loading..." : "Publish"}{" "}
                      </button>
                      <button
                        disabled={isLoading.draft}
                        type="button"
                        className="btn submitBtn me-3"
                        onClick={() => {
                          clearErrors();
                          register("articleType", { value: "Draft" });
                          submitForm();
                        }}
                      >
                        {isLoading.draft ? "Loading..." : "Draft"}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpdateArticles;
