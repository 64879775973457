import React from "react";

function Footer() {
  return (
    <footer className="footer text-center">
    All Rights Reserved. Martin
</footer>
  );
}

export default Footer;