import { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./categories.css";
import Updatecategories from "./Updatecategories";
import { MdModeEditOutline } from "react-icons/md";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { AiFillDelete } from "react-icons/ai";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import RenderImage from "../../components/Image";
import { Fragment } from "react";
import Select from "react-select";
import { CSVLink } from "react-csv";
const CategoryNameRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props.data.category}</span>
    </span>
  );
};

const SrNoRenderer = (props, ref) => {
  return <span className="profle_img_box">{props.rowIndex + 1}</span>;
};

function Categories() {
  const rowHeight = 55;
  const [category, setCategory] = useState("");
  const [active, setActive] = useState(-1);
  const [editCategoriesData, setEditCategoriesData] = useState("");
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [rowData, setRowData] = useState([]);
  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploaderrors },
    reset: uploadreset,
  } = useForm();
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [statusData, setStatusData] = useState(false);
  function close() {
    setEditIsOpen(false);
    setEditCategoriesData({});
    setRowData(rowData);
  }
  function somefun() {
    setIsBulkUploadOpen(false);
    reset();
    resetForm();
    setIsOpen(false);
    setInnericon("");
  }

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setEditCategoriesData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCateData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
          setErr(false);
        },
      });
    }
  };

  // --Add category API--//
  const [err, setErr] = useState(false);
  function addnew(postData) {
    if (sendinnericonImage.length === 0 && watch("uploadFile") === "") {
      setErr(true);
    } else {
      setIsOpen(false);
      setErr(false);
      let data = new FormData();
      data.append(
        "categoryImage",
        postData.categoryImage[0] === "h"
          ? postData.categoryImage
          : postData.categoryImage[0]
      );

      data.append("category", postData.category);
      data.append("content", postData.content);
      data.append("categoryRank", postData.categoryRank);
      data.append("isLegal", postData.Tredmark);
      data.append("isLegalStatus", postData.isLegalStatus);

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/createCategory`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          getCategory();
          reset();
          setInnericon("");
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  const resetForm = () => {
    setCategory("");
  };

  // --get category API--//
  useEffect(() => {
    getCategory();
    getImageCategory();
    resetForm();
  }, []);

  const [downloadData, setDownloadData] = useState([]);

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/categorybulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getCategory();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.CategoryData);
        const downloadData = response.data.CategoryData?.map(
          ({
            category,
            categoryImage,
            categoryRank,
            content,
            isLegalStatus,
            isLegal,
            status,
          }) => {
            return {
              category: category ? category : "",
              categoryRank: categoryRank ? categoryRank : "",
              categoryImage: categoryImage ? categoryImage : "",
              content: content ? content : "",
              isLegalStatus: isLegalStatus ? isLegalStatus : "",
              CategoryStatus: status ? status : "",
              "Tm/R (1/2)": isLegal == "™" ? 1 : isLegal == "®" ? 2 : "",
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getCategory();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  // ---- Update categories status API ---//
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Category?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeAuthorStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  function changeAuthorStatus(categoryId) {
    let data = JSON.stringify({
      categoryId: categoryId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getCategory();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ---- Update categories Home-status API ---//
  const HomeStatusRenderer = (props) => {
    let status = props?.data?.homeStatus ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Home Category?";
    return (
      <Modal_Popup
        status={props?.data?.homeStatus}
        onClick={() => {
          changeHomeStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  function changeHomeStatus(categoryId) {
    let data = JSON.stringify({
      categoryId: categoryId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changehomestatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getCategory();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------delete subcategory Api -----//
  function deleteCategory(index) {
    let data = JSON.stringify({
      categoryId: index,
    });

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/deleteCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getCategory();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24; // Adjust this value based on your textarea's line height
    const previousRows = event.target.rows;
    event.target.rows = 1; // Reset the number of rows to 1 to measure the actual height
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows; // Set the rows to the calculated value
    setHeight(`${event.target.scrollHeight}px`); // Set the height dynamically
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight; // Scroll to bottom if the number of rows increased
    }
  };

  const headers = [
    { label: "Category", key: "category" },
    { label: "Category Rank", key: "categoryRank" },
    { label: "Category Image", key: "categoryImage" },
    { label: "Content", key: "content" },
    { label: "Legal Status", key: "isLegalStatus" },
    { label: "Tm/R (1/2)", key: "Tm/R (1/2)" },
    { label: "Category Status", key: "CategoryStatus" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);
  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Category
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Category
                  </button>

                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="my-data.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>

                  {/* <JsonDownload
                    title="Export"
                    fileName="Category List"
                    downloadfile={downloadData}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  /> */}
                </div>
              </h4>
              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {uploaderrors?.uploadImages?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* Add Categories */}
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      //className="modal fade"
                      // id="createGroup"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add Category
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => somefun()}
                            ></button>
                          </div>
                          <form
                            onSubmit={handleSubmit(addnew)}
                            autoComplete="new-password"
                          >
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    {" "}
                                    Category
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...register("category", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.category?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Category Rank
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="number"
                                      min={0}
                                      className="form-control"
                                      {...register("categoryRank", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.categoryRank?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Content</label>
                                  <div className="position-relative">
                                    <textarea
                                      className="form-control"
                                      style={{
                                        height: "auto",
                                        minHeight: "20px",
                                        overflow: "hidden",
                                        resize: "none",
                                      }}
                                      onInput={handleInput}
                                      {...register("content", {
                                        required: true,
                                      })}
                                    />

                                    {errors?.content?.type === "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Category Image
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Upload File
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="file"
                                      />
                                    </label>
                                    <label className="form-label">
                                      from directory
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="from directory"
                                      />
                                    </label>
                                    <label className="form-label">
                                      Image URL
                                      <input
                                        {...register("uploadFile", {
                                          required: true,
                                        })}
                                        type="radio"
                                        value="image url"
                                      />
                                    </label>
                                    {errors?.uploadFile?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    {watch("uploadFile") === "file" && (
                                      <ul className="imagesUpload">
                                        <li
                                          style={{
                                            width: "100%",
                                            height: "128px",
                                          }}
                                        >
                                          <input
                                            type="file"
                                            {...register("categoryImage", {
                                              required:
                                                watch("uploadFile") === "file"
                                                  ? true
                                                  : false,
                                            })}
                                            onChange={(e) => {
                                              handleAuthorImage(e);
                                              unregister("categoryImage");
                                            }}
                                          />

                                          {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )}

                                          {errors?.categoryImage?.type ===
                                            "required" && (
                                            <p style={{ color: "red" }}>
                                              This field is required
                                            </p>
                                          )}

                                          {errors?.categoryImage?.type ===
                                            "validate" && (
                                            <p style={{ color: "red" }}>
                                              File size should be 500KB or less
                                            </p>
                                          )}
                                        </li>
                                      </ul>
                                    )}
                                    {watch("uploadFile") ===
                                      "from directory" && (
                                      <Fragment>
                                        <Controller
                                          name="directory"
                                          control={control}
                                          rules={{
                                            required: "This field is required",
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              isSearchable={false}
                                              className="react-dropdown"
                                              classNamePrefix="dropdown"
                                              options={directoryData}
                                              onChange={(event) => {
                                                field.onChange(event);
                                                setImagesData(event.images);
                                              }}
                                            />
                                          )}
                                        />
                                        {Object.keys(errors).includes(
                                          "directory"
                                        ) && (
                                          <p style={{ color: "red" }}>
                                            {errors.directory.message}
                                          </p>
                                        )}
                                        <div class="imageList">
                                          {imagesData &&
                                            imagesData.length > 0 &&
                                            imagesData.map((item, index) => (
                                              <Controller
                                                name="categoryImage"
                                                control={control}
                                                render={() => (
                                                  <div
                                                    className={`uploadedImg ${
                                                      active === index &&
                                                      "active"
                                                    }`}
                                                    onClick={() => {
                                                      setValue(
                                                        "categoryImage",
                                                        item.location
                                                      );
                                                      setActive(index);
                                                    }}
                                                  >
                                                    <img
                                                      height={50}
                                                      width={50}
                                                      alt="icon"
                                                      src={item?.location}
                                                    />
                                                  </div>
                                                )}
                                              />
                                            ))}
                                        </div>
                                      </Fragment>
                                    )}
                                    {watch("uploadFile") === "image url" && (
                                      <div>
                                        <input
                                          type="text"
                                          {...register("categoryImage", {
                                            required:
                                              watch("uploadFile") ===
                                              "image url"
                                                ? true
                                                : false,
                                          })}
                                          placeholder="Enter Image URL"
                                        />

                                        {errors?.categoryImage?.type ===
                                          "required" && (
                                          <p style={{ color: "red" }}>
                                            This field is required
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* ////////////////////// */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Category is Legal
                                  </label>

                                  <div className="position-relative">
                                    <label className="form-label">
                                      Is Legal
                                      <input
                                        {...register("isLegalStatus", {
                                          defaultValue: "false",
                                        })}
                                        type="checkbox"
                                        name="isLegalStatus"
                                        value="true"
                                        onChange={(e) =>
                                          setStatusData(e.target.checked)
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>

                                {/* ///////////////// */}
                                {/* {statusData ? ( */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Select Category Trademark
                                  </label>
                                  <div className="position-relative">
                                    <label className="form-label">
                                      TM
                                      <input
                                        {...register("Tredmark")}
                                        type="radio"
                                        value="™"
                                      />
                                    </label>
                                    <label className="form-label">
                                      R
                                      <input
                                        {...register("Tredmark")}
                                        type="radio"
                                        value="®"
                                      />
                                    </label>
                                  </div>
                                </div>
                                {/* // ) : null} */}
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>

              {/* delete */}

              <div
                className="modal fade DeletePopup"
                id="DeleteCateData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>Are you sure you want to delete this Category</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteCategory(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdatecateData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <Updatecategories
                        updatedData={editCategoriesData}
                        onEditDataFunction={editdataReloadFunc}
                        editIsOpen={editIsOpen}
                        close={close}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    categoryNameRenderer: CategoryNameRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    homestatusRenderer: HomeStatusRenderer,
                  }}
                >
                  <AgGridColumn
                    flex={2}
                    // field="SrNo"
                    field="categoryRank"
                    headerName="Category Rank"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={2}
                    headerName="Name"
                    field="category"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  {/* <AgGridColumn
                    flex={2}
                    headerName="Rank"
                    field="categoryRank"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn> */}
                  <AgGridColumn
                    cellRenderer="statusRenderer"
                    flex={2}
                    field="status"
                    sortable={true}
                    filter={true}
                    // comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  {/* <AgGridColumn
                    cellRenderer="homestatusRenderer"
                    flex={2}
                    field="homeStatus"
                    sortable={true}
                    filter={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn> */}
                  <AgGridColumn
                    flex={2}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Categories;
