import { Route, Routes } from "react-router-dom";
import "./style.css";
import Login from "./shared/Login/login";
import Articles from "./shared/Articles/Articles";
import AddArticles from "./shared/Articles/AddArticles";
import UpdateArticles from "./shared/Articles/UpdateArticles";
import Gardener from "./shared/Gardener/Gardener";
import Categories from "./shared/categories/categories";
import Tags from "./shared/Tags/tags";
import Author from "./shared/Author/Author";
import AddAuthor from "./shared/Author/AddAuthor";
import Topics from "./shared/Topics/Topics";
import Grouptags from "./shared/Grouptags/Grouptags";
import Inners from "./shared/Inners/Inners";
import Weathers from "./shared/Weathers/Weathers";
import Wellgorithms from "./shared/Wellgorithms/Wellgorithms";
import ArtSeries from "./shared/ArtSeries/ArtSeries";
import AddArtSeries from "./shared/ArtSeries/AddArtSeries";
import UpdateArtSeries from "./shared/ArtSeries/UpdateArtSeries";
import RequestArticles from "./shared/RequestArticles/RequestArticles";
import UpdateRequestArticles from "./shared/RequestArticles/UpdateRequestArticles";
import ImageDirectory from "./shared/ImageDirectory/ImageDirectory";
import ImageDirectoryById from "./shared/ImageDirectory/ImageDirectoryById";
import SearchImageInDirectory from "./shared/ImageDirectory/SearchImageInDirectory";
import EasyArticles from "./shared/EasyArticles";
import About from "./shared/EasyArticles/About";
import SignupInvitation from "./shared/EasyArticles/SignupInvitation";
import Policies from "./shared/EasyArticles/Policies";
import Story from "./shared/EasyArticles/Story";
import Academy from "./shared/Academy";
import LandingCategory from "./shared/LandingCategory/LandingCategory";
import FooterCategory from "./shared/FooterCategory/FooterCategory";
import AddVideo from "./shared/Video/AddVideo";
import Ads from "./shared/Ads/Ads";
import UpdateAds from "./shared/Ads/UpdateAds";
import AddAds from "./shared/Ads/AddAds";
import InnerTv from "./shared/InnerTv/InnerTv";
import InnerTvCategories from "./shared/InnerTv/InnerTvCategories";
import Contect from "./shared/contect/Contect";
import UpdateAuthor from "./shared/Author/UpdateAuthor";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/Users" element={<Gardener />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/Articles" element={<Articles />} />
        <Route exact path="/AddArticles" element={<AddArticles />} />
        <Route exact path="/UpdateArticles/:id" element={<UpdateArticles />} />
        <Route exact path="/RequestArticles" element={<RequestArticles />} />
        <Route
          exact
          path="/UpdateRequestArticles/:id"
          element={<UpdateRequestArticles />}
        />
        <Route exact path="/ArtSeries" element={<ArtSeries />} />
        <Route exact path="/AddArtSeries" element={<AddArtSeries />} />
        <Route
          exact
          path="/UpdateArtSeries/:id"
          element={<UpdateArtSeries />}
        />
        <Route exact path="/Author" element={<Author />} />
        <Route exact path="/AddAuthor" element={<AddAuthor />} />
        <Route exact path="/updateAuthor/:id" element={<UpdateAuthor />} />
        <Route exact path="/Category" element={<Categories />} />
        <Route exact path="/Topics" element={<Topics />} />
        <Route exact path="/Tag" element={<Tags />} />
        <Route exact path="/Tag-Group" element={<Grouptags />} />
        <Route exact path="/Inners" element={<Inners />} />
        <Route exact path="/Weathers" element={<Weathers />} />
        <Route exact path="/innertv" element={<InnerTv />} />
        <Route
          exact
          path="/innertvcategories"
          element={<InnerTvCategories />}
        />
        <Route exact path="/Wellgorithms" element={<Wellgorithms />} />
        <Route exact path="/contect" element={<Contect />} />
        <Route exact path="/images" element={<ImageDirectory />} />
        <Route
          exact
          path="/images/search"
          element={<SearchImageInDirectory />}
        />
        <Route path="footer-categories" element={<FooterCategory />} />
        <Route path="landing-categories" element={<LandingCategory />} />
        <Route exact path="/images/:id" element={<ImageDirectoryById />} />
        <Route exact path="/policies" element={<Academy />} />
        <Route path="/add-video" element={<AddVideo />} />
        <Route path="/ads" element={<Ads />} />
        <Route path="/add-ads" element={<AddAds />} />
        <Route path="/updateads/:id" element={<UpdateAds />} />

        <Route path="/easy-articles/" element={<EasyArticles />}>
          <Route index element={<About />} />
          <Route path="about" element={<About />} />
          <Route path="signup-invitation" element={<SignupInvitation />} />
          <Route path="story" element={<Story />} />
        </Route>
        {/* <Route path="*">
            <h1>Waiting ...</h1>
          </Route> */}
      </Routes>
    </div>
  );
}

export default App;
