import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./Weathers";
import { useForm, Controller } from "react-hook-form";
import Compressor from "compressorjs";
import Modal from "react-bootstrap/Modal";
import RenderImage from "../../components/Image";
import Select from "react-select";

const UpdateWeathers = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [active, setActive] = useState(-1);

  useEffect(() => {
    setEditData(props.updatedData);
    getImageCategory();
    if (props?.updatedData) {
      setValue("addImage", props?.updatedData?.icon);
    }
  }, [props]);

  const {
    register: uploadRegister,
    handleSubmit: handlesubmitUpload,
    reset,
    watch,
    setValue,
    control,
    unregister,
    formState: { errors: uploaderrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      weatherTagName: props?.updatedData?.weatherTagName,
    },
  });

  //--------Function to render images--------//
  const renderImages = (image) => {
    return (
      <img
        alt="renderImages"
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  //--------Function to handle and set Weather Image--------//
  const [weatherImage, setWeatherImage] = useState("");
  const [sendWeatherImage, setSendWeatherImage] = useState("");

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));

        console.log("directories>>", directories);
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }

  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  function updateWeather(postdata) {
    props.close();
    let data = new FormData();
    data.append("weatherTagName", postdata.weatherTagName);

    data.append(
      "icon",
      postdata.addImage[0] === "h" ? postdata.addImage : postdata.addImage[0]
    );
    data.append("weatherTagId", editGroupData?._id);

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updateweathertag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {});
  }
  const validateFileSize = (file) => {
    if (file && file[0]) {
      const fileSize = file[0].size / 1024;
      return fileSize <= 500;
    }
    return false;
  };

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit weather
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handlesubmitUpload(updateWeather)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label className="form-label">Weather Tag</label>
                            <input
                              className="form-control"
                              {...uploadRegister("weatherTagName", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {uploaderrors?.weatherTagName?.type ===
                              "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {uploaderrors?.weatherTagName?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                          </div>
                        </div>

                        {/* >>>>>>>>>>> */}
                        {!watch("uploadFile") && (
                          <div className="col-md-12 mb-3 mt-3">
                            <label className="form-label">Weathers Image</label>
                            <div className="position-relative">
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "128px",
                                    height: "128px",
                                  }}
                                >
                                  {watch("addImage") && (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "148px",
                                      }}
                                      src={watch("addImage") ?? innericon}
                                    />
                                  )}
                                </li>
                              </ul>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Weathers Image</label>

                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...uploadRegister("uploadFile", {
                                  required: true,
                                })}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...uploadRegister("uploadFile", {
                                  required: true,
                                })}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...uploadRegister("uploadFile", {
                                  required: true,
                                })}
                                type="radio"
                                value="image url"
                              />
                            </label>
                            {uploaderrors?.uploadFile?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...uploadRegister("addImage", {
                                      required:
                                        watch("uploadFile") === "file"
                                          ? true
                                          : false,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("addImage");
                                    }}
                                  />

                                  {innericon.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={innericon} />
                                  )}

                                  {uploaderrors?.addImage?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}

                                  {uploaderrors?.addImage?.type ===
                                    "validate" && (
                                    <p style={{ color: "red" }}>
                                      File size should be 500KB or less
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("uploadFile") === "from directory"
                                        ? true
                                        : false,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(uploaderrors).includes(
                                  "directory"
                                ) && (
                                  <p style={{ color: "red" }}>
                                    {uploaderrors.directory.message}
                                  </p>
                                )}{" "}
                                <div class="imageList">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item, index) => (
                                      <Controller
                                        name="addImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className={`uploadedImg ${
                                              active === index && "active"
                                            }`}
                                            onClick={() => {
                                              setValue(
                                                "addImage",
                                                item.location
                                              );
                                              setActive(index);
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...uploadRegister("addImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                {uploaderrors?.addImage?.type ===
                                  "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* >>>>>>>>>>>>>>>>>> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateWeathers;
