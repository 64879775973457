import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./categories.css";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import Modal from "react-bootstrap/Modal";
import RenderImage from "../../components/Image";
import Select from "react-select";

const Updatecategories = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    setValue,
    unregister,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      category: props?.updatedData?.category,
      categoryRank: props?.updatedData?.categoryRank,
      content: props?.updatedData?.content,
      Tredmark: props?.updatedData?.isLegal,
      isLegalStatus: props?.updatedData?.isLegalStatus,
    },
  });

  const [editGroupData, setEditData] = useState(props?.updateData);
  const [weatherImage, setWeatherImage] = useState("");
  const [sendWeatherImage, setSendWeatherImage] = useState("");
  const [statusData, setStatusData] = useState(
    props?.updatedData?.isLegalStatus
  );
  const [active, setActive] = useState(-1);

  useEffect(() => {
    setEditData(props.updatedData);
    console.log(props.updatedData, "props");
    if (props?.updatedData) {
      setValue("categoryImage", props?.updatedData?.categoryImage);
    }
  }, [props]);
  useEffect(() => {
    getImageCategory();
  }, []);

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  function updateData(postdata) {
    props.close();
    let data = new FormData();
    data.append("categoryId", props.updatedData?._id);

    data.append(
      "categoryImage",
      postdata.categoryImage[0] === "h"
        ? postdata.categoryImage
        : postdata.categoryImage[0]
    );
    const TDmarkData =
      postdata.isLegalStatus === false ? null : postdata.Tredmark;
    data.append("category", postdata.category);
    data.append("categoryRank", postdata.categoryRank);
    data.append("content", postdata.content);
    data.append("isLegal", TDmarkData);
    data.append("isLegalStatus", postdata.isLegalStatus);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/updateCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = 1;
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows;
    setHeight(`${event.target.scrollHeight}px`);
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight;
    }
  };

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));

        console.log("directories>>", directories);
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }

  console.log(watch(), "ooooooooooooooooo");

  return (
    <React.Fragment>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Category
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handleSubmit(updateData)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("category", {
                                required: true,
                              })}
                            />
                            {errors?.category?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category Rank</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("categoryRank", {
                                required: true,
                              })}
                            />
                            {errors?.categoryRank?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Content</label>
                          <div className="position-relative">
                            <textarea
                              className="form-control"
                              style={{
                                height: height,
                                minHeight: "20px",
                                overflow: "hidden",
                                resize: "none",
                              }}
                              onMouseMove={handleInput}
                              onInput={handleInput}
                              {...register("content", {
                                required: true,
                              })}
                            />

                            {errors?.content?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Select Category is Legal
                          </label>

                          <div className="position-relative">
                            <label className="form-label">
                              Is Legal
                              <input
                                {...register("isLegalStatus", {
                                  defaultValue: "false",
                                })}
                                type="checkbox"
                                name="isLegalStatus"
                                value="true"
                                onChange={(e) =>
                                  setStatusData(e.target.checked)
                                }
                              />
                            </label>
                          </div>
                        </div>

                        {statusData ? (
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Select Category Treademark
                            </label>
                            <div className="position-relative">
                              <label className="form-label">
                                TM
                                <input
                                  {...register("Tredmark")}
                                  type="radio"
                                  value="™"
                                />
                              </label>
                              <label className="form-label">
                                R
                                <input
                                  {...register("Tredmark")}
                                  type="radio"
                                  value="®"
                                />
                              </label>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {!watch("uploadFile") && (
                          <div className="col-md-12 mb-3 mt-3">
                            <label className="form-label">Category Image</label>
                            <div className="position-relative">
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "128px",
                                    height: "128px",
                                  }}
                                >
                                  {watch("categoryImage") && (
                                    <img
                                      alt="renderImages"
                                      style={{ width: "100%", height: "148px" }}
                                      src={watch("categoryImage") ?? innericon}
                                    />
                                  )}
                                </li>
                              </ul>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category Image</label>

                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("categoryImage", {
                                      required:
                                        watch("uploadFile") === "file"
                                          ? true
                                          : false,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("categoryImage");
                                    }}
                                  />

                                  {innericon.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={innericon} />
                                  )}

                                  {errors?.categoryImage?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}

                                  {errors?.categoryImage?.type ===
                                    "validate" && (
                                    <p style={{ color: "red" }}>
                                      File size should be 500KB or less
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("uploadFile") === "from directory"
                                        ? true
                                        : false,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}{" "}
                                <div class="imageList">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item, index) => (
                                      <Controller
                                        name="categoryImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className={`uploadedImg ${
                                              active === index && "active"
                                            }`}
                                            onClick={() => {
                                              setValue(
                                                "categoryImage",
                                                item.location
                                              );
                                              setActive(index);
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("categoryImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {/* {innericon.length === 0 ? (
                                            <img
                                              alt="renderImages"
                                              style={{
                                                width: "100%",
                                                height: "128px",
                                              }}
                                              src="../../images/defalutimg.svg"
                                            />
                                          ) : (
                                            <RenderImage image={innericon} />
                                          )} */}

                                {errors?.categoryImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Updatecategories;
