import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JsonDownload from "../../components/downloadjson";
import { exportToExcel } from "react-json-to-excel";
import { CSVLink } from "react-csv";

function ImageDirectory() {
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  let navigate = useNavigate();
  const [UpdateData, setUpdateData] = useState({});
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [tags, setTags] = useState("");
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function somefun() {
    setIsOpen(false);
    setEditIsOpen(false);

    cancelForm();
  }
  function cancelForm() {
    reset();
  }
  function fun() {
    setIsOpen(false);
  }
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div className="editIcon">
          <MdModeEditOutline
            className="ActionIcon"
            onClick={() => {
              setUpdateData(props.data);
              setEditIsOpen(true);
            }}
          />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function createNewDirectory({ imageCategory }) {
    setIsLoading(true);
    let data = JSON.stringify({
      imageCategory,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/imagecategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          reset();
          somefun();
          getImageCategory();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function editNewDirectory({ imageCategory }) {
    setIsLoading(true);
    let data = JSON.stringify({
      imageCategory,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updateimagecategory/${UpdateData?._id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          reset();
          somefun();
          getImageCategory();
          setUpdateData({});
        }
      })
      .catch(function (error) {
        // console.log(error);
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function changeTagStatus(tagId) {
    setIsLoading(true);
    let data = JSON.stringify({
      tagId: tagId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changetagstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getImageCategory();
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getImageCategory();
  }, []);
  const [downloadData, setDownloadData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getimagecategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        console.log(response.data.result, "RESULT");
        const downloadData = response.data.result?.map(
          ({ imageCategory, _id }) => {
            return {
              imageCategory: imageCategory ? imageCategory : "",
              // _id,
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  function deleteImageCategory(id) {
    setIsLoading(true);
    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/deleteimagecategory/${id}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          getImageCategory();
          setDeleteCategoriesId("");
          setDeleteIsOpen(false);
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  const headers = [{ label: "Image Category", key: "imageCategory" }];

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Directory
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => navigate(`/images/search`)}
                  >
                    Search Images
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Directory
                  </button>

                  {/* <JsonDownload
                    title="Export"
                    fileName="Image Directory List"
                    downloadfile={downloadData}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  /> */}
                </div>
              </h4>
              <div>
                {/* Add Directory Name */}
                <Modal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(false);
                    reset();
                  }}
                >
                  <form onSubmit={handleSubmit(createNewDirectory)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Add Directory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Folder Name</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              // value={tags}
                              className="form-control"
                              {...register("imageCategory", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {console.log(errors, "kdjlajsdjjld")}
                            {errors?.tags?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {errors?.tags?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* Edit Directory Name */}

              <div>
                <Modal show={editIsOpen} onHide={() => setEditIsOpen(false)}>
                  <form onSubmit={handleSubmit(editNewDirectory)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Edit Directory</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Folder Name</label>
                          <div className="position-relative">
                            <input
                              type="text"
                              // value={tags}
                              className="form-control"
                              {...register("imageCategory", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {console.log(errors, "kdjlajsdjjld")}
                            {errors?.tags?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {errors?.tags?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setEditIsOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Save Changes"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
              {/* Delete Directory */}
              <div>
                <Modal
                  show={deleteIsOpen}
                  onHide={() => {
                    setDeleteIsOpen(false);
                    setDeleteCategoriesId("");
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title> Delete Directory</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card>
                      <Card.Body>
                        Are You Sure You Want to Delete This Directory
                      </Card.Body>
                    </Card>{" "}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setDeleteIsOpen(false);
                        setDeleteCategoriesId("");
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteImageCategory(DeleteCategoriesId);
                      }}
                      disabled={isLoading}
                    >
                      {" "}
                      {isLoading ? "Loading..." : "Delete"}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <Row>
                {rowData.length > 0 &&
                  rowData.map(({ _id, imageCategory }, index) => {
                    return (
                      <Col md={4}>
                        <Card style={{ border: "2px solid #e2e2e2" }} id={_id}>
                          <Card.Body>
                            <Card.Title
                              style={{
                                cursor: "pointer",
                                marginBottom: "25px",
                              }}
                              onClick={() => {
                                navigate(`/images/${_id}`);
                              }}
                            >
                              {imageCategory}
                            </Card.Title>
                            {/* <Card.Text>
                            Some quick example text to build on the card title
                            and make up the bulk of the card's content.
                          </Card.Text> */}
                            <div className="iconActionList">
                              <div className="DeleteIcon">
                                <AiFillDelete
                                  className="ActionIcon"
                                  onClick={() => {
                                    setDeleteCategoriesId(_id);
                                    setDeleteIsOpen(true);
                                  }}
                                />
                              </div>
                              <div className="editIcon">
                                <MdModeEditOutline
                                  className="ActionIcon"
                                  onClick={() => {
                                    setUpdateData({ _id, imageCategory });
                                    setEditIsOpen(true);
                                    setValue("imageCategory", imageCategory);
                                  }}
                                />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ImageDirectory;
