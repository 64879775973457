import React, { useState, useMemo, useEffect, Fragment } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./innerTv.css";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import UpdateInnerTv from "./UpdateInnerTv";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "react-bootstrap";
import RenderImage from "../../components/Image";
import { exportToExcel } from "react-json-to-excel";
import Select from "react-select";
import UpdateInnerTvCategories from "./UpdateInnerTvCategories";
import { CSVLink } from "react-csv";

function InnerTvCategories() {
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    reset,
    watch,
    control,
    setValue,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors },
    reset: uploadreset,
  } = useForm();

  const [UpdateWeatherData, setUpdateWeatherData] = useState();
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  const [tags, setTags] = useState("");
  const [editIsOpen, setEditIsOpen] = useState(false);
  const rowHeight = 55;
  const [downloadData, setDownloadData] = useState([]);

  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    function getweather() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getInnerTV`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          setRowData(response.data.result);
          const downloadData = response.data.result?.map(
            ({ title, subtitle, rank }) => {
              return {
                title: title ? title : "",
                subtitle: subtitle ? subtitle : "",
                rank: rank ? rank : "",
              };
            }
          );
          setDownloadData(downloadData);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getweather();
  }, []);

  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateWeatherData(props.data);
            setEditIsOpen(true);
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DeleteCategoriesData"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  const resetForm = () => {
    setTags("");
    setSendinnericonImage("");
    setInnericon("");
  };

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const titleNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props?.data?.title}</span>
      </span>
    );
  };
  const subtitleNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props?.data?.subtitle}</span>
      </span>
    );
  };
  const rankNameRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props?.data?.rank}</span>
      </span>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    // getweather();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };
  const statusValueGetter = (params) => {
    return params.data.status;
  };
  //----------//---------//
  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    cancelForm();
  }
  function cancelForm() {
    reset();
  }
  function fun() {
    setIsOpen(false);
  }

  function close() {
    setEditIsOpen(false);
    setUpdateWeatherData({});
  }

  //---------Add weather Api -----//

  //--------Function to handle and set Author Image--------//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
          setErr(false);
        },
      });
    }
  };
  //-------//--------//

  //--------Function to render images--------//

  const [err, setErr] = useState(false);
  function addnewWeather(postdata) {
    const postData = {
      title: postdata?.innercattitle,
      subtitle: postdata?.innercatsubtitle,
      rank: postdata?.innercatrank,
    };

    axios({
      method: "post",
      url: "https://api.inner.garden/admin/v1/createInnerTV",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: postData,
    })
      .then(function (response) {
        // Handle successful response here
        reset();
        setIsOpen(false);
      })
      .catch(function (error) {
        // Handle error here
        reset();
        console.error("API call failed:", error);
      });
  }

  //---------Status Inners Api -----//

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this Weather?";
    return <Modal_Popup status={props?.data?.status} message={message} />;
  };

  //---------delete Inners Api -----//
  function deleteWeather(index) {
    let config = {
      method: "Delete",
      url: `${process.env.REACT_APP_BASEURL}/deleteInnerTV/${index}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log("response");
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);s

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/innerTVbulkupload  `,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        // "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "bulk upload response");
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const headers = [
    { label: "Title", key: "title" },
    { label: "Sub Title", key: "subtitle" },
    { label: "Rank", key: "rank" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    console.log("value", value);
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Inner Tv Categories
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsOpen(true)}
                  >
                    Add Inner Tv Categories
                  </button>

                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="InnerTvCategories.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Weathers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              {/* //--Add waether---// */}
              <div>
                <Modal show={isOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Inner Tv Categories
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => somefun()}
                            ></button>
                          </div>
                          <form onSubmit={handlesubmitUpload(addnewWeather)}>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Title</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("innercattitle", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.innercattitle?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                  {/* {uploaderrors?.innercattitle?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )} */}
                                  {/* add new */}
                                  <label className="form-label">SubTitle</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("innercatsubtitle", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.innercatsubtitle?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>

                                  <label className="form-label">Rank</label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      {...uploadRegister("innercatrank", {
                                        required: true,
                                      })}
                                    />

                                    {uploaderrors?.innercatrank?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                onClick={() => somefun()}
                                type="button"
                                className="btn btn-danger CancelBtn"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn submitBtn">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              {/* //--//---// */}

              {/* delete */}
              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Categories
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteWeather(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* delete */}

              {/* edit */}
              {editIsOpen && (
                <div
                  className="modal fade"
                  id="UpdateWeatherData"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <UpdateInnerTvCategories
                        updatedData={UpdateWeatherData}
                        onEditDataFunction={editdataReloadFunc}
                        editIsOpen={editIsOpen}
                        close={close}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* edit */}

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    statusRenderer: StatusRenderer,
                    titleNameRenderer: titleNameRenderer,
                    subtitleNameRenderer: subtitleNameRenderer,
                    rankNameRenderer,
                    rankNameRenderer,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="titleNameRenderer"
                    field="titleName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="subtitleNameRenderer"
                    field="subtitleName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={260}
                    cellRenderer="rankNameRenderer"
                    field="rank"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default InnerTvCategories;
