import React from "react";

export default function RenderImage({ image }) {
  console.log("image>>>>>>>>>>>>>>>>>", image);
  return (
    <img
      alt="renderImages"
      style={{ width: "110px", height: "140px" }}
      src={image}
      key={image}
    />
  );
}
