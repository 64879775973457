import React, { useState, useEffect, useRef, Fragment } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArtSeries.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  articleSeriesList,
  updateArticleSeries,
} from "../../redux/reducers/articleSeriesReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Compressor from "compressorjs";
import { useNavigate } from "react-router-dom";
import Reorder from "../../components/Reorder";
import Select from "react-select";
import RenderImage from "../../components/Image";
import { Controller, useForm } from "react-hook-form";

function AddArtSeries() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(-1);

  const {
    apiSeriesData,
    articleSeriesListCount,
    series,
    removeSeriesListCount,
    lifecycleType,
  } = useSelector((store) => store.articleSeries);

  console.log("apiSeriesData", apiSeriesData);
  //---------------/Get Articles api/-------------//
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    setValue,
    control,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    dispatch(articleSeriesList());
    getImageCategory();
  }, []);

  useEffect(() => {
    setInputFields(apiSeriesData);
  }, [articleSeriesListCount]);

  useEffect(() => {
    if (removeSeriesListCount && lifecycleType === "remove") {
      setInputFields(apiSeriesData);
      setArticles(series);
    }
  }, [removeSeriesListCount, series.length]);

  const selectRef = useRef(null);

  const [addButtonStatus, setAddButtonStatus] = useState(null);
  //-------------/Add more Button/----------------//
  const [inputFields, setInputFields] = useState([]); // Initial input fields
  //---------------/Add-Series api/------------//
  const [articles, setArticles] = useState([]);
  const [newtitleName, setNewTitleName] = useState("");
  const [newtitleNameError, setNewTitleNameError] = useState(false);
  const [seriesError, setSeriesError] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showImageErr, setShowImageErr] = useState(false);
  const [showImageFile, setShowImageFile] = useState(true);
  const [uniqueSeriesError, setUniqueSeriesError] = useState(false);
  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [innericon, setInnericon] = useState("");
  const [emptySeriesError, setEmptySeriesError] = useState(false);
  const [fileSelectionError, setFileSelectionError] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({
    value: "",
    label: "",
  });
  const [topicData, setTopicData] = useState(null);
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [optionscategory, setoptionscategory] = useState([]);
  const [optionsSubCategory, setoptionsSubCategory] = useState([]);
  const [selectCategoryID, setSelectCategoryID] = useState("");

  const handleAuthorImage = (files) => {
    if (!files || !files[0]) {
      setFileSelectionError(true);
      return;
    }
    const fileSize = files[0].size / 1024;
    if (fileSize > 500) {
      setFileSelectionError(true);
      console.error("File size exceeds 500 KB limit");
      return;
    }
    new Compressor(files[0], {
      quality: 0.2,
      success: (compressedResult) => {
        const display = URL.createObjectURL(compressedResult);
        setInnericon(display);
        setFileSelectionError(false); // Reset error state if successful
      },
    });
  };
  const handleSelectClose = () => {
    setDropdownVisible(false);
  };

  const handleRadioClick = () => {
    setDropdownVisible(true);
    // Check if the ref is assigned and if the Select component is defined
    if (selectRef.current && selectRef.current.select) {
      // Open the Select component's menu
      selectRef.current.select.openMenu();
    }
  };
  function AddSeries(postData) {
    setAddButtonStatus(true);
    const formData = new FormData();
    formData.append("title", newtitleName);
    formData.append("titleId", series.map((item) => item?.value).join(","));
    formData.append(
      "seriesImage",
      postData.seriesImage[0] === "h"
        ? postData.seriesImage
        : postData.seriesImage[0]
    );

    formData.append("subCategory", selectedTopicId);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/articleseries`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "Application/json",
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "AddSeries");
        setTimeout(() => {
          navigate("/ArtSeries");
        }, 4000);
      })
      .catch(function (error) {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setTimeout(() => {
          setAddButtonStatus(false);
        }, 4000);
      });
  }

  //GET IMAGE CATEGORY API
  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/gettopic`,
          {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("AdminToken"),
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTopicData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  //---------------/-//--/----------------------//
  useEffect(() => {
    function getCategory() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getCategory`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let options = [];
          response.data.CategoryData.forEach((tag, index) => {
            if (tag.status == true) {
              options.push({ label: tag.category, value: tag._id });
            }
          });
          setoptionscategory(options);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
    getCategory();
  }, []);

  useEffect(() => {
    function getsubCategory() {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getSubCategoryByCategoryID/${selectCategoryID}`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let options = [];
          response?.data?.data?.forEach((tag, index) => {
            if (tag.status == true) {
              options.push({ label: tag.topicName, value: tag._id });
            }
          });
          setoptionsSubCategory(options);
        })
        .catch(function (error) {
          setoptionsSubCategory([]);
        });
    }
    getsubCategory();
  }, [selectCategoryID]);

  async function addingArticlesToSeriesContainer(event) {
    if (Object.values(selectedArticle).some((item) => item.length === 0)) {
      setEmptySeriesError(true);
      return;
    }
    if (articles.find((item) => item.value === selectedArticle?.value)) {
      setUniqueSeriesError(true);
      return;
    }

    const updateInputField = inputFields.filter(
      (item) => item.value !== selectedArticle?.value
    );

    console.log(updateInputField, "updateInputField");
    setInputFields(updateInputField);
    dispatch(
      updateArticleSeries({ apiSeriesData: updateInputField, type: "update" })
    );

    setArticles((prev) => [...prev, selectedArticle]);
    setSeriesError(false);
  }

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add New Paths</h4>
              <div className="branchData">
                <form onSubmit={handleSubmit(AddSeries)}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                setNewTitleName(e.target.value);
                              }}
                              onBlur={() => {
                                setNewTitleNameError(false);
                              }}
                            />
                            {newtitleNameError && (
                              <p style={{ color: "red" }}>
                                This Field is required
                              </p>
                            )}
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Rank</label>
                            <input
                              type="number"
                              className="form-control"
                              min={0}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Paths Image</label>

                            <div className="position-relative">
                              <label className="form-label">
                                Upload File
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="file"
                                />
                              </label>
                              <label className="form-label">
                                from directory
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="from directory"
                                />
                              </label>
                              <label className="form-label">
                                Image URL
                                <input
                                  {...register("uploadFile", {
                                    required: true,
                                  })}
                                  type="radio"
                                  value="image url"
                                />
                              </label>
                              {errors?.uploadFile?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                              {watch("uploadFile") === "file" && (
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      {...register("seriesImage", {
                                        required:
                                          watch("uploadFile") === "file"
                                            ? true
                                            : false,
                                      })}
                                      onChange={(e) => {
                                        handleAuthorImage(e);
                                        unregister("seriesImage");
                                      }}
                                    />

                                    {innericon.length === 0 ? (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "128px",
                                        }}
                                        src="../../images/defalutimg.svg"
                                      />
                                    ) : (
                                      <RenderImage image={innericon} />
                                    )}

                                    {errors?.seriesImage?.type ===
                                      "required" && (
                                      <p style={{ color: "red" }}>
                                        This field is required
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              )}
                              {watch("uploadFile") === "from directory" && (
                                <Fragment>
                                  <Controller
                                    name="directory"
                                    control={control}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        isSearchable={false}
                                        className="react-dropdown"
                                        classNamePrefix="dropdown"
                                        options={directoryData}
                                        onChange={(event) => {
                                          field.onChange(event); // Update the field value
                                          setImagesData(event.images);
                                        }}
                                      />
                                    )}
                                  />
                                  {Object.keys(errors).includes(
                                    "directory"
                                  ) && (
                                    <p style={{ color: "red" }}>
                                      {errors.directory.message}
                                    </p>
                                  )}{" "}
                                  <div class="imageList">
                                    {imagesData &&
                                      imagesData.length > 0 &&
                                      imagesData.map((item, index) => (
                                        <Controller
                                          name="seriesImage"
                                          control={control}
                                          render={() => (
                                            <div
                                              className={`uploadedImg ${
                                                active === index && "active"
                                              }`}
                                              onClick={() => {
                                                setValue(
                                                  "seriesImage",
                                                  item.location
                                                );
                                                setActive(index);
                                              }}
                                            >
                                              <img
                                                height={50}
                                                width={50}
                                                alt="icon"
                                                src={item?.location}
                                              />
                                            </div>
                                          )}
                                        />
                                      ))}
                                  </div>
                                </Fragment>
                              )}
                              {watch("uploadFile") === "image url" && (
                                <div>
                                  <input
                                    type="text"
                                    {...register("seriesImage", {
                                      required:
                                        watch("uploadFile") === "image url"
                                          ? true
                                          : false,
                                    })}
                                    placeholder="Enter Image URL"
                                  />

                                  {errors?.seriesImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Select Article Title
                            </label>
                            <div className="d-flex" style={{ width: "100%" }}>
                              <div style={{ width: "100%" }}>
                                <Select
                                  options={inputFields}
                                  onChange={(event) => {
                                    setSeriesError(false);
                                    setEmptySeriesError(false);
                                    setUniqueSeriesError(false);
                                    setSelectedArticle(event);
                                  }}
                                />
                                {seriesError && (
                                  <p style={{ color: "red" }}>
                                    This Field is required
                                  </p>
                                )}
                                {emptySeriesError && (
                                  <p style={{ color: "red" }}>
                                    Please Select Something from above Dropdown
                                  </p>
                                )}
                                {uniqueSeriesError && (
                                  <p style={{ color: "red" }}>
                                    You can't add the selected field again
                                  </p>
                                )}
                              </div>
                              <button
                                className="btn btn-success ms-2"
                                type="button"
                                onClick={addingArticlesToSeriesContainer}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          {/* //////////// */}
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Select Categories
                            </label>
                            <Controller
                              name="categories"
                              control={control}
                              rules={{
                                required: "This field is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={optionscategory}
                                  onChange={(event) => {
                                    field.onChange(event);
                                    setSelectCategoryID(event?.value);
                                  }}
                                />
                              )}
                            />
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Select Sub Categories
                            </label>
                            <Controller
                              name="subcategories"
                              control={control}
                              rules={{
                                required: "This field is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={optionsSubCategory}
                                  onChange={(event) => {
                                    field.onChange(event);
                                    setSelectedTopicId(event?.value);
                                  }}
                                />
                              )}
                            />

                            {Object.keys(errors).includes("subcategories") && (
                              <p style={{ color: "red" }}>
                                {errors.tag.message}
                              </p>
                            )}
                          </div>

                          {/* //////////// */}

                          <Reorder articles={articles} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          navigate("/ArtSeries");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn submitBtn me-3"
                        // onClick={() => {
                        //   AddSeries();
                        // }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddArtSeries;
