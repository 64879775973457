import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//security_question / approval_email / false

export let articleSeriesListItems;

const initialState = {
  loading: false,
  articleSeriesListCount: 0,
  apiSeriesData: [],
  series: [],
  removeSeriesListCount: 0,
  lifecycleType: "",
};

export const articleSeriesList = createAsyncThunk(
  "getArticleSeries",
  async () => {
    const result = await fetch(`${process.env.REACT_APP_BASEURL}/getarticles`, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
    });

    const data = await result.json();
    return data;
  }
);

const articleSeries = createSlice({
  name: "articleSeries",
  initialState,
  reducers: {
    getOwnerId(state = initialState, action) {},
    articleSeriesData(state = initialState, action) {
      state.series = action.payload.series;
    },
    updateArticleSeries(state = initialState, action) {
      state.apiSeriesData = action.payload.apiSeriesData;
      if (action.payload.type === "remove") {
        state.removeSeriesListCount += 1;
      }
      state.lifecycleType = action.payload.type;
    },
  },
  extraReducers: {
    [articleSeriesList.fulfilled]: (
      state,
      { payload: { error, message, result } }
    ) => {
      // && item.statusMessage && item.statusMessage !== "Draft"
      const articleTitleOptions = result
        ? result
            .filter((item) => item)
            .map(({ articleTitle, _id }) => ({
              label: articleTitle,
              value: _id,
            }))
        : [];

      state.loading = false;
      state.articleSeriesListCount += 1;
      state.apiSeriesData = articleTitleOptions;
    },
  },
});

export const { getOwnerId, articleSeriesData, updateArticleSeries } =
  articleSeries.actions;
export default articleSeries.reducer;
