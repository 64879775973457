import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Articles.css";
import "../Gardener/Gardener.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import JsonDownload from "../../components/downloadjson";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import CsvDownloadButton from "react-json-to-csv";
import { CSVLink, CSVDownload } from "react-csv";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const ImageRenderer = (props, ref) => {
  return (
    <span className="profle_img_box">
      <img
        className="profile_img_table"
        src={
          props?.data?.type === "GARDENER"
            ? props.data?.userName?.aboutcommunitystrengths?.about?.userImage
            : props.data?.authorName?.authorImage
        }
        alt="icon"
      />
    </span>
  );
};

const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data?.articleTitle}</span>
    </>
  );
};

const AuthorNameRenderer = (props) => {
  return (
    <>
      <span>
        {props?.data?.type === "GARDENER"
          ? props.data?.userName?.first_name
          : props.data?.authorName?.authorName}
      </span>
    </>
  );
};

const ArticleType = (props) => {
  console.log("props", props);
  return (
    <>
      <span>
        {props?.data?.type === "GARDENER"
          ? props.data?.blogType
          : props.data?.articleType}
      </span>
    </>
  );
};

const CreatedByRenderer = (props) => {
  return (
    <>
      <span>{props.data?.createdBy}</span>
    </>
  );
};

const DescriptionRenderer = (props) => {
  return (
    <>
      <span>{props.data?.description}</span>
    </>
  );
};

const TagsRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props?.data?.tags?.join(", ")}</span>
    </span>
  );
};

const GroupRender = (props) => {
  return (
    <span className="profle_img_box">
      <span>
        {props?.data?.groups_Id.map((item) => item.grpName)?.join(", ")}
      </span>
    </span>
  );
};

const CreatedDateRenderer = (props) => {
  const date = new Date(+props.data.createdDate);
  return (
    <span className="profle_img_box">
      <span>{date.toLocaleDateString("en-US")}</span>
    </span>
  );
};

const ArticleimageRenderer = (props) => {
  return (
    <span>
      <img className="profile_img_table" src={props.data.banner} alt="icon" />
    </span>
  );
};

function Articles() {
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [rowData, setRowData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [DeleteBusinessId, setDeleteBusinessId] = useState("");
  const rowHeight = 55;
  let navigate = useNavigate();
  const token = `${sessionStorage.getItem("token")}`;
  let encoded = encodeURIComponent(token);

  useEffect(() => {
    getarticles();
  }, []);

  // ---- Get article API ---//
  function getarticles() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getarticles`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log("response.data.result", response.data.result);
        setRowData(response.data.result);
        const downloadData = response.data.result?.map(
          ({
            aiMeter,
            articleDescription,
            articleImage,
            articleRank,
            articleSubTitle,
            articleTitle,
            articleType,
            authorName,
            categoryId,
            headContent,
            innerId,
            metaDescription,
            oppositeTags,
            science,
            scienceImage,
            status,
            tags,
            topicId,
            weatherTag,
            wellgoContent,
            wellgoHeadline,
            wellgoImage,
            wellgoPoints,
            wellgoTitle,
            sciencename,
            userName,
            _id,
          }) => {
            return {
              authorUniqueId: authorName?.uniqueId
                ? authorName?.uniqueId
                : userName?.uniqueId,
              categoryName: categoryId ? categoryId?.category : "",
              subCategoryName: topicId ? topicId?.topicName : "",
              articleTitle: articleTitle ? articleTitle : "",
              articleSubTitle: articleSubTitle ? articleSubTitle : "",
              articleImage: articleImage ? articleImage : "",
              articleDescription: articleDescription ? articleDescription : "",
              articleType: articleType ? articleType : "",
              articleRank: articleRank ? articleRank : "",
              tagsName:
                tags && tags.length > 0
                  ? tags.map((tag) => tag?.tags).join(",")
                  : "",
              ProblemTag:
                oppositeTags && oppositeTags.length > 0
                  ? oppositeTags.map((otag) => otag?.tags).join(",")
                  : "",
              weatherName: weatherTag ? weatherTag?.weatherTagName : "",
              XBTname: innerId ? innerId?.inner : "",
              "Distinction,Imagine":
                headContent && headContent.length > 0
                  ? headContent
                      .map(
                        (head_content) =>
                          `${head_content?.heading},${head_content?.content}`
                      )
                      .join("||")
                  : "",
              heroName: sciencename ? sciencename : "",
              heroImage: scienceImage ? scienceImage : "",
              heroContent: science ? science : "",
              wellgoTitle: wellgoTitle ? wellgoTitle?.name : "",
              wellgoHeadline: wellgoHeadline ? wellgoHeadline : "",
              wellgoContent: wellgoContent ? wellgoContent : "",
              wellgoPoints: wellgoTitle?.wellgorithmsPoints
                ? wellgoTitle?.wellgorithmsPoints
                : "",
              wellgoTags:
                wellgoTitle?.tag && wellgoTitle?.tag?.length > 0
                  ? wellgoTitle?.tag?.map((tag) => tag?.tags).join(",")
                  : "",
              wellgoGroupTags:
                wellgoTitle?.tagGroup && wellgoTitle?.tagGroup?.length > 0
                  ? wellgoTitle?.tagGroup
                      .map((gtag) => gtag?.groupName)
                      .join(",")
                  : "",
              wellgoImage: wellgoTitle?.wellgoImage
                ? wellgoTitle?.wellgoImage
                : "",
              metaDescription: metaDescription ? metaDescription : "",
              aiMeter: aiMeter ?? "",
            };
          }
        );
        setArticleData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ----Delete article API ---//
  function articlesdeleteData(id) {
    let data = JSON.stringify({
      articleId: id,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletearticles`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  // ---- article status API ---//
  function changeArticleStatus(articleId) {
    let data = JSON.stringify({
      articleId: articleId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changearticleStatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this article?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeArticleStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };
  function changeArticleTrendingStatus(articleId) {
    let data = JSON.stringify({
      articleId: articleId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changearticletrendingstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getarticles();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeArticleTrendingStatus(props?.data?._id);
        }}
        status={props?.data?.trending}
      />
    );
  };

  // ----  article status API ---//
  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() =>
            navigate({
              pathname: `/UpdateArticles/${props.data._id}`,
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteBusinessId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);
  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);

  function somefun() {
    setIsBulkUploadOpen(false);
    setIsLoading(false);
  }

  function bulkupload({ uploadImages }) {
    const formData = new FormData();
    formData.append("attachment", uploadImages[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/articlebulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: formData,
    };
    axios(config)
      .then(function (response) {
        if (
          response.data.message.includes("bulk upload created successfully")
        ) {
          toast.success(response.data.message);
          reset();
          somefun();
          getarticles();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      value = value?.replace(/"/g, '""');
    }
    return value;
  };
  const prepareData = (data) => {
    return data?.map((row) =>
      Object.fromEntries(
        Object.entries(row)?.map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };
  const preparedData = prepareData(articleData);

  const headers = [
    { label: "Author/Gardner UniqueId", key: "authorUniqueId" },
    { label: "Category", key: "categoryName" },
    { label: "Sub Category", key: "subCategoryName" },
    { label: "Article Title", key: "articleTitle" },
    { label: "Article Sub Title", key: "articleSubTitle" },
    { label: "Article Image", key: "articleImage" },
    { label: "Article Description", key: "articleDescription" },
    { label: "Article Type", key: "articleType" },
    { label: "Article Ranks", key: "articleRank" },
    { label: "Article Tags", key: "tagsName" },
    { label: "Article Problem Tag", key: "ProblemTag" },
    { label: "Weather", key: "weatherName" },
    { label: "XBT", key: "XBTname" },
    { label: "Distinction,Imagine", key: "Distinction,Imagine" },
    { label: "Hero Name", key: "heroName" },
    { label: "Hero Content", key: "heroContent" },
    { label: "Hero Image", key: "heroImage" },
    { label: "Wellgo Title", key: "wellgoTitle" },
    { label: "Wellgo Headline", key: "wellgoHeadline" },
    { label: "Wellgo Content", key: "wellgoContent" },
    { label: "Wellgo Points", key: "wellgoPoints" },
    { label: "Wellgo Tags", key: "wellgoTags" },
    { label: "Wellgo Group Tags", key: "wellgoGroupTags" },
    { label: "Wellgo Image", key: "wellgoImage" },
    { label: "Meta Description", key: "metaDescription" },
    { label: "AI Meter", key: "aiMeter" },
  ];

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Journals
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => navigate("/AddArticles")}
                  >
                    Add New Journals
                  </button>

                  <CSVLink
                    data={preparedData ? preparedData : []}
                    headers={headers}
                    filename="Journal.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                </div>
              </h4>

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={handleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Journals</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...register("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                unregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          reset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this article?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                articlesdeleteData(DeleteBusinessId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      childMessageRenderer: ChildMessageRenderer,
                      srNoRenderer: SrNoRenderer,
                      titleRenderer: TitleRenderer,
                      authorNameRenderer: AuthorNameRenderer,
                      articleType: ArticleType,
                      createdByRenderer: CreatedByRenderer,
                      descriptionRenderer: DescriptionRenderer,
                      imageRenderer: ImageRenderer,
                      tagRenderer: TagsRenderer,
                      statusRenderer: StatusRenderer,
                      TrendingRender: TrendingRender,
                      GroupRender: GroupRender,
                      createdDateRenderer: CreatedDateRenderer,
                      articleimageRenderer: ArticleimageRenderer,
                    }}
                  >
                    <AgGridColumn
                      flex={1}
                      field="articleRank"
                      headerName="Journals Rank"
                      // cellRenderer="srNoRenderer"
                      sortable={true}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={1}
                      field="JournalsTitle"
                      cellRenderer="titleRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="JournalsType"
                      cellRenderer="articleType"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="authorName"
                      cellRenderer="authorNameRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="authorimage"
                      cellRenderer="imageRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                    <AgGridColumn
                      cellRenderer="statusRenderer"
                      flex={1}
                      field="status"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Articles;
