import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { RxCrossCircled } from "react-icons/rx";
import { ItemTypes } from "./ItemTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  articleSeriesData,
  updateArticleSeries,
} from "../../redux/reducers/articleSeriesReducer";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};

export const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { series, apiSeriesData } = useSelector((store) => store.articleSeries);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ ...style, opacity, position: "relative" }}
      data-handler-id={handlerId}
    >
      {text}
      <RxCrossCircled
        style={{
          position: "absolute",
          right: "0px",
          top: "50%",
          transform: "translate(-50%, -50%)",
          cursor: "pointer",
        }}
        className="crossbox"
        onClick={async () => {
          const removeIdData = series?.find((item) => item?.value === id);
          let addSeriesItemtoApiData = [];

          if (removeIdData) {
            addSeriesItemtoApiData = [...apiSeriesData, removeIdData];
          } else {
            addSeriesItemtoApiData = [...apiSeriesData];
          }
          const updateSeriesData = series.filter((item) => item?.value !== id);
          await dispatch(
            updateArticleSeries({
              apiSeriesData: addSeriesItemtoApiData,
              type: "remove",
            })
          );
          await dispatch(articleSeriesData({ series: updateSeriesData }));
        }}
      />
    </div>
  );
};
