import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./Topics.css";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Compressor from "compressorjs";
import RenderImage from "../../components/Image";

const UpdateTopics = (props) => {
  console.log("props>>>>>>>>>>>>>>>>>>>>>>>", props);

  const [editGroupData, setEditData] = useState(props?.updateData);
  const [Image, setImage] = useState("");
  const [sendImage, setSendImage] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [updateCategory, setUpdateCategory] = useState("");
  const [topicName, setTopicName] = useState("");
  const [active, setActive] = useState(-1);
  useEffect(() => {
    setEditData(props.updatedData);
  }, [props]);
  const { updatedData } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    unregister,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      topicName: updatedData?.topicName || "",
      topicRank: updatedData?.topicRank || "",
      content: updatedData?.content || "",
      category: {
        label: updatedData?.categoryId?.category || "",
        value: updatedData?.categoryId?._id || "",
      },
    },
  });
  // useEffect(() => {
  //   setEditData(props.updatedData);
  //   console.log(props.updatedData, "props");
  //   if (props?.updatedData) {
  //     setValue("categoryImage", props?.updatedData?.topicImage);
  //   }
  // }, [props]);
  const resetForm = () => {
    setTopicName("");
    setUpdateCategory("");
  };
  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }
  //--------Function to handle and set Weather Image--------//
  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");
  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.result, "RESULT");
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }

  useEffect(() => {
    if (props?.updatedData) {
      setValue("topicImage", props?.updatedData?.topicImage);
    }
  }, [props]);

  function SubcategoryData(postdata) {
    console.log("postdata>>>>>>>>>>", postdata);
    let data = new FormData();
    data.append("categoryId", postdata.category.value);
    data.append("topicName", postdata.topicName);
    data.append("topicRank", postdata.topicRank);
    data.append("content", postdata.content);
    data.append("topicId", editGroupData?._id);

    data.append(
      "topicImage",
      postdata.topicImage[0] === "h"
        ? postdata.topicImage
        : postdata.topicImage[0]
    );

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updatetopic`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        resetForm();
      })
      .catch(function (error) {});

    props.close();
  }
  // --get category API--//

  useEffect(() => {
    getCategory();
    getImageCategory();
  }, []);

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const categoryData = response.data.CategoryData.map((item) => ({
          label: item.category,
          value: item._id,
        }));
        setCategoryData(categoryData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = 1;
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows;
    setHeight(`${event.target.scrollHeight}px`);
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight;
    }
  };
  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit SubCategory
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handleSubmit(SubcategoryData)}>
                    <div className="modal-body">
                      <div className="row">
                        {/* <div className="col-md-12 my-3">
                          <label className="form-label">Image</label>
                          <div className="position-relative">
                            <ul className="imagesUpload">
                              <li
                                style={{
                                  width: "128px",
                                  height: "128px",
                                }}
                              >
                                <input
                                  type="file"
                                  accept="image/*,capture=camera"
                                  capture="”camera"
                                  {...register("topicImage", {
                                    validate: validateFileSize,
                                  })}
                                  onChange={(e) => {
                                    handleImage(e);
                                  }}
                                />

                                {errors?.topicImage?.type === "validate" && (
                                  <p style={{ color: "red" }}>
                                    File size should be 500KB or less
                                  </p>
                                )}
                                {!sendImage ? (
                                  <img
                                    alt="renderImages"
                                    style={{ width: "100%", height: "148px" }}
                                    src={updatedData?.topicImage}
                                  />
                                ) : (
                                  <RenderImage image={Image} />
                                )}
                              </li>
                            </ul>
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div> */}
                        {!watch("uploadFile") && (
                          <div className="col-md-12 mb-3 mt-3">
                            <label className="form-label">
                              SubCategory Image
                            </label>
                            <div className="position-relative">
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "128px",
                                    height: "128px",
                                  }}
                                >
                                  {watch("topicImage") && (
                                    <img
                                      alt="renderImages"
                                      style={{ width: "100%", height: "148px" }}
                                      src={watch("topicImage") ?? innericon}
                                    />
                                  )}
                                </li>
                              </ul>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Category Image</label>

                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="image url"
                              />
                            </label>
                            {errors?.uploadFile?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("topicImage", {
                                      required:
                                        watch("uploadFile") === "file"
                                          ? true
                                          : false,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("topicImage");
                                    }}
                                  />

                                  {innericon.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={innericon} />
                                  )}

                                  {errors?.topicImage?.type === "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}

                                  {errors?.topicImage?.type === "validate" && (
                                    <p style={{ color: "red" }}>
                                      File size should be 500KB or less
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("uploadFile") === "from directory"
                                        ? true
                                        : false,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}{" "}
                                <div class="imageList">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item, index) => (
                                      <Controller
                                        name="topicImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className={`uploadedImg ${
                                              active === index && "active"
                                            }`}
                                            onClick={() => {
                                              setValue(
                                                "topicImage",
                                                item.location
                                              );
                                              setActive(index);
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("topicImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.topicImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 my-3">
                          <div className="position-relative">
                            <label className="form-label">Subcategory</label>
                            <input
                              className="form-control"
                              {...register("topicName", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {errors?.topicName?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {errors?.topicName?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                          </div>
                          <div className="position-relative">
                            <label className="form-label">Content</label>
                            <textarea
                              className="form-control"
                              style={{
                                height: height,
                                minHeight: "20px",
                                overflow: "hidden",
                                resize: "none",
                              }}
                              onMouseMove={handleInput}
                              onInput={handleInput}
                              {...register("content", {
                                required: true,
                              })}
                            />
                            {errors?.content?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>

                          <div className="col-md-12 my-3">
                            <label className="form-label">
                              SubCategory Rank
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control"
                                {...register("topicRank", {
                                  required: true,
                                })}
                              />
                              {errors?.topicRank?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="position-relative">
                            <label
                              className="form-label"
                              style={{ "padding-top": "14px" }}
                            >
                              Select Category
                            </label>
                            <Controller
                              name="category"
                              control={control}
                              rules={{
                                required: "This field is required",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isSearchable={false}
                                  className="react-dropdown"
                                  classNamePrefix="dropdown"
                                  options={categoryData}
                                  onChange={(event) => {
                                    field.onChange(event); // Update the field value
                                    setUpdateCategory(event.value);
                                  }}
                                />
                              )}
                            />

                            {Object.keys(errors).includes("category") && (
                              <p style={{ color: "red" }}>
                                {errors.category.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateTopics;
