import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Grouptags.css";
import { AiFillEye } from "react-icons/ai";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { exportToExcel } from "react-json-to-excel";

function Contect() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    unregister,
  } = useForm();

  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors: uploaderrors },
    reset: uploadreset,
  } = useForm();

  const [UpdateGroupData, setUpdateGroupData] = useState();
  const [DeleteGroupId, setDeleteGroupId] = useState("");
  const [GroupView, setGroupView] = useState([]);
  const [groupName, setGroups] = useState("");
  const [rowData, setRowData] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const rowHeight = 55;
  const [editIsOpen, setEditIsOpen] = useState(false);

  function close() {
    setEditIsOpen(false);
    setUpdateGroupData({});
    setRowData(rowData);
  }
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="ViewIcon"
          onClick={() => {
            setGroupView(props.data);
          }}
          data-bs-toggle="modal"
          data-bs-target="#DetailGroup"
        >
          <AiFillEye className="ActionIcon" />
        </div>
      </div>
    );
  };
  const [isOpen, setIsOpen] = useState(false);
  function somefun() {
    setIsBulkUploadOpen(false);
    setIsOpen(false);
    reset();
  }

  const resetForm = () => {
    setGroups("");
  };

  const handlechangetag = (event) => {
    let array = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    setselectedOptionone(array);
    setErr(false);
  };

  const FirstNameRenderer = (props) => {
    // const firstName = [];
    // props.data?.map((item) => {
    //   firstName.push(item.firstName);
    // });
    return <span>{props.data?.firstName}</span>;
  };

  const LastNameRenderer = (props) => {
    // const lastName = [];
    // props.data?.map((item) => {
    //   lastName.push(item.lastName);
    // });
    return <span>{props.data?.lastName}</span>;
  };

  const EmailRenderer = (props) => {
    // const email = [];
    // props.data?.map((item) => {
    //   email.push(item.email);
    // });
    return <span>{props.data?.email}</span>;
  };

  const DescriptionRenderer = (props) => {
    // const description = [];
    // props.data?.map((item) => {
    //   description.push(item.description);
    // });
    return <span>{props.data?.message}</span>;
  };

  let viewTags = GroupView?.tags?.map((tag) => {
    return tag?.tags.trim();
  });

  const SrNoRenderer = (props) => {
    return <span className="profle_img_box">{props.rowIndex + 1}</span>;
  };

  const GroupRenderer = (props) => {
    return (
      <span className="profle_img_box">
        <span>{props.data.groupName}</span>
      </span>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const editdataReloadFunc = () => {
    getgrouptags();
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  useEffect(() => {
    getnewtag();
    getgrouptags();
  }, []);
  //-----------Add Group-tag api--------//
  const [err, setErr] = useState(false);

  //-----------Get Group-tag api----------//
  const [downloadData, setDownloadData] = useState([]);

  function getgrouptags() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/contactus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response?.data?.data);
        const downloadData = response?.data?.data?.map(
          ({ firstName, _id, lastName, email, message }) => {
            return {
              firstName: firstName ? firstName : "",
              lastName: lastName ? lastName : "",
              email: email ? email : "",
              message: message ? message : "",
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //-----------Delete Group-tag api----------//
  function deleteGroup(index) {
    let data = JSON.stringify({
      grouptagId: index,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletegrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getgrouptags();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Get tag API ---//
  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const options = [];
        response.data.tagData.forEach((tag, index) => {
          if (tag.status === true) {
            options.push({ value: tag._id, label: tag.tags });
          }
        });
        setoptions(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Contact
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() =>
                      exportToExcel(
                        [
                          {
                            sheetName: "taggroupbulkupload",
                            details: downloadData,
                          },
                        ],
                        "Contact List",
                        true
                      )
                    }
                  >
                    {" "}
                    Export
                  </button>

                  {/* <JsonDownload
                    title="Export"
                    fileName="Tag Group List"
                    downloadfile={downloadData}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  /> */}
                </div>
              </h4>

              {/* Upload Images */}

              <div
                className="modal fade"
                id="UpdateCategoriesData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                className="modal fade DeletePopup"
                id="DeleteGroupData"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            <p>
                              Are you sure you want to delete this Tag-Group
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteGroup(DeleteGroupId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="DetailGroup"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Contact Details
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="profile_box">
                                <div className="profile_box_body">
                                  <tr>
                                    <th>Name :</th>
                                    <td>{GroupView.firstName}</td>
                                  </tr>
                                  <tr>
                                    <th>lastName :</th>
                                    <td>{GroupView.lastName}</td>
                                  </tr>
                                  <tr>
                                    <th>email :</th>
                                    <td>{GroupView.email}</td>
                                  </tr>
                                  <tr>
                                    <th>Description :</th>
                                    <td>{GroupView.message}</td>
                                  </tr>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ height: 440, width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    // tagsRenderer: TagsRenderer,
                    firstNameRenderer: FirstNameRenderer,
                    lastNameRenderer: LastNameRenderer,
                    descriptionRenderer: DescriptionRenderer,
                    srNoRenderer: SrNoRenderer,
                  }}
                >
                  <AgGridColumn
                    flex={2}
                    field="SrNo"
                    Srno={true}
                    sortable={false}
                    filter={false}
                    cellRenderer="srNoRenderer"
                    // pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={2}
                    cellRenderer="firstNameRenderer"
                    headerName="First Name"
                    field="FirstName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    // pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="lastNameRenderer"
                    headerName="Last Name"
                    field="LastName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    // pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="emailRenderer"
                    headerName="email"
                    field="email"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    // pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    flex={2}
                    cellRenderer="descriptionRenderer"
                    headerName="description"
                    field="description"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                    // pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    flex={2}
                    field="Action"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    // pinned="left"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contect;
