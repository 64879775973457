import React, { useState, useEffect } from "react";
import axios from "axios";
import "./footerCategory.css";
import Sidebar from "../Sidebar/sidebar";

function FooterCategory() {
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory1, setSelectedCategory1] = useState("");
  const [selectedCategoryData, setSelectedCategoryData] = useState("");
  useEffect(() => {
    getCategory();
  }, []);

  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const categories = response.data.CategoryData.filter(
          (category) => category.status === true
        ).map((category) => ({
          label: category?.category,
          value: category?._id,
          footerStatus: category?.footerStatus,
        }));
        setCategoryData(categories);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  function onSubmit() {
    const adminToken = localStorage.getItem("AdminToken");

    if (!adminToken) {
      console.error("Admin token not available.");
      // Handle the absence of the admin token, e.g., redirect to login page
      return;
    }

    const selectedIds = [selectedCategory1];

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASEURL}/footerCategory`,
      headers: {
        Authorization: adminToken,
        "Content-Type": "application/json",
      },
      data: {
        id1: selectedIds[0],
      },
    };

    axios(config)
      .then((response) => {
        setSelectedCategoryData(response.data);
        // Handle the response data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }
  const cate = categoryData?.filter((item) => item?.footerStatus === true);

  return (
    <React.Fragment>
      <Sidebar />
      <div className={`footerCategory`}>
        <h2>Please Select Footer Categories </h2>
        <div className="footerCategorySelect">
          <select onChange={(e) => setSelectedCategory1(e.target.value)}>
            {/* <option value="">Please select the categorie one </option> */}
            <option value="" disabled selected>
              {cate[0]?.label}
            </option>
            {categoryData.map((categoryData, index) => (
              <option
                key={index}
                value={categoryData.value}
                // selected={categoryData.footerStatus}
              >
                {categoryData.label}
              </option>
            ))}
          </select>
        </div>
        <button
          className="footerCategorySaveButton"
          type="button"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
}

export default FooterCategory;
