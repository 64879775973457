import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { Card } from "./Card";
import { useDispatch } from "react-redux";
import { articleSeriesData } from "../../redux/reducers/articleSeriesReducer";

const style = {
  width: 400,
};

export const Container = (props) => {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [statusIndex, setStatusIndex] = useState(0);

  useEffect(() => {
    setCards(props?.articles);
    dispatch(articleSeriesData({ series: props?.articles }));
  }, [props?.articles?.length]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
    setStatusIndex((prev) => prev + 1);
  }, []);

  useEffect(() => {
    dispatch(articleSeriesData({ series: cards }));
  }, [statusIndex]);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.value}
        index={index}
        id={card.value}
        text={card.label}
        moveCard={moveCard}
      />
    );
  }, []);

  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
