import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Inners";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
const UpdateInners = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  useEffect(() => {
    setEditData(props.updatedData);
  }, [props]);
  const {
    register: uploadRegister,
    handleSubmit: handlesubmitUpload,
    reset,
    formState: { errors: uploaderrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      inner: props?.updatedData?.inner,
    },
  });

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }
  function updateInner(postdata) {
    props.close();
    let data = JSON.stringify({
      innerId: editGroupData._id,
      inner: postdata.inner,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/updateinner`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {});
  }

  return (
    <>
      <div>
        <Modal show={props.editIsOpen} onHide={props.close}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit XBT
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => fun()}
                    ></button>
                  </div>
                  <form onSubmit={handlesubmitUpload(updateInner)}>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...uploadRegister("inner", {
                                required: true,
                                min: {
                                  value: 1,
                                  message: "Please enter minimum 1 characters",
                                },
                              })}
                            />
                            {uploaderrors?.inner?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                            {uploaderrors?.inner?.type === "min" && (
                              <p style={{ color: "red" }}>
                                Please enter minimum One characters
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        onClick={() => somefun()}
                        type="button"
                        className="btn btn-danger CancelBtn"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default UpdateInners;
