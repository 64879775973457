import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { Controller, useForm } from "react-hook-form";
import Compressor from "compressorjs";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import RenderImage from "../../components/Image";

function Academy() {
  const values = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/getpolicies`,
        {
          headers: {
            Authorization: localStorage.getItem("AdminToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data from the server");
      }

      const data = await response.json();

      const result = data?.result[0];
      reset({
        headline: result?.headline,
        subheadline: result?.content,
        policiesImage: result?.policiesImage,
      });
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    unregister,
    getValues,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onBlur", values, reValidateMode: "onChange" });

  const [authorImage, setAuthorImage] = useState("");
  const [active, setActive] = useState(-1);
  useEffect(() => {
    getImageCategory();
    getAcademy();
  }, []);

  //--------Function to render All images--------//

  useEffect(() => {
    // getauthor();
    values();
  }, []);

  const [isLoading, setIsLoading] = useState({ draft: false, publish: false });

  async function AboutForm({ policiesImage, headline, subheadline }) {
    setIsLoading({
      publish: true,
    });
    try {
      let formData = new FormData();
      // if (watch("uploadFile") === "from directory")
      //   formData.append("policiesImage", image);
      // else if (image?.[0]) formData.append("policiesImage", image[0] ?? "");

      formData.append(
        "policiesImage",
        policiesImage[0] === "h" ? policiesImage : policiesImage[0]
      );

      if (headline) formData.append("headline", headline);
      if (subheadline) formData.append("content", subheadline);
      const ACADEMY_API_URL = `${process.env.REACT_APP_BASEURL}/createAndUpdatePolicies/${aboutDetails?.[0]?._id}`;
      const ACADEMY_API_METHOD = "POST";

      const response = await fetch(ACADEMY_API_URL, {
        method: ACADEMY_API_METHOD,
        body: formData,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
        },
      });

      if (!response.ok) {
        throw new Error(
          "Failed to submit the data. Please Fill the required fields."
        );
      }

      // Handle response if necessary
      const data = await response.json();
      // ...

      if (data.status) {
        toast.success(data.message);
        getAcademy();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      // Capture the error message to display to the user
      // setError(error.message);
      toast.error(error.message);
      console.error(error);
    } finally {
      setIsLoading({
        publish: true,
      });
    }
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));
        setDirectoryData(directories);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }
  const [aboutDetails, setAboutDetails] = useState({});
  function getAcademy() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getpolicies`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const details = response.data.result;
        setAboutDetails(details);
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      });
  }
  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
        },
      });
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(AboutForm)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <div className="d-flex">
                  <h4 className="card-title">Policies</h4>
                  <button
                    type="button"
                    className="btn submitBtn mb-2 mx-2"
                    onClick={() => {
                      setIsLoading({ publish: true });
                      clearErrors();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn submitBtn mb-2"
                    onClick={() => {
                      setIsLoading({ publish: false });
                    }}
                  >
                    Edit
                  </button>
                </div>

                <div className="branchData">
                  <div className="row">
                    {/*//----Academy -section---/*/}
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-10">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Headline</label>
                            <input
                              disabled={isLoading.publish}
                              type="text"
                              className="form-control"
                              {...register("headline", {
                                required: true,
                              })}
                              name="headline"
                            />{" "}
                            {errors?.headline?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col-md-12">
                            <label className="form-label">Content</label>

                            <div className="App">
                              <Editor
                                name="subheadline"
                                defaultValue={getValues("subheadline")}
                                watch={watch}
                                control={control}
                                placeholder="Write a Description..."
                              />
                            </div>

                            {errors?.subheadline?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          {!watch("uploadFile") && (
                            <div className="col-md-12 mb-3 mt-3">
                              <label className="form-label">
                                Policies Image
                              </label>
                              <div className="position-relative">
                                <ul className="imagesUpload">
                                  <li
                                    style={{
                                      width: "128px",
                                      height: "128px",
                                    }}
                                  >
                                    {watch("policiesImage") && (
                                      <img
                                        alt="renderImages"
                                        style={{
                                          width: "100%",
                                          height: "148px",
                                        }}
                                        src={
                                          watch("policiesImage") ?? innericon
                                        }
                                      />
                                    )}
                                  </li>
                                </ul>
                                <div
                                  className="hint_box"
                                  style={{ display: "block" }}
                                ></div>
                              </div>
                            </div>
                          )}

                          <div className="position-relative">
                            <label className="form-label">
                              Upload File
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="file"
                              />
                            </label>
                            <label className="form-label">
                              from directory
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="from directory"
                              />
                            </label>
                            <label className="form-label">
                              Image URL
                              <input
                                {...register("uploadFile")}
                                type="radio"
                                value="image url"
                              />
                            </label>

                            {watch("uploadFile") === "file" && (
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "100%",
                                    height: "128px",
                                  }}
                                >
                                  <input
                                    type="file"
                                    {...register("policiesImage", {
                                      required:
                                        watch("uploadFile") === "file"
                                          ? true
                                          : false,
                                    })}
                                    onChange={(e) => {
                                      handleAuthorImage(e);
                                      unregister("policiesImage");
                                    }}
                                  />

                                  {innericon.length === 0 ? (
                                    <img
                                      alt="renderImages"
                                      style={{
                                        width: "100%",
                                        height: "128px",
                                      }}
                                      src="../../images/defalutimg.svg"
                                    />
                                  ) : (
                                    <RenderImage image={innericon} />
                                  )}

                                  {errors?.policiesImage?.type ===
                                    "required" && (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  )}

                                  {errors?.policiesImage?.type ===
                                    "validate" && (
                                    <p style={{ color: "red" }}>
                                      File size should be 500KB or less
                                    </p>
                                  )}
                                </li>
                              </ul>
                            )}
                            {watch("uploadFile") === "from directory" && (
                              <Fragment>
                                <Controller
                                  name="directory"
                                  control={control}
                                  rules={{
                                    required:
                                      watch("uploadFile") === "from directory"
                                        ? true
                                        : false,
                                  }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      isSearchable={false}
                                      className="react-dropdown"
                                      classNamePrefix="dropdown"
                                      options={directoryData}
                                      onChange={(event) => {
                                        field.onChange(event); // Update the field value
                                        setImagesData(event.images);
                                      }}
                                    />
                                  )}
                                />
                                {Object.keys(errors).includes("directory") && (
                                  <p style={{ color: "red" }}>
                                    {errors.directory.message}
                                  </p>
                                )}{" "}
                                <div class="imageList">
                                  {imagesData &&
                                    imagesData.length > 0 &&
                                    imagesData.map((item, index) => (
                                      <Controller
                                        name="policiesImage"
                                        control={control}
                                        render={() => (
                                          <div
                                            className={`uploadedImg ${
                                              active === index && "active"
                                            }`}
                                            onClick={() => {
                                              setValue(
                                                "policiesImage",
                                                item.location
                                              );
                                              setActive(index);
                                            }}
                                          >
                                            <img
                                              height={50}
                                              width={50}
                                              alt="icon"
                                              src={item?.location}
                                            />
                                          </div>
                                        )}
                                      />
                                    ))}
                                </div>
                              </Fragment>
                            )}
                            {watch("uploadFile") === "image url" && (
                              <div>
                                <input
                                  type="text"
                                  {...register("policiesImage", {
                                    required:
                                      watch("uploadFile") === "image url"
                                        ? true
                                        : false,
                                  })}
                                  placeholder="Enter Image URL"
                                />

                                {errors?.policiesImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        disabled={isLoading.publish}
                        type="submit"
                        className="btn submitBtn me-3"
                        name="Publish"
                        onClick={() => {
                          register("articleType", { value: "Publish" });
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default Academy;
