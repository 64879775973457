import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import "./Wellgorithms.css";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Compressor from "compressorjs";
import RenderImage from "../../components/Image";

const UpdateWellgorithms = (props) => {
  const [editGroupData, setEditData] = useState(props?.updateData);
  useEffect(() => {
    setEditData(props.updatedData);
    console.log("props?.updatedData?.", props?.updatedData);
    if (props?.updatedData) {
      setValue("wellgoImage", props?.updatedData?.wellgoImage);
    }
  }, [props]);

  function somefun() {
    setEditData({});
    props.close();
    cancelForm();
  }
  function fun() {
    props.close();
    cross();
  }
  function cancelForm() {
    reset();
  }
  function cross() {
    reset();
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    unregister,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      headline: props?.updatedData?.headline,
      content: props?.updatedData?.content,
      name: props?.updatedData?.name,
      pointtype: props?.updatedData?.pointtype,
      wellgorithmsPoints: props?.updatedData?.wellgorithmsPoints,
      wellgoRank: props?.updatedData?.wellgoRank,
      tagGroup: props?.updatedData?.tagGroup?.map((item) => ({
        label: item?.groupName,
        value: item?._id,
      })),
      tag: props?.updatedData?.tag?.map((item) => ({
        label: item?.tags,
        value: item?._id,
      })),
      categories: {
        label: props?.updatedData?.categoriesname,
        value: props?.updatedData?.categories,
      },
      subcategories: {
        label: props?.updatedData?.subcategoriesname,
        value: props?.updatedData?.categories,
      },
    },
  });
  //----get Group-tags API--//
  const [groupdata, setGroupdata] = useState([]);
  const [optionstag, setoptionstag] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState(
    editGroupData?.tagGroup?.map((item, index) => {
      return { value: item._id, label: item.groupName };
    })
  );
  const [optionscategory, setoptionscategory] = useState([]);
  const [optionsSubCategory, setoptionsSubCategory] = useState([]);
  const [selectCategoryID, setSelectCategoryID] = useState("");
  const [active, setActive] = useState(-1);

  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      multigroupArray.push({ id: option.value });
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedMultipleGroups(array);
    setErr(false);
  };

  useEffect(() => {
    getnewgroup();
    gettag();
    getCategory();
  }, []);

  useEffect(() => {
    getsubCategory();
  }, [selectCategoryID]);

  // add sub category function
  function getCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let options = [];
        response.data.CategoryData.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.category, value: tag._id });
          }
        });
        setoptionscategory(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  function getsubCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubCategoryByCategoryID/${selectCategoryID}`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        console.log("response>>>>>>>>>>>>>", response);
        let options = [];
        response?.data?.data?.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.topicName, value: tag._id });
          }
        });
        setoptionsSubCategory(options);
      })
      .catch(function (error) {
        setoptionsSubCategory([]);
      });
  }

  function gettag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let options = [];
        response.data.tagData.forEach((tag, index) => {
          if (tag.status == true) {
            options.push({ label: tag.tags, value: tag._id });
          }
        });
        setoptionstag(options);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let groupData = [];
        response.data.result.forEach((item) => {
          if (item.status == true) {
            groupData.push({ label: item.groupName, value: item._id });
          }
        });
        setGroupdata(groupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const [directoryData, setDirectoryData] = useState([]);
  const [imagesData, setImagesData] = useState([]);

  function getImageCategory() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getallimages`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const directories = response.data.result.map((item) => ({
          value: item?.imageCategoryId?._id,
          label: item?.imageCategoryId?.imageCategory,
          images: item.images,
        }));

        console.log("directories>>", directories);
        setDirectoryData(directories);
      })
      .catch(function (error) {
        console.log(error.message ?? "Something went wrong");
      });
  }
  //----updateData API--//
  const [err, setErr] = useState(false);
  function updateData(postdata) {
    if (selectedMultipleGroups?.length === 0) {
      setErr(true);
    } else {
      setErr(false);
      props.close();
      let data = new FormData();
      data.append("name", postdata.name);
      data.append("headline", postdata?.headline);

      data.append(
        "wellgoImage",
        postdata.wellgoImage[0] === "h"
          ? postdata.wellgoImage
          : postdata.wellgoImage[0]
      );

      data.append("content", postdata.content);
      data.append("wellgorithmsPoints", postdata.wellgorithmsPoints);
      data.append("wellgoRank", postdata.wellgoRank);
      data.append("pointtype", postdata.pointtype);

      data.append(
        "subcategories",
        postdata?.subcategories.value || props?.updatedData?.subCategory
      );
      data.append(
        "categories",
        postdata?.categories.value || props?.updatedData?.category
      );
      data.append("categoriesname", postdata?.categories.label);
      data.append("subcategoriesname", postdata?.subcategories.label);

      data.append("wellgorithmsId", editGroupData?._id);
      data.append(
        "tagGroup",
        postdata?.tagGroup?.map((item) => item.value).join(",")
      );
      data.append("tag", postdata?.tag?.map((item) => item.value).join(","));

      let config = {
        method: "PATCH",
        url: `${process.env.REACT_APP_BASEURL}/changewellgorithms`,
        headers: {
          Authorization: localStorage.getItem("AdminToken"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          props.onEditDataFunction();
          reset();
        })
        .catch(function (error) {});
    }
  }

  //--------Function to handle and set Weather Image--------//
  const [Image, setImage] = useState("");
  const [sendImage, setSendImage] = useState("");
  //---------Add Category Image -----//
  const [innericon, setInnericon] = useState("");
  const [sendinnericonImage, setSendinnericonImage] = useState("");

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2,
        success: (compressedResult) => {
          let display = URL.createObjectURL(compressedResult);
          setInnericon(display);
          setSendinnericonImage(compressedResult);
        },
      });
    }
  };
  const [height, setHeight] = useState("auto");

  const handleInput = (event) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = 1; // Reset the number of rows to 1 to measure the actual height
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows; // Set the rows to the calculated value
    setHeight(`${event.target.scrollHeight}px`); // Set the height dynamically
    if (currentRows !== previousRows) {
      event.target.scrollTop = event.target.scrollHeight; // Scroll to bottom if the number of rows increased
    }
  };

  return (
    <div className="">
      <Modal show={props.editIsOpen} onHide={props.close}>
        <Modal.Body>
          <div
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit Wellgorithms
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => fun()}
                  ></button>
                </div>
                <form onSubmit={handleSubmit(updateData)}>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">name</label>
                        <div className="position-relative">
                          <input
                            className="form-control"
                            {...register("name", {
                              required: true,
                              min: {
                                value: 1,
                                message: "Please enter minimum 1 characters",
                              },
                            })}
                          />

                          {errors?.name?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}
                          {errors?.name?.type === "min" && (
                            <p style={{ color: "red" }}>
                              Please enter minimum One characters
                            </p>
                          )}
                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="form-label">Headline</label>
                        <div className="position-relative">
                          <input
                            className="form-control"
                            {...register("headline", {
                              required: true,
                            })}
                          />

                          {errors?.headline?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}

                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        {!watch("uploadFile") && (
                          <div className="col-md-12 mb-3 mt-3">
                            <label className="form-label">
                              Wellgorithms Image
                            </label>
                            <div className="position-relative">
                              <ul className="imagesUpload">
                                <li
                                  style={{
                                    width: "128px",
                                    height: "128px",
                                  }}
                                >
                                  {watch("wellgoImage") && (
                                    <img
                                      alt="renderImages"
                                      style={{ width: "100%", height: "148px" }}
                                      src={watch("wellgoImage") ?? innericon}
                                    />
                                  )}
                                </li>
                              </ul>
                              <div
                                className="hint_box"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                          </div>
                        )}

                        <div className="position-relative">
                          <label className="form-label">
                            Upload File
                            <input
                              {...register("uploadFile")}
                              type="radio"
                              value="file"
                            />
                          </label>
                          <label className="form-label">
                            from directory
                            <input
                              {...register("uploadFile")}
                              type="radio"
                              value="from directory"
                            />
                          </label>
                          <label className="form-label">
                            Image URL
                            <input
                              {...register("uploadFile")}
                              type="radio"
                              value="image url"
                            />
                          </label>

                          {watch("uploadFile") === "file" && (
                            <ul className="imagesUpload">
                              <li
                                style={{
                                  width: "100%",
                                  height: "128px",
                                }}
                              >
                                <input
                                  type="file"
                                  {...register("wellgoImage", {
                                    required:
                                      watch("uploadFile") === "file"
                                        ? true
                                        : false,
                                  })}
                                  onChange={(e) => {
                                    handleAuthorImage(e);
                                    unregister("wellgoImage");
                                  }}
                                />

                                {innericon.length === 0 ? (
                                  <img
                                    alt="renderImages"
                                    style={{
                                      width: "100%",
                                      height: "128px",
                                    }}
                                    src="../../images/defalutimg.svg"
                                  />
                                ) : (
                                  <RenderImage image={innericon} />
                                )}

                                {errors?.wellgoImage?.type === "required" && (
                                  <p style={{ color: "red" }}>
                                    This field is required
                                  </p>
                                )}

                                {errors?.wellgoImage?.type === "validate" && (
                                  <p style={{ color: "red" }}>
                                    File size should be 500KB or less
                                  </p>
                                )}
                              </li>
                            </ul>
                          )}
                          {watch("uploadFile") === "from directory" && (
                            <Fragment>
                              <Controller
                                name="directory"
                                control={control}
                                rules={{
                                  required:
                                    watch("uploadFile") === "from directory"
                                      ? true
                                      : false,
                                }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    isSearchable={false}
                                    className="react-dropdown"
                                    classNamePrefix="dropdown"
                                    options={directoryData}
                                    onChange={(event) => {
                                      field.onChange(event); // Update the field value
                                      setImagesData(event.images);
                                    }}
                                  />
                                )}
                              />
                              {Object.keys(errors).includes("directory") && (
                                <p style={{ color: "red" }}>
                                  {errors.directory.message}
                                </p>
                              )}{" "}
                              <div class="imageList">
                                {imagesData &&
                                  imagesData.length > 0 &&
                                  imagesData.map((item, index) => (
                                    <Controller
                                      name="wellgoImage"
                                      control={control}
                                      render={() => (
                                        <div
                                          className={`uploadedImg ${
                                            active === index && "active"
                                          }`}
                                          onClick={() => {
                                            setValue(
                                              "wellgoImage",
                                              item.location
                                            );
                                            setActive(index);
                                          }}
                                        >
                                          <img
                                            height={50}
                                            width={50}
                                            alt="icon"
                                            src={item?.location}
                                          />
                                        </div>
                                      )}
                                    />
                                  ))}
                              </div>
                            </Fragment>
                          )}
                          {watch("uploadFile") === "image url" && (
                            <div>
                              <input
                                type="text"
                                {...register("wellgoImage", {
                                  required:
                                    watch("uploadFile") === "image url"
                                      ? true
                                      : false,
                                })}
                                placeholder="Enter Image URL"
                              />

                              {errors?.wellgoImage?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  This field is required
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label"> content</label>
                        <div className="position-relative">
                          <textarea
                            className="form-control"
                            style={{
                              height: "auto",
                              minHeight: "20px",
                              overflow: "hidden",
                              resize: "none",
                            }}
                            onMouseEnter={handleInput}
                            onInput={handleInput}
                            {...register("content", {
                              required: true,
                            })}
                          />

                          {errors?.content?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}

                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="form-label">
                          Wellgorithms Points
                        </label>
                        <div className="position-relative">
                          <input
                            className="form-control"
                            {...register("wellgorithmsPoints", {
                              required: true,
                            })}
                          />

                          {errors?.wellgorithmsPoints?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}

                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="form-label">Points Type</label>
                        <div className="position-relative">
                          <input
                            className="form-control"
                            {...register("pointtype", {
                              required: true,
                            })}
                          />

                          {errors?.pointtype?.type === "required" && (
                            <p style={{ color: "red" }}>
                              This field is required
                            </p>
                          )}

                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="form-label">Rank</label>
                        <div className="position-relative">
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            {...register("wellgoRank", {
                              required: "This field is required",
                              valueAsNumber: true,
                            })}
                          />

                          {errors?.wellgoRank?.type === "required" && (
                            <p style={{ color: "red" }}>
                              {errors.wellgoRank.message}
                            </p>
                          )}

                          <div
                            className="hint_box"
                            style={{ display: "block" }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Select Tag</label>
                        <Controller
                          name="tag"
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isMulti
                              isSearchable={false}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              options={optionstag}
                              onChange={(event) => {
                                field.onChange(event); // Update the field value
                              }}
                            />
                          )}
                        />

                        {Object.keys(errors).includes("tag") && (
                          <p style={{ color: "red" }}>{errors.tag.message}</p>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Select Group-Tag</label>
                        <Controller
                          name="tagGroup"
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isMulti
                              isSearchable={false}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              options={groupdata}
                              onChange={(event) => {
                                field.onChange(event); // Update the field value
                                handlechangegroupmulti(event);
                              }}
                            />
                          )}
                        />

                        {Object.keys(errors).includes("tagGroup") && (
                          <p style={{ color: "red" }}>
                            {errors.tagGroup.message}
                          </p>
                        )}
                      </div>
                      {/* add new code  */}
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Select Categories</label>
                        <Controller
                          name="categories"
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          defaultValue={props?.updatedData?.categoriesname}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isSearchable={false}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              options={optionscategory}
                              onChange={(event) => {
                                field.onChange(event);
                                setSelectCategoryID(event?.value);
                              }}
                            />
                          )}
                        />

                        {Object.keys(errors).includes("tag") && (
                          <p style={{ color: "red" }}>{errors.tag.message}</p>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">
                          Select Sub Categories
                        </label>
                        <Controller
                          name="subcategories"
                          control={control}
                          rules={{
                            required: "This field is required",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isSearchable={false}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              options={optionsSubCategory}
                              onChange={(event) => {
                                field.onChange(event);
                              }}
                            />
                          )}
                        />

                        {Object.keys(errors).includes("subcategories") && (
                          <p style={{ color: "red" }}>{errors.tag.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={() => somefun()}
                      type="button"
                      className="btn btn-danger CancelBtn"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn submitBtn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateWellgorithms;
