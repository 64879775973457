import React, { useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Author.css";
import "../Gardener/Gardener.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { AiFillDelete } from "react-icons/ai";
// import JsonDownload from "../../components/downloadjson";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { exportToExcel } from "react-json-to-excel";
import { MdModeEditOutline } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

const SrNoRenderer = (props) => {
  return (
    <>
      <span>{props.node.rowIndex + 1}</span>
    </>
  );
};

const TitleRenderer = (props) => {
  return (
    <>
      <span>{props.data?.title}</span>
    </>
  );
};
const uniqueIdRenderer = (props) => {
  return (
    <>
      <span>{props.data?.uniqueId}</span>
    </>
  );
};
const AuthorRenderer = (props) => {
  return (
    <>
      <span>{props.data?.authorName}</span>
    </>
  );
};

const customLowerCaseComparator = (valueA, valueB) => {
  if (typeof valueA === "string") {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }
  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

function Author() {
  const {
    register: uploadregister,
    handleSubmit: uploadhandleSubmit,
    unregister: uploadunregister,
    formState: { errors },
    reset: uploadreset,
  } = useForm();

  const ImageRenderer = (props, ref) => {
    return (
      <span className="profle_img_box">
        <img
          className="profile_img_table"
          src={props.data?.authorImage}
          alt="icon"
        />
      </span>
    );
  };
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
      filter: true,
      sortable: true,
    };
  }, []);

  const rowHeight = 55;
  let navigate = useNavigate();
  const token = `${sessionStorage.getItem("token")}`;
  let encoded = encodeURIComponent(token);
  // ---- Get author API ---//
  useEffect(() => {
    getauthor();
  }, []);
  const [rowData, setRowData] = useState([]);

  const [downloadData, setDownloadData] = useState([]);

  function getauthor() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        setRowData(response.data.result);
        console.log("response.data.result", response.data.result);
        const downloadData = response.data.result?.map(
          ({
            authorImage,
            authorName,
            authordescription,
            authorType,
            uniqueId,
            status,
          }) => {
            return {
              authorName: authorName ? authorName : "",
              authorImage: authorImage ? authorImage : "",
              authordescription: authordescription ? authordescription : "",
              authorType: authorType ? authorType : "",
              uniqueId: uniqueId ? uniqueId : "",
              status: status ? status : "",
              // _id,
            };
          }
        );
        setDownloadData(downloadData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Update author status API ---//
  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this author?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeAuthorStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  function changeAuthorStatus(authorId) {
    let data = JSON.stringify({
      authorId: authorId,
    });

    let config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_BASEURL}/changeauthorstatus`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getauthor();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //---------delete Author Api -----//
  const [DeleteCategoriesId, setDeleteCategoriesId] = useState("");
  function deleteAuthor(index) {
    let data = JSON.stringify({
      authorId: index,
    });

    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASEURL}/deleteauthor`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getauthor();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() =>
            navigate({
              pathname: `/updateAuthor/${props?.data?.uniqueId}`,
            })
          }
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteCategoriesId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete className="ActionIcon" />
        </div>
      </div>
    );
  };

  function somefun() {
    setIsBulkUploadOpen(false);
  }

  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function bulkupload({ uploadImages }) {
    // setIsLoading(true);
    const formData = new FormData();

    formData.append("attachment", uploadImages[0]);
    // formData.append("images", uploadImages);

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/authorbulkupload`,
      headers: {
        Authorization: localStorage.getItem("AdminToken"),
      },
      data: formData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          toast.success(response.data.message);
          uploadreset();
          somefun();
          getauthor();
        }
      })
      .catch(function (error) {
        toast.error(error.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const headers = [
    { label: "Name", key: "authorName" },
    { label: "Image", key: "authorImage" },
    { label: "Description", key: "authordescription" },
    { label: "Type", key: "authorType" },
    { label: "unique ID", key: "uniqueId" },
    { label: "Status", key: "status" },
  ];

  const escapeCsvValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap value in double quotes if it contains a comma, double quote, or newline
      // if (value.includes(",") || value.includes('"')) {
      //   value = `"${value}"`;
      // }
    }
    return value;
  };

  // Function to prepare data by escaping CSV values
  const prepareData = (data) => {
    return data.map((row) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, escapeCsvValue(value)])
      )
    );
  };

  const preparedData = prepareData(downloadData);

  return (
    <>
      <Header />
      <Sidebar />
      <ToastContainer />

      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Author
                <div className="float-end btns_head">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => setIsBulkUploadOpen(true)}
                  >
                    Bulk Upload
                  </button>
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => navigate("/AddAuthor")}
                  >
                    Add New Author
                  </button>
                  <CSVLink
                    data={preparedData}
                    headers={headers}
                    filename="author.csv"
                    className="btn btn-theme btn-sm"
                  >
                    Export
                  </CSVLink>
                  {/* <button
                    className="btn btn-theme btn-sm"
                    onClick={() =>
                      exportToExcel(
                        [
                          {
                            sheetName: "authorbulkupload",
                            details: downloadData,
                          },
                        ],
                        "Author List",
                        true
                      )
                    }
                  >
                    {" "}
                    Export
                  </button> */}
                  {/* <JsonDownload
                    title="Export"
                    fileName="Author List"
                    downloadfile={downloadData}
                    btnClassName={"btn btn-theme btn-sm"}
                    btnColor={"#f9560f"}
                  /> */}
                </div>
              </h4>

              {/* Upload Images */}

              <div>
                <Modal
                  show={isBulkUploadOpen}
                  onHide={() => setIsBulkUploadOpen(false)}
                >
                  <form onSubmit={uploadhandleSubmit(bulkupload)}>
                    <Modal.Header closeButton>
                      <Modal.Title> Upload Author</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">File</label>
                          <div className="position-relative">
                            <input
                              type="file"
                              multiple
                              // value={tags}
                              className="form-control"
                              {...uploadregister("uploadImages", {
                                required: true,
                              })}
                              onChange={(e) => {
                                uploadunregister("uploadImages");
                              }}
                              name="uploadImages"
                            />
                            {errors?.uploadImages?.type === "required" && (
                              <p style={{ color: "red" }}>
                                This field is required
                              </p>
                            )}

                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setIsBulkUploadOpen(false);
                          uploadreset();
                        }}
                      >
                        Close
                      </Button>
                      <button type="submit" disabled={isLoading}>
                        {" "}
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>

              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this Author?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                deleteAuthor(DeleteCategoriesId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowHeight}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    frameworkComponents={{
                      srNoRenderer: SrNoRenderer,
                      childMessageRenderer: ChildMessageRenderer,
                      titleRenderer: TitleRenderer,
                      uniqueId: uniqueIdRenderer,
                      authorRenderer: AuthorRenderer,
                      imageRenderer: ImageRenderer,
                      statusRenderer: StatusRenderer,
                    }}
                  >
                    <AgGridColumn
                      flex={2}
                      field="SrNo"
                      cellRenderer="srNoRenderer"
                      sortable={false}
                      filter={false}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={3}
                      field="uniqueId"
                      cellRenderer="uniqueId"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      flex={3}
                      field="Author Name"
                      cellRenderer="authorRenderer"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                      // pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="authorImage"
                      cellRenderer="imageRenderer"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>

                    <AgGridColumn
                      cellRenderer="statusRenderer"
                      flex={2}
                      field="status"
                      sortable={true}
                      filter={true}
                      comparator={customLowerCaseComparator}
                    ></AgGridColumn>
                    <AgGridColumn
                      flex={1}
                      field="Actions"
                      cellRenderer="childMessageRenderer"
                      colId="params"
                      sortable={false}
                      filter={false}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Author;
